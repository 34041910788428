import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { LayoutSecondary } from '../../layout';
import { AppLayoutTypes } from '../../../constants/ui';

import errorPageIcon from '../../../assets/images/backgrounds/error-page-icon.png';
import { useStoreConfigAppHeader } from '../../../dao/store/states/ConfigState/hoox';
import { useEffectOnce } from '../../../hoox/useEffectOnce';
import { StoreActionConfigAppHeader } from '../../../dao/store/states/ConfigState/AppHeaderState/actions';

export function PageError() {
  const [isRender, setIsRender] = useState(false);
  const { dispatchHeaderState } = useStoreConfigAppHeader();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  useEffect(() => {
    if (!isRender) {
      setIsRender(true);
    }
  }, [isRender]);

  useEffectOnce(() => {
    dispatchHeaderState({
      type: StoreActionConfigAppHeader.OnlyLogotype,
      payload: true,
    });
  });

  return (
    <LayoutSecondary layoutType={AppLayoutTypes.Secondary} isRender={isRender}>
      <div className="container">
        <h1
          style={{ display: 'none' }}
        >
          404
        </h1>
        <img
          className="icon"
          src={errorPageIcon}
          alt="error-page-icon"
        />
        <h2>Page not found</h2>
        <p>The page you were looking for doesn’t seem to exist anymore.</p>
        <button
          type="button"
          className="button-email"
          aria-label="Go to Homepage"
          onClick={handleClick}
        >
          Go to Homepage
        </button>
      </div>
    </LayoutSecondary>
  );
}
