import {
  DeviceTypes,
  ScreenBPTypes,
} from '../../../../../constants/device';
import { DirectionTypes } from '../../../../../constants/ui';

export const appDeviceInitialState = {
  deviceType: DeviceTypes.Desktop,
  deviceScreen: {
    width: 100,
    height: 100,
    browserWidth: 100,
    browserHeight: 100,
    breakPoint: ScreenBPTypes.MD,
    pixelRatio: 1,
    horizontal: false,
  },
  deviceScroll: {
    top: 0,
    delayTop: 0,
    yDirection: DirectionTypes.Down,
  },
  isAnimated: true,
};
