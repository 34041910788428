import React from 'react';

export function LayoutSecondary(props) {
  const { layoutType, children, className } = props;
  return (
    <div
      className={`app-container-secondary app-container-${layoutType} ${className}`}
    >
      {
          children
        }
    </div>
  );
}
