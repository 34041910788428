import React from 'react';

import { IconLogoPrimary } from '../../../../components/shared/icons';
import { CustomNavLink } from '../../../../components/shared/CustomNavLink';
import { IconHeaderBurger } from '../../../shared/icons';
import { MobileMenu } from './MobileMenu';
import { useStoreConfigAppHeader } from '../../../../dao/store/states/ConfigState/hoox';

export function HeaderMobile() {
  const { headerState } = useStoreConfigAppHeader();

  return (
    <>
      <CustomNavLink to="/" aria-label="home">
        <IconLogoPrimary
          className="app-header-logotype"
        />
      </CustomNavLink>
      {
        !headerState.onlyLogotype
          && <IconHeaderBurger />
      }
      <MobileMenu />
    </>
  );
}
