import React from 'react';

export function KeyFeature2({ feature: { icon, description, prefix } }) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-3 tw-items-center tw-justify-between tw-text-[24px]">
      {prefix && <span className="tw-max-w-[300px]">{prefix}</span>}
      {icon && <img height={75} src={icon} className="tw-w-full tw-max-w-[956px]" alt="key featrue 1" />}
      {description && <span className="tw-max-w-[180px]">{description}</span>}
    </div>
  );
}
