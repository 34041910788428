import React, { useState } from 'react';

import { useStoreConfigAppDevice, useStoreSectionState } from '../../../../dao/store/hoox';

import { BlockWrapperFix } from '../BlockWrapperFix';
import { AlignmentTypes, BlockTitleTypes, SectionTypes } from '../../../../constants/ui';
import { useEffectOnce } from '../../../../hoox/useEffectOnce';

function BlockContent(props) {
  const {
    config: {
      contentAlignment = AlignmentTypes.CC, specialColor, color, type, backgroundSize, backgroundPosition,
    },
    content: {
      title, description, component, image, blocks,
    },
  } = props;
  const [opacity, setOpacity] = useState(0);
  const { deviceState } = useStoreConfigAppDevice();
  const { sectionState } = useStoreSectionState();

  useEffectOnce(() => {
    const timeOut = setTimeout(() => {
      setOpacity(1);
    }, 200);

    return () => {
      clearTimeout(timeOut);
    };
  });

  const calculateCoverHeight = (browserHeight, alignment) => {
    if (alignment === AlignmentTypes.CC) {
      return ((80 * browserHeight) / 100);
    }
    return ((50 * browserHeight) / 100);
  };
  return (
    <div
      className={image ? 'block-page-cover-image'
        : `${
          component ? 'block-page-cover-component' : 'block-page-cover'
        }
        ${
          !deviceState.deviceScreen.horizontal ? 'block-page-cover-vertical' : ''
        }`}
      style={{
        height: image ? `${
          type === SectionTypes.Sticky ? sectionState.sectionMinHeight : sectionState.sectionCurrentHeight
        }px` : `${
          calculateCoverHeight(
            deviceState.deviceScreen.browserHeight,
            contentAlignment,
          )
        }px`,
        willChange: 'transform',
        opacity,
        backgroundImage: `url(${image})`,
        backgroundSize: backgroundSize || 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: backgroundPosition || '44%',
      }}
    >
      {
        component
        && <props.content.component />
      }
      {
        !component && (
        <div className="tw-w-full">
          {title && <h1>{title}</h1>}
          {description && <p>{description}</p>}
          {blocks && (
          <div className="block-page-cover-title">
            {
              blocks?.map((item, key) => (
                <span
                  className="tw-font-bold tw-text-[40px] max-lg:tw-text-[32px] max-md:tw-text-[28px] tw-leading-[140%]"
                  style={
                    item.textType === BlockTitleTypes.Colored
                      ? { color: specialColor }
                      : { color: color || '#000' }
                  }
                  key={`page-cover-title-span-${key}`}
                >
                  {
              item.text
            }
                </span>
              ))
            }
          </div>
          )}
        </div>
        )
      }
    </div>
  );
}

export function BlockPageCover(props) {
  const {
    blockData,
  } = props;

  return (
    <BlockWrapperFix
      {...blockData.config}
    >
      <BlockContent
        {...blockData}
      />
    </BlockWrapperFix>
  );
}
