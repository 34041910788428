import {
  AlignmentTypes, BlockTypes, BlockWrapperTypes, contentBlocksTypes, SectionTypes,
} from '../constants/ui';

import industryFintech from '../assets/images/industries/Fintech.png';
import industryRealEstate from '../assets/images/industries/RealEstate.png';
import industryEnergy from '../assets/images/industries/RenewableEnergy.png';
import industryWeb3 from '../assets/images/industries/Web3.png';
import industryEGove from '../assets/images/industries/E-gov.png';

import projectBreakEvenImage1 from '../assets/images/venture-projects/venture-project-breakeven-1.jpg';
import projectBreakEvenImage2 from '../assets/images/venture-projects/venture-project-breakeven-2.jpg';
import projectBreakEvenImage3 from '../assets/images/venture-projects/venture-project-breakeven-3.jpg';

import projectKickOffImage1 from '../assets/images/venture-projects/venture-project-kickoff-1.jpg';
import projectKickOffImage2 from '../assets/images/venture-projects/venture-project-kickoff-2.jpg';
import projectKickOffImage3 from '../assets/images/venture-projects/venture-project-kickoff-3.jpg';

import projectWireStockImage1 from '../assets/images/venture-projects/venture-project-wirestock-1.jpg';
import projectWireStockImage2 from '../assets/images/venture-projects/venture-project-wirestock-2.jpg';
import projectWireStockImage3 from '../assets/images/venture-projects/venture-project-wirestock-3.jpg';

import ProjectDewa from '../assets/images/industries/projects/logos/Dewa.png';
import ProjectRipple from '../assets/images/industries/projects/logos/Ripple.png';
import ProjectBreakeven from '../assets/images/industries/projects/logos/Breakeven.png';
import ProjectCanada from '../assets/images/industries/projects/logos/Canada.png';
import ProjectDaozi from '../assets/images/industries/projects/logos/Daozi.png';
import ProjectFlote from '../assets/images/industries/projects/logos/Flote.png';
import ProjectHelios from '../assets/images/industries/projects/logos/Helios.png';
import ProjectPwc from '../assets/images/industries/projects/logos/PWC.png';
import ProjectRemix from '../assets/images/industries/projects/logos/Remix.png';
import ProjectTai from '../assets/images/industries/projects/logos/TAI.png';
import ProjectResident from '../assets/images/industries/projects/logos/Resident.png';

export const ventureInfoBlocks = [
  {
    sectionType: SectionTypes.Sticky,
    index: 38,
    blockType: BlockTypes.PageTextImage,
    config: {
      color: '#000000',
      backgroundColor: '#FFFFFF',
      minHeight: 100,
      contendEndFixed: true,
    },
    blockData: {
      config: {
        contentPosition: BlockWrapperTypes.Fixed,
        color: '#000000',
        isImageLeft: true,
        zIndex: 40,
        contentBlockType: contentBlocksTypes.GridPrimary,
      },
      content: {
        image: {
          image1: industryFintech,
          alt: 'groksmith fintech',
        },
        text: {
          styles: {
            textWrapper: {
              width: '90%',
            },
            header: {
              fontSize: '1.75rem',
              marginTop: 0,
            },
            description: {
              fontSize: '1.125rem',
              fontFamily: 'gilroy-medium',
              color: '#200039',
            },
          },
          header: {
            title: 'Fintech',
            // eslint-disable-next-line max-len
            description: 'Software for financial institutions to improve operations and customer experience, including Web3 tech like P2P lending, cryptocurrency wallets, and trading platforms.',
          },
          blocks: [],
          projects: [
            {
              hasLink: true,
              name: 'ripple-fine-tech',
              link: 'https://ripple.com/',
              logo: ProjectRipple,
            },
            {
              hasLink: true,
              name: 'helios-fintech',
              link: 'https://helios.eco/home',
              logo: ProjectHelios,
            },
            {
              name: 'breakeven-fintech',
              link: 'https://breakeven.pro/',
              logo: ProjectBreakeven,
            },
          ],
        },
      },
    },
  },
  {
    sectionType: SectionTypes.Sticky,
    index: 42,
    blockType: BlockTypes.PageTextImage,
    config: {
      color: '#000000',
      backgroundColor: '#F7F8FA',
      minHeight: 100,
      contendEndFixed: true,
    },
    blockData: {
      config: {
        color: '#000000',
        isImageLeft: false,
        zIndex: 44,
        contentBlockType: contentBlocksTypes.GridPrimary,
      },
      content: {
        image: {
          image1: industryRealEstate,
          alt: 'groksmith real estate',
        },
        text: {
          styles: {
            textWrapper: {
              width: '90%',
            },
            header: {
              fontSize: '1.75rem',
              marginTop: 0,
            },
            description: {
              fontSize: '1.125rem',
              fontFamily: 'gilroy-medium',
              color: '#200039',
            },
          },
          header: {
            title: 'Real estate',
            // eslint-disable-next-line max-len
            description: 'We bring the most intricate real-estate concepts to fruition, bridging the gap between businesses and their customers and investors.',
          },
          blocks: [],
          projects: [
            {
              name: 'flote-real-estate',
              link: 'https://www.floteinc.com/',
              logo: ProjectFlote,
            },
            {
              link: null,
              name: 'tai-real-estate',
              logo: ProjectTai,
            },
            {
              link: null,
              name: 'resident-real-estate',
              logo: ProjectResident,
            },
          ],
        },
      },
    },
  },
  {
    sectionType: SectionTypes.Sticky,
    index: 46,
    blockType: BlockTypes.PageTextImage,
    config: {
      color: '#000000',
      backgroundColor: '#FFFFFF',
      minHeight: 100,
      contendEndFixed: true,
    },
    blockData: {
      config: {
        color: '#000000',
        isImageLeft: true,
        zIndex: 48,
        contentBlockType: contentBlocksTypes.GridPrimary,
      },
      content: {
        image: {
          image1: industryEnergy,
          alt: 'groksmith energy',
        },
        text: {
          styles: {
            textWrapper: {
              width: '90%',
            },
            header: {
              fontSize: '1.75rem',
              marginTop: 0,
            },
            description: {
              fontSize: '1.125rem',
              fontFamily: 'gilroy-medium',
              color: '#200039',
            },
          },
          header: {
            title: 'Renewable energy',
            // eslint-disable-next-line max-len
            description: 'Custom renewable energy solutions for various sources, facilities, and businesses through advanced software and data products.',
          },
          blocks: [],
          projects: [
            {
              link: 'https://www.dewa.gov.ae/en/',
              name: 'dewa-energy',
              logo: ProjectDewa,
            },
            {
              link: 'https://helios.eco/home',
              name: 'helios-energy',
              logo: ProjectHelios,
            },
          ],
        },
      },
    },
  },
  {
    sectionType: SectionTypes.Sticky,
    index: 50,
    blockType: BlockTypes.PageTextImage,
    config: {
      color: '#000000',
      backgroundColor: '#F7F8FA',
      minHeight: 100,
      contendEndFixed: true,
    },
    blockData: {
      config: {
        color: '#000000',
        isImageLeft: false,
        zIndex: 52,
        contentBlockType: contentBlocksTypes.GridPrimary,
      },
      content: {
        image: {
          image1: industryWeb3,
          alt: 'groksmith web3',
        },
        text: {
          styles: {
            textWrapper: {
              width: '90%',
            },
            header: {
              fontSize: '1.75rem',
              marginTop: 0,
            },
            description: {
              fontSize: '1.125rem',
              fontFamily: 'gilroy-medium',
              color: '#200039',
            },
          },
          header: {
            title: 'Web3',
            // eslint-disable-next-line max-len
            description: 'Expertise in integrating web3 solutions for startups and enterprises, having worked with industry leaders such as Ripple and Ethereum Foundation.',
          },
          blocks: [
            // {
            //   icon: iconText1,
            //   description: 'Business \nprocesses setup',
            // },
            // {
            //   icon: iconText2,
            //   description: 'Organizational and \nfinancial modeling',
            // },
            // {
            //   icon: iconText3,
            //   description: 'Team setup and \nmanagement',
            // },
            // {
            //   icon: iconText4,
            //   description: 'Business \noperations toolset',
            // },
          ],
          projects: [
            {
              logo: ProjectRipple,
              link: 'https://ripple.com/',
              name: 'ripple-web3',
            },
            {
              logo: ProjectRemix,
              link: 'https://remix-project.org/',
              name: 'remix-web3',
            },
            {
              logo: ProjectHelios,
              link: 'https://helios.eco/home',
              name: 'helios-web3',
            },
            {
              logo: ProjectDaozi,
              link: null,
              name: 'daozi-web3',
            },
            {
              logo: ProjectDewa,
              link: 'https://www.dewa.gov.ae/en/',
              name: 'dewa-web3',
            },
          ],
        },
      },
    },
  },
  {
    sectionType: SectionTypes.Sticky,
    index: 46,
    blockType: BlockTypes.PageTextImage,
    config: {
      color: '#000000',
      backgroundColor: '#FFFFFF',
      minHeight: 100,
      contendEndFixed: true,
    },
    blockData: {
      config: {
        color: '#000000',
        isImageLeft: true,
        zIndex: 48,
        contentBlockType: contentBlocksTypes.GridPrimary,
      },
      content: {
        image: {
          image1: industryEGove,
          alt: 'groksmith E-gov',
        },
        text: {
          styles: {
            textWrapper: {
              width: '90%',
            },
            header: {
              fontSize: '1.75rem',
              marginTop: 0,
            },
            description: {
              fontSize: '1.125rem',
              fontFamily: 'gilroy-medium',
              color: '#200039',
            },
          },
          header: {
            title: 'E-gov',
            // eslint-disable-next-line max-len
            description: 'Commercial, E-Government, and E-Governance software systems that meet high industry standards. Our solutions are flexible, scalable, and user-friendly.',
          },
          blocks: [],
          projects: [
            {
              logo: ProjectDewa,
              link: 'https://www.dewa.gov.ae/en/',
              name: 'dewa-e-gov',
            },
            {
              logo: ProjectCanada,
              link: null,
              name: 'canada-e-gov',
            },
            {
              logo: ProjectPwc,
              link: 'https://www.pwc.com/',
              name: 'pwc-e-gov',
            },
          ],
        },
      },
    },
  },
];

export const ventureProjectList = [
  {
    index: 64,
    sectionType: SectionTypes.Sticky,
    blockType: BlockTypes.PageProject,
    config: {
      path: 'break',
      color: '#FFFFFF',
      backgroundColor: '#1F40DB',
      minHeight: 200,
      contendEndFixed: true,
    },
    blockData: {
      config: {
        color: '#FFFFFF',
        backgroundColor: '#1F40DB',
        isImageLeft: true,
        contentPosition: BlockWrapperTypes.Fixed,
        contentAlignment: AlignmentTypes.CT,
        zIndex: 66,
      },
      content: {
        image: {
          image1: projectBreakEvenImage1,
          image2: projectBreakEvenImage2,
          image3: projectBreakEvenImage3,
          alt: 'groksmith venture breakeven',
        },
        text: {
          header: {
            title: 'Breakeven',
            // eslint-disable-next-line max-len
            description: 'An industry-specific full-featured vertical SAAS platform that helps C-level management to manage their businesses, and track business health at the company, client, project, and employee levels. It provides automatic reports on the company’s income, profit, expenses, and invoices and creates the base of business-wise and long-term decisions.',
          },
          blocks: [
          ],
          footer: {
          },
        },
      },
    },
  },
  {
    sectionType: SectionTypes.Sticky,
    blockType: BlockTypes.PageProject,
    index: 70,
    config: {
      path: 'wirestock',
      color: '#000000',
      backgroundColor: '#D9E2E6',
      minHeight: 200,
      contendEndFixed: true,
    },
    blockData: {
      config: {
        color: '#000000',
        backgroundColor: '#D9E2E6',
        isImageLeft: true,
        contentPosition: BlockWrapperTypes.Fixed,
        contentAlignment: AlignmentTypes.CT,
        zIndex: 72,
      },
      content: {
        image: {
          image1: projectWireStockImage1,
          image2: projectWireStockImage2,
          image3: projectWireStockImage3,
          alt: 'groksmith venture wirestock',
        },
        text: {
          header: {
            title: 'Wirestock',
            // eslint-disable-next-line max-len
            description: 'Wirestock helps visual content creators monetize their photos, graphics, and videos in seconds. The platform automates content tagging and gives creators one-door access to the largest content marketplaces including Shutterstock, Adobe, Pond5, Alamy, Freepik and many others․',
          },
          blocks: [
          ],
          footer: {
          },
        },
      },
    },
  },
  {
    sectionType: SectionTypes.Sticky,
    blockType: BlockTypes.PageProject,
    index: 74,
    config: {
      path: 'kickoff',
      color: '#FFFFFF',
      backgroundColor: '#74b000',
      minHeight: 200,
      contendEndFixed: false,
    },
    blockData: {
      config: {
        color: '#FFFFFF',
        backgroundColor: '#74b000',
        isImageLeft: true,
        contentPosition: BlockWrapperTypes.Fixed,
        contentAlignment: AlignmentTypes.CT,
        zIndex: 76,
      },
      content: {
        image: {
          image1: projectKickOffImage1,
          image2: projectKickOffImage2,
          image3: projectKickOffImage3,
          alt: 'groksmith venture kickoff',
        },
        text: {
          header: {
            title: 'Kickoff',
            // eslint-disable-next-line max-len
            description: 'Kickoff is a community-driven platform that allows fans around the world to connect, talk and discuss matches, and teams together. Simply create audio rooms, invite friends or other fans and hold casual conversations, debates, live podcasts, and panel discussions from your home.',
          },
          blocks: [
          ],
          footer: {
          },
        },
      },
    },
  },
];
