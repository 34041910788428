import React, { createContext, useReducer } from 'react';
import { clientInitialState } from './initialState';
import { clientStateReducer } from './reducer';

export const ClientContext = createContext(clientInitialState);

export function ClientStateProvider({ children }) {
  const [clientState, dispatchClientState] = useReducer(clientStateReducer, clientInitialState, undefined);

  const value = React.useMemo(() => ({
    clientState, dispatchClientState,
  }), [clientState]);

  return (
    <ClientContext.Provider
      value={value}
    >
      {
        children
      }
    </ClientContext.Provider>
  );
}
