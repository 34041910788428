import React from 'react';
import heliosImage from '../../../../assets/images/referralProgram/cover.png';

export function Cover() {
  const handleClick = () => {
    const section = document.getElementById('referral-program-contact');
    section.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="tw-relative tw-h-[calc(100vh_-_10rem)] tw-w-full tw-flex tw-items-center tw-px-5 tw-text-start">
      <div className="tw-flex tw-flex-col tw-gap-9 tw-z-20 tw-max-w-[800px]">
        <h1 className="tw-m-0 lg:tw-text-[92px] sm:tw-text-[72px] tw-text-[52px] tw-leading-[1.1]">
          Earn for referring
          {' '}
          <span className="lg:tw-text-[92px] sm:tw-text-[72px] tw-text-[52px] tw-leading-[1.1] tw-text-[#6B37FF] tw-font-bold tw-font-serif">
            Grōksmith
          </span>
          {' '}
          to your clients
        </h1>
        <p className="tw-text-[22px] max-sm:tw-text-[18px] tw-leading-[38px] max-sm:tw-leading-[24px] tw-w-full tw-max-w-[500px] tw-font-semibold">
          You get 10% commission on the entire contract.
          Your client gets 10% discount.
        </p>
        <button
          type="button"
          className="button-email tw-leading-none tw-px-10 tw-py-5 tw-text-[1rem] tw-font-medium tw-w-fit"
          // aria-label={name || label}
          onClick={handleClick}
        >
          Refer a Business
        </button>
      </div>
      <img src={heliosImage} className="tw-absolute tw-right-0 tw-h-auto tw-max-w-full tw-max-h-full" alt="groksmith project helios" />
    </div>
  );
}
