import React from 'react';

import {
  AlignmentTypes,
  BlockTitleTypes,
  BlockTypes,
  BlockWrapperTypes,
  SectionTypes,
} from '../../constants/ui';
import {
  FloteLogo,
  FloteStructure,
  FloteKeyFeatures,
} from '../../modules/pages/views/Projects/Flote';
import floteImage from '../../assets/images/projects/flote/cover.svg';

const sectionCover = {
  sectionType: SectionTypes.Sticky,
  blockType: BlockTypes.PageCover,
  index: 28,
  config: {
    color: '#FFFFFF',
    contentPosition: BlockWrapperTypes.Fixed,
    contentAlignment: AlignmentTypes.CC,
    contendEndFixed: true,
    minHeight: 100,
    zIndex: 32,
  },
  blockData: {
    config: {
      color: '#000000',
      zIndex: 32,
      specialColor: '#6b37ff',
      contentAlignment: AlignmentTypes.LC,
      minHeight: 100,
    },
    content: {
      image: floteImage,
      blocks: [
        {
          textType: BlockTitleTypes.Static,
          text: 'Bringing',
        },
        {
          textType: BlockTitleTypes.Colored,
          text: ' frictionless finance',
        },
        {
          textType: BlockTitleTypes.Static,
          text: ' to commercial real estate',
        },
      ],
    },
  },
};

const sectionLogo = {
  sectionType: SectionTypes.Sticky,
  blockType: BlockTypes.PageTitle,
  index: 32,
  config: {
    color: '#FFFFFF',
    contentPosition: BlockWrapperTypes.Fixed,
    minHeight: 100,
    contendEndFixed: true,
    zIndex: 34,
    backgroundColor: '#fff',
  },
  blockData: {
    config: {
      color: '#000000',
      zIndex: 30,
      contentPosition: BlockWrapperTypes.Fixed,
    },
    content: {
      component: (props) => <FloteLogo {...props} />,
    },
  },
};

const sectionTitle = {
  sectionType: SectionTypes.Sticky,
  index: 34,
  config: {
    color: '#000000',
    backgroundColor: '#F7F8FA',
    minHeight: 200,
    contendEndFixed: true,
  },
  blockType: BlockTypes.PageTitle,
  blockData: {
    config: {
      contentPosition: BlockWrapperTypes.Fixed,
      color: '#000000',
      specialColor: '#6b37ff',
      backgroundColor: '#F7F8FA',
      zIndex: 36,
      showContactBtn: false,
    },
    content: {
      blocks: [
        {
          textType: BlockTitleTypes.Static,
          text: 'A personalized investor portal created specifically for real estate entrepreneurs that helps simplify capital raising, close more deals, be more organized, and optimize time while fostering happiness amongst investors. The platform offers a',
        },
        {
          textType: BlockTitleTypes.Colored,
          text: ' perfect blend of technology and data that facilitates customer engagement for better ROI.',
        },
      ],
    },
  },
};

const sectionStructure = {
  sectionType: SectionTypes.Static,
  blockType: BlockTypes.PageText,
  index: 28,
  config: {
    color: '#FFFFFF',
    contentAlignment: AlignmentTypes.CC,
    minHeight: 100,
    zIndex: 32,
    backgroundColor: '#fff',
    display: 'table',
    wrapperDisplay: 'table-cell',
  },
  blockData: {
    config: {
      color: '#000000',
      zIndex: 32,
      contentAlignment: AlignmentTypes.CC,
      mobile: {
        contentAlignment: AlignmentTypes.CC,
      },
    },
    content: {
      component: (props) => <FloteStructure {...props} />,
    },
  },
};

const sectionImages = {
  sectionType: SectionTypes.Static,
  blockType: BlockTypes.PageText,
  config: {
    color: '#FFFFFF',
    minHeight: 200,
    overflow: 'hidden',
    backgroundColor: '#fff',
    background: 'linear-gradient(180deg, #0A0620 0%, rgba(23, 15, 63, 0.84) 25.71%, rgba(21, 14, 60, 0.68) 56.67%, rgba(20, 11, 68, 0.18) 72.69%, rgba(0, 0, 0, 0.05) 86.84%, rgba(0, 0, 0, 0.03) 100%)',
  },
  blockData: {
    config: {},
    content: {
      header: {
        title: 'The key features',
      },
      component: (props) => <FloteKeyFeatures {...props} />,
    },
  },
};

export const pageFlote = {
  config: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
  },
  sections: [
    sectionCover,
    sectionLogo,
    sectionTitle,
    sectionStructure,
    sectionImages,
  ],
  seo: {
    title: 'Flote - Investor Portal for Real Estate Entrepreneurs\n',
    description: 'Flote, based in San Francisco, makes capital raising easy for real estate entrepreneurs. It has over $1 billion plus assets under management. Groksmith led the product development from analyzing the business to engineering and launching it in the market.',
    name: 'Flote',
  },
};
