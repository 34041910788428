import React from 'react';

import { BlockTypes } from '../../../../constants/ui';
import { BlockPageCover } from '../BlockPageCover';
import { BlockPageTitle } from '../BlockPageTitle';
import { BlockPageIcons } from '../BlockPageIcons';
import { BlockPageTextImage } from '../BlockPageTextImage';
import { BlockPageText } from '../BlockPageText';
import { BlockPageProject } from '../BlockPageProject';
import { BlockPageEvents } from '../BlockPageEvents';
import { BlockPageDefault } from '../BlockDefault/BlockPageDefault';

export function BlockContainer(props) {
  const { type, blockData, sectionConfig } = props;

  switch (type) {
    case BlockTypes.PageDefault:
      return <BlockPageDefault blockData={blockData} />;

    case BlockTypes.PageCover:
      return <BlockPageCover blockData={blockData} />;

    case BlockTypes.PageTitle:
      return <BlockPageTitle blockData={blockData} />;

    case BlockTypes.PageIcons:
      return <BlockPageIcons blockData={blockData} />;

    case BlockTypes.PageText:
      return <BlockPageText blockData={blockData} />;

    case BlockTypes.PageTextImage:
      return <BlockPageTextImage blockData={blockData} sectionConfig={sectionConfig} />;

    case BlockTypes.PageProject:
      return <BlockPageProject blockData={blockData} />;

    case BlockTypes.PageEvents:
      return <BlockPageEvents blockData={blockData} />;

    default:
      return null;
  }
}
