import React from 'react';

import {
  AlignmentTypes,
  BlockTitleTypes,
  BlockTypes,
  BlockWrapperTypes,
  SectionTypes,
} from '../../constants/ui';
import {
  HeliosCover,
  HeliosLogo,
  HeliosStructure,
  HeliosHowItWorks,
  HeliosImages,
} from '../../modules/pages/views/Projects/Helios';

const sectionCover = {
  sectionType: SectionTypes.Sticky,
  blockType: BlockTypes.PageCover,
  index: 28,
  config: {
    color: '#FFFFFF',
    contentPosition: BlockWrapperTypes.Fixed,
    contentAlignment: AlignmentTypes.CC,
    minHeight: 100,
    contendEndFixed: true,
    zIndex: 32,
    background: 'linear-gradient(110deg, #F0E9FF 0%, rgba(255, 255, 255, 0.47) 49.15%, #EFEEFD 100%)',
  },
  blockData: {
    config: {
      color: '#000000',
      zIndex: 32,
      contentPosition: BlockWrapperTypes.Fixed,
      contentAlignment: AlignmentTypes.CC,
      mobile: {
        contentAlignment: AlignmentTypes.CC,
      },
    },
    content: {
      component: (props) => <HeliosCover {...props} />,
    },
  },
};

const sectionLogo = {
  sectionType: SectionTypes.Sticky,
  blockType: BlockTypes.PageTitle,
  index: 32,
  config: {
    color: '#FFFFFF',
    contentPosition: BlockWrapperTypes.Fixed,
    minHeight: 100,
    contendEndFixed: true,
    zIndex: 34,
    backgroundColor: '#fff',
  },
  blockData: {
    config: {
      color: '#000000',
      zIndex: 30,
      contentPosition: BlockWrapperTypes.Fixed,
    },
    content: {
      component: (props) => <HeliosLogo {...props} />,
    },
  },
};

const sectionTitle1 = {
  sectionType: SectionTypes.Sticky,
  blockType: BlockTypes.PageTitle,
  index: 34,
  config: {
    contentPosition: BlockWrapperTypes.Fixed,
    color: '#000000',
    backgroundColor: '#F7F8FA',
    minHeight: 200,
    contendEndFixed: true,
  },
  blockData: {
    config: {
      contentPosition: BlockWrapperTypes.Fixed,
      color: '#000000',
      specialColor: '#6b37ff',
      backgroundColor: '#F7F8FA',
      zIndex: 36,
      showContactBtn: false,
    },
    content: {
      blocks: [
        {
          textType: BlockTitleTypes.Colored,
          text: 'Helios brings renewable energy to web3',
        },
        {
          textType: BlockTitleTypes.Static,
          // eslint-disable-next-line max-len
          text: ' and helps anyone to profitably fight climate change, utilizing web3 tech to maximize liquidity, demolish decade-long lockup periods, and introduce real-time transparency.',
        },
      ],
    },
  },
};

const sectionStructure = {
  sectionType: SectionTypes.Static,
  blockType: BlockTypes.PageCover,
  index: 28,
  config: {
    color: '#FFFFFF',
    contentPosition: BlockWrapperTypes.Static,
    contentAlignment: AlignmentTypes.CC,
    minHeight: 100,
    zIndex: 32,
    backgroundColor: '#fff',
  },
  blockData: {
    config: {
      color: '#000000',
      zIndex: 32,
      contentPosition: BlockWrapperTypes.Static,
      contentAlignment: AlignmentTypes.CC,
      mobile: {
        contentAlignment: AlignmentTypes.CC,
      },
    },
    content: {
      component: (props) => <HeliosStructure {...props} />,
    },
  },
};

const sectionHowItWorks = {
  sectionType: SectionTypes.Static,
  blockType: BlockTypes.PageCover,
  index: 30,
  config: {
    color: '#FFFFFF',
    contentPosition: BlockWrapperTypes.Fixed,
    contentAlignment: AlignmentTypes.CC,
    minHeight: 110,
    zIndex: 34,
    backgroundColor: '#fff',
  },
  blockData: {
    config: {
      color: '#000000',
      zIndex: 34,
      contentPosition: BlockWrapperTypes.Fixed,
      contentAlignment: AlignmentTypes.CC,
      mobile: {
        contentAlignment: AlignmentTypes.CC,
      },
    },
    content: {
      component: (props) => <HeliosHowItWorks {...props} />,
    },
  },
};

const sectionTitle2 = {
  sectionType: SectionTypes.Static,
  index: 34,
  config: {
    color: '#000000',
    backgroundColor: '#F7F8FA',
    minHeight: 100,
    contendEndFixed: true,
  },
  blockType: BlockTypes.PageTitle,
  blockData: {
    config: {
      contentPosition: BlockWrapperTypes.Fixed,
      color: '#000000',
      specialColor: '#6b37ff',
      backgroundColor: '#F7F8FA',
      zIndex: 36,
      showContactBtn: false,
    },
    content: {
      blocks: [
        {
          textType: BlockTitleTypes.Static,
          text: 'Helios represents the future of impact investing. Through Helios, anyone can start funding clean energy in minutes, and earn stable yields safe from the volatility of the markets without choosing between impact and profit: it makes saving the planet both profitable and accessible to anyone.',
        },
        {
          textType: BlockTitleTypes.Colored,
          text: ' Groksmith\'s role includes the development of a robust Helios protocol with a secure smart contract architecture, along with product development.',
        },
      ],
    },
  },
};

const sectionImages = {
  sectionType: SectionTypes.Static,
  blockType: BlockTypes.PageText,
  config: {
    color: '#FFFFFF',
    minHeight: 100,
    backgroundColor: '#F7F8FA',
  },
  blockData: {
    config: {},
    content: {
      component: (props) => <HeliosImages {...props} />,
    },
  },
};

export const pageHelios = {
  config: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
  },
  sections: [
    sectionCover,
    sectionLogo,
    sectionTitle1,
    sectionStructure,
    sectionHowItWorks,
    sectionTitle2,
    sectionImages,
  ],
  seo: {
    title: 'Helios - The Evolution of Solar, Powered by Crypto\n',
    description: 'Helios Offers a Platform for Streamlined Cryptocurrency Investment in Solar Projects, Backed by the Groksmith Team\'s Development of a Robust and Secure Helios Protocol.',
    name: 'Helios',
  },
};
