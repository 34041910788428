import React from 'react';
import TeamBackground from '../../../../assets/images/backgrounds/teamBackground.svg';
import { ButtonContact } from '../../../../components/shared/buttons/ButtonContact';

export function TeamTitle() {
  return (
    <div className="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center">
      <img className="tw-w-11/12 tw-h-11/12" src={TeamBackground} alt="" />
    </div>
  );
}

export function ConnectButtonComponent() {
  return (
    <div className="tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center">
      <ButtonContact name="Connect" label="connect" />
    </div>
  );
}
