import React from 'react';
import { Field } from 'formik';

function CustomTextAreaComponent({ hasError = false, rest }) {
  return (
    <textarea rows="6" cols="50" className={`${hasError ? 'form-error-textarea' : 'form-textarea'}`} {...rest} />
  );
}

export function FormTextArea(props) {
  const {
    type, id, name, placeholder,
  } = props;
  return (
    <Field
      id={id}
      type={type}
      name={name}
    >
      {({ field, meta }) => (
        <div>
          <CustomTextAreaComponent hasError={!!(meta.error && meta.touched)} rest={{ ...field, placeholder }} />
        </div>
      )}
    </Field>
  );
}
