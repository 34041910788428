import React from 'react';

export function IconLogotypeStarkNet(props) {
  const { className, style } = props;

  return (
    <svg
      width="6.5em"
      height="1.375em"
      viewBox="0 0 104 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M20.8792 11.2723C20.8792 16.9866 16.2062 21.6172 10.4396 21.6172C4.67307 21.6172 0 16.9866 0 11.2723C0 5.55814 4.67505 0.92749 10.4396 0.92749C16.2042 0.92749 20.8792 5.55814 20.8792 11.2723Z" fill="#29296E" />
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M15.2969 8.50642L15.0314 7.69375C14.9779 7.52886 14.8452 7.3993 14.6788 7.34826L13.8547 7.097C13.7398 7.06167 13.7398 6.90267 13.8547 6.86734L14.6748 6.6043C14.8412 6.5513 14.972 6.41978 15.0235 6.25489L15.277 5.43829C15.3127 5.3264 15.4732 5.32444 15.5088 5.43829L15.7743 6.25096C15.8278 6.41585 15.9605 6.54541 16.1269 6.59645L16.951 6.84771C17.0658 6.88304 17.0658 7.04204 16.951 7.07737L16.1308 7.34041C15.9644 7.39341 15.8337 7.52493 15.7822 7.68982L15.5286 8.50642C15.493 8.62027 15.3325 8.62027 15.2969 8.50642Z" fill="#FAFAFA" />
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M0.948786 11.1074C1.19641 10.6265 1.68768 10.2634 2.19679 10.0337C2.71183 9.80599 3.27839 9.66662 3.83503 9.62147C4.95823 9.52136 6.05172 9.70784 7.04814 10.0278C7.5612 10.179 8.02078 10.389 8.49621 10.603C8.726 10.7129 8.94391 10.8385 9.16776 10.9582L9.78383 11.3116C10.4613 11.7218 11.117 12.087 11.7331 12.3696C12.3511 12.6503 12.9098 12.8407 13.4367 12.9369C13.9636 13.0351 14.4985 13.0331 15.1562 12.8839C15.8099 12.7367 16.5448 12.4226 17.3115 12.0163C18.084 11.6119 18.8764 11.1153 19.7797 10.6285C19.6886 11.6433 19.3994 12.5954 18.9457 13.514C18.4822 14.4209 17.8364 15.3062 16.8974 16.0247C15.9743 16.7471 14.7085 17.2515 13.4189 17.3281C12.1293 17.4145 10.9011 17.1338 9.85713 16.7098C8.8092 16.2799 7.90391 15.7145 7.09964 15.0923C6.87778 14.9195 6.75892 14.8214 6.5945 14.6859L6.1369 14.2894C5.82787 14.0499 5.53073 13.7653 5.22566 13.5278C4.61751 13.0233 4.0153 12.5188 3.35564 12.0811C3.02284 11.8593 2.68014 11.6531 2.28989 11.4706C2.09576 11.3823 1.89172 11.2998 1.66985 11.235C1.44402 11.1624 1.21423 11.1192 0.944824 11.1094L0.948786 11.1074Z" fill="#FF4F0A" />
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M0.949058 11.1075C1.07584 10.0632 1.56712 9.08369 2.35355 8.25139C3.13603 7.42498 4.36026 6.78897 5.71721 6.70457C6.38677 6.65942 7.06822 6.73401 7.69222 6.90675C8.31424 7.07753 8.89862 7.32683 9.42357 7.62127C9.68704 7.7685 9.93268 7.92946 10.1763 8.09239L10.8281 8.58117L11.8463 9.37813C12.5079 9.90028 13.1379 10.3812 13.7203 10.7797C14.3086 11.1782 14.8098 11.4707 15.2892 11.6414C15.7646 11.8279 16.3628 11.875 17.1552 11.6787C17.5494 11.5884 17.9595 11.4255 18.3973 11.2469C18.8331 11.0643 19.2847 10.8484 19.778 10.6325C19.7186 11.1566 19.6116 11.6807 19.4056 12.1695C19.2095 12.6641 18.95 13.149 18.5894 13.6005C18.4052 13.8203 18.2071 14.0422 17.9753 14.2463C17.7436 14.4446 17.488 14.635 17.2048 14.7999C16.6402 15.1237 15.9706 15.3613 15.2832 15.4457C14.5958 15.532 13.9065 15.4889 13.2785 15.3573C12.6466 15.2298 12.0721 15.0217 11.5432 14.7842C10.4913 14.2993 9.60978 13.6888 8.83127 13.043C8.44102 12.7211 8.07454 12.3834 7.72589 12.0419L7.31584 11.6336C7.19302 11.5158 7.06822 11.3961 6.94342 11.2881C6.44422 10.8523 5.98266 10.5186 5.47355 10.3105C4.96841 10.0927 4.32064 10.0024 3.52628 10.1496C2.73588 10.2948 1.86822 10.6344 0.943115 11.1095L0.949058 11.1075Z" fill="#FAFAFA" />
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M5.1228 16.4918C5.1228 16.8 4.86923 17.0513 4.55822 17.0513C4.24721 17.0513 3.99365 16.8 3.99365 16.4918C3.99365 16.1836 4.24523 15.9324 4.55822 15.9324C4.87121 15.9324 5.1228 16.1836 5.1228 16.4918Z" fill="#FAFAFA" />
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M5.1228 16.4918C5.1228 16.8 4.86923 17.0513 4.55822 17.0513C4.24721 17.0513 3.99365 16.8 3.99365 16.4918C3.99365 16.1836 4.24523 15.9324 4.55822 15.9324C4.87121 15.9324 5.1228 16.1836 5.1228 16.4918Z" fill="#FAFAFA" />
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M35.9406 11.5119C35.5622 11.2685 34.8768 10.9603 33.8824 10.5873C32.8879 10.2144 32.1966 9.88067 31.8103 9.58622C31.424 9.28981 31.2299 8.88152 31.2299 8.35937C31.2299 7.83721 31.426 7.42695 31.8182 7.12466C32.2104 6.82236 32.7275 6.67317 33.3673 6.67317C34.0072 6.67317 34.5301 6.84591 34.9323 7.18943C35.3344 7.53492 35.5365 8.0237 35.5365 8.6597H37.1074C37.1074 7.56829 36.7429 6.71439 36.0139 6.09802C35.2849 5.48165 34.4073 5.17346 33.3792 5.17346C32.3511 5.17346 31.4795 5.46006 30.7604 6.03324C30.0433 6.60643 29.6828 7.37592 29.6828 8.3417C29.6828 9.09155 29.869 9.68633 30.2414 10.126C30.4375 10.3596 30.6277 10.5559 30.8079 10.7169C30.9882 10.8779 31.3705 11.0977 31.9509 11.3765C32.5314 11.6572 33.0246 11.8456 33.4307 11.9418C34.2112 12.2735 34.8511 12.5444 35.2591 12.8389C35.6672 13.1313 35.8712 13.5357 35.8712 14.0481C35.8712 14.5604 35.6593 14.9746 35.2334 15.2906C34.8095 15.6047 34.2231 15.7637 33.4743 15.7637C32.7255 15.7637 32.1154 15.5772 31.6459 15.2062C31.1744 14.8332 30.9387 14.3032 30.9387 13.6142H29.3777C29.3777 14.7704 29.7798 15.6734 30.5821 16.3192C31.3844 16.965 32.3531 17.2889 33.4882 17.2889C34.6232 17.2889 35.5642 16.9925 36.315 16.4017C37.0658 15.8088 37.4362 15.004 37.4283 13.9813C37.4204 12.9606 36.9231 12.1381 35.9366 11.5139L35.9406 11.5119Z" fill="#26296E" />
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M46.3384 5.50342H38.5889V6.96387H41.691V17.0712H43.2203V6.96387H46.3384V5.50342Z" fill="#26296E" />
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M50.3757 5.50342L46.0691 17.0692H47.7212C47.7212 17.0692 48.1412 15.7815 48.4819 15.2555C49.9617 12.9706 52.42 12.6918 53.2104 12.7134L54.7873 17.0692H56.4394L52.1249 5.50342H50.3757ZM49.4367 12.3326L51.2552 7.31131L52.6795 11.2471C51.8673 11.306 50.6827 11.5474 49.4367 12.3346V12.3326Z" fill="#26296E" />
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M76.6015 5.50342H74.6958L69.8286 10.7465V5.50342H68.2815V17.0692H69.8286V11.5199L74.8662 17.0692H76.7619L71.4471 11.1411L76.5995 5.50342H76.6015Z" fill="#26296E" />
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M84.2775 12.6427L79.7272 5.50342H77.978V17.0692H80.2819V9.91618L84.8163 17.0712H86.5655V5.50342H84.2775V12.6427Z" fill="#26296E" />
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M90.2916 12.2951H94.41V10.1652H90.2916V7.68797H94.826V5.50122H87.9878V17.067H94.9073V14.8901H90.2916V12.2931V12.2951Z" fill="#26296E" />
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M96.0881 5.50342V7.69017H98.8872V17.0712H101.183V7.69017H104V5.50342H96.0881Z" fill="#26296E" />
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M63.4439 12.9058C63.2081 12.6899 62.9684 12.5014 62.7288 12.3365C63.4815 12.2266 64.2917 11.8183 64.8147 11.1568C65.2901 10.5541 65.4981 9.84944 65.4981 9.09173C65.4981 8.0965 65.1435 7.25046 64.4324 6.55164C63.7212 5.85283 62.8674 5.50342 61.869 5.50342H57.6199V17.0692H59.167V12.7075C60.0129 12.7272 61.2708 12.9392 62.4257 13.9972C63.535 15.014 64.2996 16.2762 64.6939 17.0692H66.3361C65.9953 16.1938 65.0207 14.3525 63.4439 12.9058ZM61.869 6.9462C62.4435 6.9462 62.9348 7.15428 63.3428 7.57043C63.7489 7.98658 63.953 8.49302 63.953 9.08584C63.953 9.67866 63.7569 10.1949 63.3428 10.615C62.6059 11.3609 60.9023 11.2117 59.2126 11.2196C59.1967 11.2196 59.1828 11.2196 59.167 11.2196V6.94816H61.869V6.9462Z" fill="#26296E" />
    </svg>
  );
}
