import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { API_URL, RequestStatusTypes } from '../../../../constants/api';
import { FormInput } from '../../../../components/shared/inputs/FormInput';
import { FormTextArea } from '../../../../components/shared/inputs/FormTextarea';
import ThanksIcon from '../../../../assets/images/backgrounds/thanksIcon-1x.png';

const ContactFormSchema = Yup.object()
  .shape({
    email: Yup.string()
      .email('Provide valid email address. ')
      .required('Required field.'),
    referral: Yup.string().required('Required field.'),
    company: Yup.string().required('Required field.'),
    text: Yup.string()
      .required('Required field.'),
  });

export function ContactForm() {
  const [isRender, setIsRender] = useState(false);
  const [requestStatus, setRequestStatus] = useState(RequestStatusTypes.Waiting);
  useEffect(() => {
    setIsRender(true);
  }, []);

  const handleSubmit = async (values) => {
    const data = {
      email: values.email,
      referral: values.referral,
      company: values.company,
      text: values.text,
    };

    try {
      setRequestStatus(RequestStatusTypes.Loading);
      await axios.post(`${API_URL}referral/`, { ...data });
      setRequestStatus(RequestStatusTypes.Success);
    } catch (e) {
      setRequestStatus(RequestStatusTypes.Error);
      await Promise.reject(e);
    }
  };

  if (requestStatus === RequestStatusTypes.Success && isRender) {
    return (
      <div className="page-wrapper tw-flex tw-flex-col tw-items-center">
        <img
          className="icon"
          src={ThanksIcon}
          alt="error-page-icon"
        />
        <h2>Thank you for contacting us!</h2>
        <p>We have received your message.</p>
        <button
          type="button"
          className="button-email tw-mt-2"
          aria-label="Go to Homepage"
          onClick={() => { setRequestStatus(RequestStatusTypes.Waiting); }}
        >
          Back
        </button>
      </div>
    );
  }

  return (
    <div id="referral-program-contact" className="page-wrapper">
      <h1 className="page-title">Get in touch</h1>
      <p className="page-subtitle">Fill in the form below and we will get back to you within 2 business days.</p>
      <div className="form-container">
        <Formik
          initialValues={{
            email: '',
            company: '',
            referral: '',
            text: '',
          }}
          onSubmit={handleSubmit}
          validationSchema={ContactFormSchema}
          validateOnBlur={false}
        >
          <Form>

            <FormInput
              id="company"
              name="company"
              placeholder="Your business name"
              type="text"
            />
            <FormInput
              id="referral"
              name="referral"
              placeholder="Referral's business name"
              type="text"
            />
            <FormInput
              id="email"
              name="email"
              placeholder="Your business email"
              type="email"
            />
            <FormTextArea
              id="text"
              name="text"
              placeholder="Message"
              type="text"
            />
            <div className="contact-btn-wrapper">
              <button
                type="submit"
                className="contact-button"
              >
                {requestStatus === RequestStatusTypes.Loading ? 'Loading...' : 'Contact'}
              </button>
            </div>
          </Form>
        </Formik>
        {
          requestStatus === RequestStatusTypes.Error
          && <div><p className="request-error">Something gone wrong.Please try again later</p></div>
        }
      </div>
      <p className="bottom-subtitle">
        By clicking the button,
        I agree with the collection and processing of my personal data as described in the
        <Link to="/privacy-policy"> Privacy Policy.</Link>
      </p>
    </div>
  );
}
