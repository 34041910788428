import React from 'react';

import { ButtonTypes } from '../../../../constants/ui';
import { ButtonContact } from '../ButtonContact';
import { ButtonWithArrow } from '../ButtonWithArrow';

export function ButtonContainer(props) {
  const {
    type,
  } = props;

  switch (type) {
    case ButtonTypes.ContactButton:
      return (<ButtonContact {...props} />);

    case ButtonTypes.ArrowButton:
      return (<ButtonWithArrow {...props} />);

    default:
      return null;
  }
}
