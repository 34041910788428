import React, { useContext, useEffect } from 'react';
import { TeamListItem } from './TeamListItem';
import { useTeamList } from '../../../../hoox/useTeam/useTeamList';
import { SectionStateContext } from '../../../../dao/store/states/SectionState/State';
import { StoreActionSectionState } from '../../../../dao/store/states/SectionState/actions';

export function TeamList() {
  const { sectionState, dispatchSectionState } = useContext(SectionStateContext);
  const { teamData, handleNavigateToMemberSingle } = useTeamList();

  useEffect(() => {
    dispatchSectionState({
      type: StoreActionSectionState.ReRender,
      payload: !sectionState.reRender,
    });
  }, [teamData]);
  return (
    <div
      className="tw-min-h-fit tw-h-full tw-flex tw-justify-center tw-items-center tw-flex-col"
    >
      <div className="tw-mb-20 tw-text-center">
        <h1 className="tw-mt-0 tw-mb-4 tw-text-2xl tw-font-semibold">Team</h1>
        <p className="tw-text-center tw-text-base tw-font-medium">
          At Groksmith we are committed to finding new and better ways of changing lives for the better:
          <br />
          we are passionate about the work we do, the process of creation, and the teamwork.
        </p>
      </div>
      {
        teamData.managers.length > 0
        && (
        <div className="tw-mb-20">
          <h1 className="tw-mb-16 tw-mt-0 tw-text-[2rem] tw-font-regular tw-text-center">Management</h1>
          <div className="tw-container tw-mx-auto">
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-20">
              {teamData.managers.map((item) => <TeamListItem navigate={handleNavigateToMemberSingle} key={item.id} item={item} />)}
            </div>
          </div>
        </div>
        )
      }
      {
        teamData.developers.length > 0
        && (
        <div>
          <h1 className="tw-mb-16 tw-mt-0 tw-text-[2rem] tw-font-regular tw-text-center">Engineering team</h1>
          <div className="tw-container tw-mx-auto">
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-20">
              {teamData.developers
                .map((item) => <TeamListItem navigate={handleNavigateToMemberSingle} key={item.id} item={item} />)}
            </div>
          </div>
        </div>
        )
      }
    </div>
  );
}
