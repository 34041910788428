import React from 'react';
import frame1 from '../../../../../assets/images/projects/globalid/frame1.jpg';
import frame2 from '../../../../../assets/images/projects/globalid/frame2.jpg';
import frame3 from '../../../../../assets/images/projects/globalid/frame3.jpg';
import state1 from '../../../../../assets/images/projects/globalid/state1.png';
import state2 from '../../../../../assets/images/projects/globalid/state2.png';
import state3 from '../../../../../assets/images/projects/globalid/state3.png';
import state4 from '../../../../../assets/images/projects/globalid/state4.png';
import state5 from '../../../../../assets/images/projects/globalid/state5.png';
import state6 from '../../../../../assets/images/projects/globalid/state6.png';
import { Structure } from './Structure';

export function KeyFeatures() {
  const items = [
    {
      title: 'API development',
      description: 'Optimized APIs that elevate the performance and functionality of the product.',
    },
    {
      title: 'Messaging App development',
      description: 'End-to-end encrypted messaging app focused on privacy-focused communication within community.',
    },
    {
      title: 'GlobalID groups ecosystem development',
      description: 'Development of features that simplify collaboration among the group members.',
    },
    {
      title: 'Website functionalities',
      description: 'Website functionalities development prioritized user experience and security in identity management.',
    },
  ];

  return (
    <>
      <span className="tw-text-[24px] tw-text-black max-lg:tw-text-[22px] max-sm:tw-text-[20px]">
        Product development at GlobaliD was based on microservice architecture. There were 9 teams working on distinct, but interconnected feature sets: Messaging, Payments, Groups, Mobile App, Web App, Infrastructure, Identity plus Design systems and Labs. Our team primarily concentrated on the following domains.
      </span>
      <Structure items={items} />
      <div className="tw-relative tw-flex tw-flex-col tw-gap-7 tw-justify-center tw-mt-52 [&>img]:tw-rounded-md [&>img]:tw-shadow-xl">
        <img src={frame1} alt="frame 1" />
        <img src={frame2} alt="frame 1" />
        <img src={frame3} alt="frame 1" />
      </div>
      <div className="tw-relative tw-flex tw-flex-wrap tw-justify-center tw-gap-5 tw-mt-10 tw-mb-48 max-sm:tw-mb-24 [&>img]:tw-max-w-[319px] max-sm:[&>img]:tw-max-w-[160px] max-lg:[&>img]:tw-max-w-[200px] [&>img]:tw-h-auto [&>img]:tw-shadow-xl max-sm:[&>img]:tw-rounded-2xl max-lg:[&>img]:tw-rounded-3xl lg:[&>img]:tw-rounded-[32px]">
        <img src={state1} alt="state 1" />
        <img src={state2} alt="state 2" />
        <img src={state3} alt="state 3" />
        <img src={state4} alt="state 4" />
        <img src={state5} alt="state 5" />
        <img src={state6} alt="state 6" />
      </div>
      <div className="tw-mb-32 max-lg:tw-mb-28 max-sm:tw-mb-24 tw-flex tw-justify-center">
        <div className="tw-max-w-4xl">
          <span className="tw-text-[24px] tw-text-blueMedium max-lg:tw-text-[22px] max-sm:tw-text-[20px]">
            {'Private, secure, and reusable digital identity that works for everyone. '}
          </span>
          <span className="tw-text-[24px] tw-text-black max-lg:tw-text-[22px] max-sm:tw-text-[20px]">
            Open standards for machine-verifiable credentials reimagine digital identity. GlobaliD is bringing these breakthroughs to the real world.
          </span>
        </div>
      </div>
    </>
  );
}
