import {
  AlignmentTypes,
  BlockTitleTypes,
  BlockTypes,
  BlockWrapperTypes,
  // ButtonActionTypes, ButtonTypes,
  SectionTypes,
} from '../constants/ui';

import backgroundIndustries from '../assets/images/backgrounds/backgroundIndustries.jpg';
import { ventureInfoBlocks } from './venture';
// import iconHowWeDo1 from '../assets/images/venture/how-we-do-1.svg';
// import iconHowWeDo2 from '../assets/images/venture/how-we-do-2.svg';
// import iconHowWeDo3 from '../assets/images/venture/how-we-do-3.svg';
// import iconHowWeDo4 from '../assets/images/venture/how-we-do-4.svg';
import { sectionOurApproach } from './pageAgency';

const sectionCover = {
  sectionType: SectionTypes.Static,
  index: 28,
  config: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
    minHeight: 100,
    contendEndFixed: true,
    background: {
      backgroundImage: {
        image1x: backgroundIndustries,
      },
    },
  },
  blockType: BlockTypes.PageDefault,
  blockData: {
    config: {
      color: '#000000',
      zIndex: 30,
      wrapperClassName: 'block-page-cover-centered tw-max-w-[600px]',
      contentPosition: BlockWrapperTypes.Fixed,
      contentAlignment: AlignmentTypes.CC,
      mobile: {
        contentAlignment: AlignmentTypes.CC,
      },
    },
    content: {
      contentConfigs: {
        title: {
          fontSize: '3.5rem',
          marginTop: 0,
          paddingRight: '20px',
          paddingLeft: '20px',
          color: '#FFFFFF',
        },
        description: {
          fontSize: '1.25rem',
          fontFamily: 'gilroy-semibold, serif',
          color: '#FFFFFF',
        },
      },
      title: 'Get the most from our expertise',
      // eslint-disable-next-line max-len
      description: 'We have gained a diverse range of experience and developed a deep understanding of various industries through our engagement in a wide range of projects.',
    },
  },
};

const sectionTitle = {
  sectionType: SectionTypes.Sticky,
  index: 34,
  config: {
    color: '#000000',
    backgroundColor: '#F7F8FA',
    minHeight: 200,
    contendEndFixed: true,
  },
  blockType: BlockTypes.PageTitle,
  blockData: {
    config: {
      contentPosition: BlockWrapperTypes.Fixed,
      color: '#000000',
      specialColor: '#6b37ff',
      backgroundColor: '#F7F8FA',
      zIndex: 36,
      showContactBtn: false,
    },
    content: {
      blocks: [
        {
          textType: BlockTitleTypes.Colored,
          // eslint-disable-next-line max-len
          text: 'Our expertise is multifaceted',
        },
        {
          textType: BlockTitleTypes.Static,
          // eslint-disable-next-line max-len
          text: ' and allows us to approach complex and nuanced challenges with a well-rounded perspective.',
        },
      ],
    },
  },
};

// const sectionHowWeDo = {
//   sectionType: SectionTypes.Static,
//   index: 78,
//   blockType: BlockTypes.PageText,
//   config: {
//     color: '#FFFFFF',
//     backgroundColor: '#000000',
//     minHeight: 80,
//   },
//   blockData: {
//     config: {
//       color: '#FFFFFF',
//       isImageLeft: false,
//       zIndex: 80,
//     },
//     content: {
//       header: {
//         title: 'How We Do',
//       },
//       blocks: [
//         {
//           icon: iconHowWeDo1,
//           title: 'We share our network',
//           description: 'Sharing our experience and connections gives more value and success to our partner companies.',
//         },
//         {
//           icon: iconHowWeDo2,
//           title: 'We trust people',
//           description: 'We are confident that each partnership starts with mutual vibes and love.',
//         },
//         {
//           icon: iconHowWeDo3,
//           title: 'We take the risk',
//           description: 'We entirely understand the risks from the very beginning and share them with founders.',
//         },
//         {
//           icon: iconHowWeDo4,
//           title: 'We embrace changes',
//           description: 'By taking a proactive approach we anticipate problems and make things happen.',
//         },
//       ],
//       footer: {
//         button: {
//           label: 'Contact',
//           type: ButtonTypes.ContactButton,
//           action: {
//             type: ButtonActionTypes.Mail,
//             email: 'info@milinator.com',
//           },
//         },
//       },
//     },
//   },
// };

export const pageIndustries = {
  config: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
  },
  sections: [
    sectionCover,
    sectionTitle,
    ...ventureInfoBlocks,
    sectionOurApproach,
  ],
  seo: {
    title: 'Groksmith: Venture Studio',
    // eslint-disable-next-line max-len
    description: 'Our Venture Studio works side by side with Startups as a Co-founder to solve real problems. We match business ideas with product leaders, engineers, and marketers to execute those ideas.',
    name: 'Groksmith website',
  },
};
