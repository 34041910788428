import React from 'react';
import architectureImage from '../../../../../assets/images/projects/dewa/architecture.jpg';

export function Architecture() {
  return (
    <div className="tw-text-black">
      <h2 className="tw-pb-28">System Architecture</h2>
      <img className="tw-max-w-[1000px] tw-w-full tw-h-auto" src={architectureImage} alt="" />
    </div>
  );
}
