import React from 'react';

import {
  AlignmentTypes, BlockTitleTypes, BlockTypes, BlockWrapperTypes, SectionTypes,
} from '../constants/ui';

import backgroundCareers from '../assets/images/backgrounds/background-career.jpg';
import benefit1 from '../assets/images/career/benefits-1.svg';
import benefit2 from '../assets/images/career/benefits-2.svg';
import benefit3 from '../assets/images/career/benefits-3.svg';
import benefit4 from '../assets/images/career/benefits-4.svg';
import benefit5 from '../assets/images/career/benefits-5.svg';
import benefit6 from '../assets/images/career/benefits-6.svg';
import benefit7 from '../assets/images/career/benefits-7.svg';
import benefit8 from '../assets/images/career/benefits-8.svg';
import { ourValues } from './pageTeam';
import { Jobs } from '../modules/pages/views/Jobs';

const sectionCover = {
  sectionType: SectionTypes.Static,
  index: 28,
  config: {
    color: '#000000',
    minHeight: 100,
    contendEndFixed: true,
    backgroundColor: '#FFFFFF',
    background: {
      backgroundImage: {
        image1x: backgroundCareers,
      },
    },
  },
  blockType: BlockTypes.PageCover,
  blockData: {
    config: {
      color: '#000000',
      backgroundColor: '#FFFFFF',
      contentPosition: BlockWrapperTypes.Fixed,
      contentAlignment: AlignmentTypes.CC,
      mobile: {
        contentAlignment: AlignmentTypes.CC,
      },
      zIndex: 30,
    },
    content: {
      title: 'Careers at Groksmith',
      // eslint-disable-next-line max-len
      description: 'This is a place where you can make an impact, invest your talent and energy. Everything matters here!',
    },
  },
};

const sectionTitle = {
  sectionType: SectionTypes.Sticky,
  index: 32,
  config: {
    color: '#000000',
    backgroundColor: '#f7f8fa',
    minHeight: 200,
    contendEndFixed: true,
  },
  blockType: BlockTypes.PageTitle,
  blockData: {
    config: {
      contentPosition: BlockWrapperTypes.Fixed,
      color: '#000000',
      specialColor: '#6b37ff',
      backgroundColor: '#F7F8FA',
      zIndex: 34,
      showContactBtn: false,
    },
    content: {
      blocks: [
        {
          textType: BlockTitleTypes.Static,
          // eslint-disable-next-line max-len
          text: 'Find your team to begin a new story in the ',
        },
        {
          textType: BlockTitleTypes.Colored,
          // eslint-disable-next-line max-len
          text: 'House of Grok.',
        },
      ],
    },
  },
};

const sectionJobs = {
  sectionType: SectionTypes.Static,
  index: 50,
  config: {
    color: '#000000',
    backgroundColor: '#F7F8FA',
    minHeight: 80,
  },
  blockType: BlockTypes.PageText,
  blockData: {
    config: {
      color: '#000000',
      backgroundColor: '#000',
      zIndex: 80,
    },
    content: {
      component: (props) => (
        <Jobs {...props} />
      ),
      blocks: [],
    },
  },
};

export const careerBenefits = {
  sectionType: SectionTypes.Static,
  index: 40,
  blockType: BlockTypes.PageText,
  config: {
    color: '#000000',
    backgroundColor: '#FCFCFC',
    minHeight: 80,
  },
  blockData: {
    config: {
      color: '#000000',
      blocks: {
        textAlign: 'left',
      },
      zIndex: 42,
    },
    content: {
      header: {
        title: 'Benefits',
      },
      blocks: [
        {
          icon: benefit1,
          title: 'Insurance',
          // eslint-disable-next-line max-len
          description: 'We provide premium coverage for medical, surgical, prescription drug, and dental expenses.',
        },
        {
          icon: benefit2,
          title: 'Free learning courses',
          // eslint-disable-next-line max-len
          description: 'We conduct our internal university grade courses for our team members.',
        },
        {
          icon: benefit3,
          title: 'Friendly and inspiring work environment',
          // eslint-disable-next-line max-len
          description: 'Attentiveness is one of our core values. We respect and support each other, always think positively, share our routine, interests, and crazy ideas.',
        },
        {
          icon: benefit4,
          title: 'Great office location',
          // eslint-disable-next-line max-len
          description: 'Situated right in the heart of the city, with the balcony and backyard, convenient for various team activities such as talks, open-air workshops, parties, etc.',
        },
        {
          icon: benefit5,
          title: 'Gym membership | Partial coverage',
          // eslint-disable-next-line max-len
          description: 'We encourage work-life balance and healthy living.',
        },
        {
          icon: benefit6,
          title: 'Childbirth bonuses',
          // eslint-disable-next-line max-len
          description: 'Our new family members deserve the best!',
        },
        {
          icon: benefit7,
          title: 'Employee referral program',
          // eslint-disable-next-line max-len
          description: 'Recommendations are welcome! We are looking for like-minded individuals to join our big family.',
        },
        {
          icon: benefit8,
          title: 'Music studio',
          // eslint-disable-next-line max-len
          description: 'The most lovely corner at our office where you can record music, podcasts, video blogs, etc.',
        },
      ],
    },
  },
};

export const pageCareers = {
  config: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
  },
  sections: [
    sectionCover,
    sectionTitle,
    ourValues,
    careerBenefits,
    sectionJobs,
  ],
  seo: {
    title: 'Groksmith: Careers at Groksmith',
    // eslint-disable-next-line max-len
    description: 'Find your team to begin a new story in the house of Grok.',
    name: 'Groksmith website',
  },
};
