import React from 'react';

export function Jobs() {
  return (
    <div className="tw-flex tw-justify-center tw-items-center tw-flex-col">
      <div className="tw-mb-20 tw-text-center">
        <h1 className="tw-mt-0 tw-mb-1 tw-text-1xl tw-font-medium">Check our current openings</h1>
        <p className="tw-text-center tw-text-base tw-font-normal tw-font-medium">
          Let’s grow together!
        </p>
      </div>
      <div className="tw-w-full">
        <div className="tw-rounded-[14px] tw-px-6 tw-py-3 tw-w-full tw-h-16 tw-flex tw-bg-white tw-items-center">
          <p className="tw-text-base tw-font-normal tw-font-medium">No job openings available</p>
        </div>
      </div>
    </div>
  );
}
