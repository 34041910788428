import React from 'react';

import { ListItem } from './ListItem';

import messageImage from '../../../../assets/images/referralProgram/message.svg';
import commissionImage from '../../../../assets/images/referralProgram/commission.svg';
import exchangeImage from '../../../../assets/images/referralProgram/exchange.svg';

export function ReferralWork() {
  const listItems = [
    {
      icon: messageImage,
      title: 'Send Us a Message',
      description: 'Do you have a client who could benefit from our services? Simply drop us a line through the below form, and we will take it from there.',
    },
    {
      icon: exchangeImage,
      title: 'Strategic Logo Exchange',
      description: "An optional logo exchange can boost trust and discoverability. Display our logo on your site, and we'll feature yours in our trusted partners section.",
    },
    {
      icon: commissionImage,
      title: 'Enjoy Your 10% Commission',
      description: 'We’ll sign an MOU and transfer you the 10% commission for every referred business that starts a project with us. Your referee gets 10% discount.',
    },
  ];

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-[24px] tw-h-full">
      <h3 className="tw-mb-24 tw-text-[28px]">How referral works</h3>
      <div className="tw-flex max-sm:tw-flex-col tw-gap-16 tw-justify-center max-sm:tw-items-center tw-w-full tw-h-fit">
        {listItems.map((item) => <ListItem className="tw-max-w-[500px] max-sm:tw-max-w-[320px]" item={item} />)}
      </div>
    </div>
  );
}
