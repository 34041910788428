import React from 'react';

import {
  AlignmentTypes,
  BlockTypes,
  // BlockWrapperTypes,
  SectionTypes,
} from '../constants/ui';

import { ContactForm, Cover, ReferralWork } from '../modules/pages/views/ReferralProgram';
import { clientListSVG } from './clientList';

import seo1 from '../assets/images/seo/1200x639.jpg';
import seo2 from '../assets/images/seo/2400x1260.jpg';
import seo3 from '../assets/images/seo/400x200.jpg';

import cubeImage from '../assets/images/referralProgram/cube.png';
// import floteImage from '../assets/images/referralProgram/cover.png';

const sectionCover = {
  sectionType: SectionTypes.Static,
  blockType: BlockTypes.PageText,
  index: 28,
  config: {
    color: '#000000',
    contentAlignment: AlignmentTypes.CC,
    zIndex: 32,
    backgroundColor: '#ECECEC',
  },
  blockData: {
    config: {
      color: '#000000',
      height: 100,
      zIndex: 32,
      specialColor: '#6b37ff',
      backgroundColor: '#ECECEC',
      contentAlignment: AlignmentTypes.CC,
    },
    content: {
      component: (props) => <Cover {...props} />,
    },
  },
};

const sectionClients = {
  sectionType: SectionTypes.Static,
  blockType: BlockTypes.PageIcons,
  index: 36,
  config: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
    minHeight: 50,
  },
  blockData: {
    config: {
      zIndex: 38,
    },
    content: {
      header: {
        title: 'We have built solutions for',
        styles: { fontSize: '28px' },
      },
      blocks: clientListSVG,
    },
  },
};

const sectionTextImage = {
  sectionType: SectionTypes.Static,
  index: 34,
  blockType: BlockTypes.PageTextImage,
  config: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
    minHeight: 100,
  },
  blockData: {
    config: {
      color: '#000000',
      isImageLeft: true,
      zIndex: 36,
    },
    content: {
      image: {
        image1: cubeImage,
        alt: 'groksmith agency concept development',
        styles: {
          maxWidth: '440px',
        },
      },
      text: {
        styles: {
          header: {
            fontSize: '1.75rem',
            marginTop: 0,
          },
          description: {
            fontSize: '1.25rem',
          },
        },
        header: {
          title: 'Why you\'d love working with us',
          description: 'We give our clients everything they need to build, grow and scale their startups, by creating exceptional quality experiences backed by sustainable technology. Our team consists of professionals with up to 15 years of experience in the product design, custom and web3 development.',
          blocks: [],
        },
      },
    },
  },
};

const sectionReferralWork = {
  sectionType: SectionTypes.Static,
  blockType: BlockTypes.PageText,
  config: {
    minHeight: 80,
    backgroundColor: '#fff',
  },
  blockData: {
    config: {
      color: '#000000',
    },
    content: {
      component: (props) => <ReferralWork {...props} />,
    },
  },
};

const sectionConnect = {
  sectionType: SectionTypes.Static,
  index: 78,
  blockType: BlockTypes.PageText,
  config: {
    color: '#000000',
    backgroundColor: '#F7F8FA',
    minHeight: 70,
    padding: 0,
  },
  blockData: {
    config: {
      color: '#000000',
      zIndex: 60,
      padding: 0,
      contentAlignment: AlignmentTypes.CC,
    },
    content: {
      component: (props) => <ContactForm {...props} />,
    },
  },
};

export const pageReferralProgram = {
  config: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
  },
  sections: [
    sectionCover,
    sectionTextImage,
    sectionClients,
    sectionReferralWork,
    sectionConnect,
  ],
  seo: {
    title: 'Groksmith: Software Design and Development Company',
    // eslint-disable-next-line max-len
    description: 'Groksmith is a Yerevan-based Tech Company that delivers full-cycle services to global businesses | Product Strategy, Design, Development, and Growth Hacking.',
    name: 'Groksmith website',
    image1: seo1,
    image2: seo2,
    image3: seo3,
  },
};
