import { StoreActionConfigAppConfig } from './actions';

export const appConfigStateReducer = (
  state,
  action,
) => {
  switch (action.type) {
    case StoreActionConfigAppConfig.AppConfigSetIsReady:
      return {
        ...state,
        isReady: action.payload,
      };

    case StoreActionConfigAppConfig.AppConfigSetPreloaded:
      return {
        ...state,
        isPreloaded: action.payload,
      };

    default:
      return state;
  }
};
