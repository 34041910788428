import React from 'react';

import { ListItem } from './ListItem';

import concertoImage from '../../../../../assets/images/projects/dewa/icons/concerto.svg';
import controlsImage from '../../../../../assets/images/projects/dewa/icons/controls.svg';
import reportsImage from '../../../../../assets/images/projects/dewa/icons/reports.svg';
import executionImage from '../../../../../assets/images/projects/dewa/icons/execution.svg';
import frame1 from '../../../../../assets/images/projects/dewa/frame1.jpg';
import frame2 from '../../../../../assets/images/projects/dewa/frame2.jpg';
import frame3 from '../../../../../assets/images/projects/dewa/frame3.jpg';
import frame4 from '../../../../../assets/images/projects/dewa/frame4.jpg';
import frame5 from '../../../../../assets/images/projects/dewa/frame5.jpg';

export function UsageInfo() {
  const listItems = [
    {
      icon: concertoImage,
      description: 'Concerto API - a distributed energy optimization software platform that can gather event data and transmit it to read and write real-time data streams to enable two-way communication',
    },
    {
      icon: controlsImage,
      description: 'Capabilities to control VPPs directly from the system',
    },
    {
      icon: reportsImage,
      description: 'Exportable daily and weekly reports',
    },
    {
      icon: executionImage,
      description: 'Customizable display settings and notifications system to enable automatic execution of control events',
    },
  ];

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-between tw-text-[24px]">
      <h3 className="tw-mb-40">The key features</h3>
      <div className="tw-flex tw-flex-wrap tw-gap-16 tw-justify-center  tw-max-w-[600px] max-sm:tw-max-w-[320px]">
        {listItems.map(({ icon, description }) => <ListItem icon={icon} description={description} />)}
      </div>
      <div className="tw-relative tw-max-w-[70%] max-lg:tw-max-w-full tw-flex tw-flex-col tw-gap-16 tw-justify-center tw-mt-52 [&>img]:tw-rounded-md [&>img]:tw-shadow-xl">
        <img src={frame1} alt="frame 1" />
        <img src={frame2} alt="frame 2" />
        <img src={frame3} alt="frame 3" />
        <img src={frame4} alt="frame 4" />
        <img src={frame5} alt="frame 5" />
      </div>
      <div className=" tw-mt-52 tw-mb-28">
        <div className="tw-max-w-3xl tw-text-center max-lg:tw-text-center">
          <span className="tw-text-[22px] tw-text-black max-lg:tw-text-[20px] max-sm:tw-text-[18px]">
            {'The implementation of a VPP (Virtual Power Plant) system has proven to be effective in helping DEWA (Dubai Electricity and Water Authority) in '}
          </span>
          <span className="tw-text-[22px] tw-text-greenMedium max-lg:tw-text-[20px] max-sm:tw-text-[18px]">
            minimizing power wastage and fostering a sustainable and dependable energy supply.
          </span>
        </div>
      </div>
    </div>
  );
}
