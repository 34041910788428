import React, { createContext, useReducer } from 'react';
import { appDeviceInitialState } from './initialState';
import { appDeviceStateReducer } from './reducer';

export const AppDeviceContext = createContext(appDeviceInitialState);

export function AppDeviceStateProvider({ children }) {
  const [deviceState, dispatchDeviceState] = useReducer(appDeviceStateReducer, appDeviceInitialState, undefined);

  const value = React.useMemo(() => ({
    deviceState, dispatchDeviceState,
  }), [deviceState]);

  return (
    <AppDeviceContext.Provider
      value={value}
    >
      {
        children
      }
    </AppDeviceContext.Provider>
  );
}
