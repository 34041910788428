export const API_ROOT = process.env.REACT_APP_API_BASE_URL || '';
export const API_URL = process.env.REACT_APP_API_URL;

export const defaultLocalIndex = 'groksmith-web';

const EVENTS_API_ROOT = 'https://www.eventbriteapi.com/v3/';

export const API_AUTH = {
  POST_SIGN_IN: `${API_ROOT}login/`,
  GET_SIGN_OUT: `${API_ROOT}logout/`,
  POST_SIGN_UP: `${API_ROOT}registration/`,
  POST_FORGOT: `${API_ROOT}password/reset/`,
  POST_RESET: `${API_ROOT}password/reset/confirm/`,
  POST_EMAIL_VERIFICATION: `${API_ROOT}registration/verify-email/`,
  POST_TOKEN: `${API_ROOT}token/refresh/`,
  POST_EMAIL_VALIDATION: `${API_ROOT}email/validate/`,
};

export const EVENTS_API = {
  GET_COMPANY_EVENTS_TEST: `${EVENTS_API_ROOT}subcategories/3003/`,
  GET_COMPANY_EVENTS: `${EVENTS_API_ROOT}organizations/34003404245/events/`,
};

export const HttpRequestHeaderTypes = {
  Json: 'JSON',
  MultiPart: 'MultiPart',
};

export const RequestStatusTypes = {
  Waiting: 'Waiting',
  Loading: 'Loading',
  Success: 'Success',
  Error: 'Error',
};

export const RequestTypes = {
  Get: 'GET',
  Post: 'POST',
  Put: 'PUT',
  Delete: 'DELETE',
  Patch: 'PATCH',
};

export const DISCOVERED_FROM = {
  search_engine: 'search engine',
  social_media: 'social media',
  recommendation: 'recommendation',
  goodfirms: 'goodfirms',
  other: 'other',
};
