import React from 'react';

export function IconArrow({ iconColor }) {
  return (
    <svg
      width="1.8125rem"
      height="0.9375rem"
      viewBox="0 0 29 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ willChange: 'transform' }}
    >
      <path fill={iconColor || '#FFF'} d="M0,8h26.7l-3.8,5.2l0.9,1.2L29,7.2L23.7,0l-0.9,1.2l3.8,5.2H0V8z" />
    </svg>
  );
}
