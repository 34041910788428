import React from 'react';

export function ListItem({ icon, description }) {
  return (
    <div className="tw-text-start tw-pl-6 tw-pr-4 tw-py-[18px] tw-rounded-xl tw-w-full">
      <img src={icon} alt="icon" />
      <p className="tw-mt-1 tw-text-[14px] tw-font-medium">
        {description}
      </p>
    </div>
  );
}
