import React, { useEffect, useState } from 'react';
import { IconArrowDown } from '../icons/IconArrowDown';

function CustomSelect(props) {
  const { defaultText, hasError, onSelect } = props;
  const [defaultSelectText, setDefaultSelectText] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [showOptionList, setShowOptionList] = useState(false);

  const handleClickOutside = (e) => {
    if (
      !e.target.classList.contains('custom-select-option')
      && !e.target.classList.contains('selected-text')
    ) {
      setShowOptionList(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    setDefaultSelectText(defaultText);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleListDisplay = () => {
    setShowOptionList(!showOptionList);
  };

  const handleOptionClick = (option) => {
    setSelectedItem(option.name);
    onSelect(option.value);
    setShowOptionList(false);
  };

  const { optionsList } = props;
  return (
    <div className={`${hasError ? 'error-select' : 'custom-select-container'}`}>
      <button
        type="button"
        className={hasError ? 'selected-text-error' : 'selected-text'}
        onClick={() => handleListDisplay()}
      >
        {selectedItem || <p className={`${hasError ? 'selected-text-error' : 'select-default-text'}`}>{defaultSelectText}</p>}
        <IconArrowDown isOpen={showOptionList} />
      </button>
      {showOptionList && (
        <ul className="select-options">
          {optionsList.map((option) => (
            <li
              className="custom-select-option"
              data-name={option.name}
              key={option.id}
              onClick={() => handleOptionClick(option)}
            >
              {option.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default CustomSelect;
