import React from 'react';
import { useNavigate } from 'react-router-dom';

export function ButtonContact(props) {
  const {
    label,
    name,
  } = props;
  const navigate = useNavigate();
  const onClick = () => {
    navigate('/contact');
  };

  return (
    <button
      type="button"
      className="button-email"
      aria-label={name || label}
      onClick={onClick}
    >
      {label}
    </button>
  );
}
