import React from 'react';

import { BlockImage } from '../BlockImage';

export function BlockBackground(props) {
  const {
    backgroundImage,
    backgroundVideo,
    className,
    index,
  } = props;

  if (!backgroundVideo && !backgroundImage) {
    return null;
  }

  return (
    <div
      className={`${
        className || 'block-background-container'
      }`}
      style={{
        width: '100%',
        height: '100%',
        zIndex: `${index}`,
      }}
    >
      {
        backgroundVideo && (
        <div
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            zIndex: '-1',
            backgroundColor: '#000000',
          }}
        >
          <video
            className="background-video"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            autoPlay
            muted
            loop
            playsInline
          >
            <source src={backgroundVideo} type="video/mp4" />
          </video>
        </div>
        )
      }
      {
        (!backgroundVideo && backgroundImage?.image1x)
        && (
          <BlockImage
            image1={backgroundImage.image1x}
            image2={backgroundImage.image2x}
            image3={backgroundImage.image3x}
            alt="cover-background"
          />
        )
      }
    </div>
  );
}
