import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { HelmetProvider } from 'react-helmet-async';
import { AppConfig } from '../AppConfig';
import { AppRoutes } from '../AppRoutes';
import { GlobalStateProvider } from '../../../dao/store/providers';
import { AppHeader } from '../AppHeader';
import { useStoreConfigAppConfig } from '../../../dao/store/hoox';
import { AppLoader } from '../AppLoader';

function AppLayout() {
  const { configState } = useStoreConfigAppConfig();

  if (configState.isReady) {
    return (
      <>
        <AppRoutes />
        <AppHeader />
      </>
    );
  }

  return null;
}
const helmetContext = {};

export function AppWrapper() {
  return (
    <HelmetProvider context={helmetContext}>
      <Router>
        <GlobalStateProvider>
          <AppConfig />
          <AppLoader />
          <AppLayout />
        </GlobalStateProvider>
      </Router>
    </HelmetProvider>
  );
}
