import React from 'react';

export function SectionDelayWrapper(props) {
  const { children } = props;

  return (
    <div
      className="section-delay-wrapper"
    >
      {
        children && (children)
      }
    </div>
  );
}
