import React, { useEffect, useRef, useState } from 'react';

import { useStoreConfigAppDevice } from '../../../../dao/store/states/ConfigState';
import { returnImageStyle } from '../../../../helper/images';
import { AlignmentTypes, ImageContainTypes } from '../../../../constants/ui';

export const BlockImage = React.memo((props) => {
  const {
    className,
    image1,
    image2,
    image3,
    alt,
    contain = ImageContainTypes.Cover,
    alignment = AlignmentTypes.CC,
    config,
  } = props;

  const imageContainer = useRef(null);
  const { deviceState } = useStoreConfigAppDevice();
  const [imgOpacity, setImgOpacity] = useState(0);
  const [imgTag, setImgTag] = useState(null);

  const [originalSize, setOriginalSize] = useState({
    width: 1,
    height: 1,
  });

  const [currentSize, setCurrentSize] = useState({
    width: '1px',
    height: '1px',
    marginLeft: '0',
    marginTop: '0',
  });
  const [containerSize, setContainerSize] = useState({ width: 0, height: 0 });
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    if (
      imageContainer
      && imageContainer?.current
      && imageContainer?.current?.offsetWidth
      && originalSize.width > 0) {
      setCurrentSize(
        returnImageStyle(
          containerSize.width,
          containerSize.height,
          originalSize.width,
          originalSize.height,
          alignment,
          contain,
        ),
      );
      if (!opacity) {
        setOpacity(1);
      }
    }
  }, [
    containerSize.width,
    containerSize.height,
    originalSize,
    deviceState.deviceScreen.browserHeight,
    deviceState.deviceScreen.browserWidth,
  ]);

  useEffect(() => {
    if (imageContainer?.current?.offsetWidth) {
      setContainerSize({
        width: imageContainer?.current?.offsetWidth,
        height: imageContainer?.current?.offsetHeight,
      });
    }
  }, [
    imageContainer?.current?.offsetWidth,
    imageContainer?.current?.offsetHeight,
  ]);

  useEffect(() => {
    setImgTag(null);
    setImgOpacity(0);
  }, [image1]);

  useEffect(() => {
    if (imgTag === null) {
      const customImage = new Image();
      customImage.src = image1;
      customImage.srcset = `${image1} 1x,  ${image2 || image1} 2x , ${image3 || image1} 3x`;
      customImage.alt = ` ${alt} `;
      customImage.onload = (evt) => {
        if (setOriginalSize && setImgTag && !imgTag) {
          setOriginalSize({
            width: evt.target.naturalWidth,
            height: evt.target.naturalHeight,
          });
          setImgTag(customImage);
        }
      };
    } else {
      setImgOpacity(1);
    }
  }, [imgTag]);

  return (
    <>
      {
        image1 && (
          <div
            className={
              className || 'block-image-container'
            }
            ref={imageContainer}
            style={{
              width: '100%',
              height: '100%',
              willChange: 'transform',
              position: 'relative',
              overflow: 'hidden',
              backgroundColor: config?.backgroundColor || 'transparent',
            }}
          >
            {
              imgTag && (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  src={imgTag.src}
                  srcSet={imgTag.srcSet}
                  style={{ ...currentSize, opacity: imgOpacity }}
                  alt={imgTag.alt}
                />
              )
            }
          </div>
        )
      }
    </>
  );
}, (prevProps, nextProps) => prevProps.image1 === nextProps.image1);
