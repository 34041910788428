import { useContext } from 'react';
import { AppConfigContext } from './State';

export const useStoreConfigAppConfig = () => {
  const { configState, dispatchConfigState } = useContext(AppConfigContext);

  return {
    configState,
    dispatchConfigState,
  };
};
