import { useContext } from 'react';
import { AppModalContext } from './State';

export const useStoreConfigAppModal = () => {
  const { modalState, dispatchModalState } = useContext(AppModalContext);

  return {
    modalState,
    dispatchModalState,
  };
};
