import { useContext } from 'react';
import { ClientContext } from './State';

export const useStoreClient = () => {
  const { clientState, dispatchClientState } = useContext(ClientContext);

  return {
    clientState,
    dispatchClientState,
  };
};
