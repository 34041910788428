import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  useStoreConfigAppConfig,
  useStoreConfigAppDevice, useStoreConfigAppHeader,
  useStoreConfigAppLoader,
} from '../../../dao/store/hoox';
import {
  StoreActionConfigAppConfig,
  StoreActionConfigAppDevice, StoreActionConfigAppHeader,
  StoreActionConfigAppLoader,
} from '../../../dao/store/actions';
import { useEffectOnce } from '../../../hoox/useEffectOnce';
import { DeviceTypes } from '../../../constants/device';

export function AppConfig() {
  const { dispatchConfigState } = useStoreConfigAppConfig();
  const { dispatchLoaderState } = useStoreConfigAppLoader();
  const { headerState, dispatchHeaderState } = useStoreConfigAppHeader();
  const { deviceState, dispatchDeviceState } = useStoreConfigAppDevice();
  const localHistory = useLocation();

  const calculateFontSize = (width, height, ratio) => {
    let newFontSize;

    if (width > height) {
      newFontSize = height / 100;
    } else {
      newFontSize = width / 100;
    }

    if (newFontSize < 16) {
      newFontSize = 16;
    }

    if (width < 490 || height < 490) {
      newFontSize = 14;
    }

    if (width < 362 || height < 362) {
      newFontSize = 12;
    }

    if (ratio < 1) {
      newFontSize *= 1 / ratio;
    }
    document.documentElement.style.setProperty('--font-size-primary', `${newFontSize}px`);
  };

  const calculateWindowHeight = () => {
    if (window.screen.height > window.document.documentElement.offsetHeight + 50) {
      if (deviceState.deviceScreen.height > window.document.documentElement.offsetHeight + 50) {
        return window.document.documentElement.offsetHeight;
      }
    }
    return window.screen.height;
  };

  const windowResize = () => {
    const newWidth = window.innerWidth;
    const newHeight = calculateWindowHeight();
    const newRatio = window.devicePixelRatio;
    dispatchDeviceState({
      type: StoreActionConfigAppDevice.AppDeviceSetSize,
      payload: {
        width: newWidth,
        height: newHeight,
        browserWidth: window.document.documentElement.offsetWidth,
        browserHeight: window.document.documentElement.offsetHeight,
        pixelRatio: newRatio,
      },
    });
    calculateFontSize(newWidth, newHeight, newRatio);
  };

  const initialize = () => {
    dispatchConfigState({
      type: StoreActionConfigAppConfig.AppConfigSetIsReady,
      payload: true,
    });
    setTimeout(() => {
      dispatchLoaderState({
        type: StoreActionConfigAppLoader.AppConfigSetLoaderClose,
      });
    }, 500);
  };

  useEffect(() => {
    let scrollTimeout;
    if (deviceState.deviceType !== DeviceTypes.Mobile) {
      const endPoint = deviceState.deviceScroll.top;
      let currentPoint = deviceState.deviceScroll.delayTop;
      scrollTimeout = setInterval(() => {
        currentPoint += (endPoint - currentPoint) / 15;
        const roundScroll = Math.round(currentPoint * 100) / 100;
        dispatchDeviceState({
          type: StoreActionConfigAppDevice.AppDeviceSetAnimatedScroll,
          payload: roundScroll,
        });
        if (roundScroll > deviceState.deviceScroll.top - 0.1 && roundScroll < deviceState.deviceScroll.top + 0.1) {
          dispatchDeviceState({
            type: StoreActionConfigAppDevice.AppDeviceSetAnimatedScroll,
            payload: endPoint,
          });
          clearInterval(scrollTimeout);
        }
      }, 10);
    } else {
      dispatchDeviceState({
        type: StoreActionConfigAppDevice.AppDeviceSetAnimatedScroll,
        payload: deviceState.deviceScroll.top,
      });
    }

    return () => {
      if (scrollTimeout) {
        clearInterval(scrollTimeout);
      }
    };
  }, [deviceState.deviceScroll.top]);

  useEffect(() => {
    document.documentElement.style.scrollBehavior = 'auto';
    dispatchDeviceState({
      type: StoreActionConfigAppDevice.AppDeviceSetScroll,
      payload: 0,
    });
    dispatchDeviceState({
      type: StoreActionConfigAppDevice.AppDeviceSetAnimatedScroll,
      payload: 0,
    });
    if (headerState.mobile.isOpen) {
      dispatchHeaderState({
        type: StoreActionConfigAppHeader.MobileHeaderIsOpen,
        payload: false,
      });
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 5);
    setTimeout(() => {
      document.documentElement.style.scrollBehavior = 'smooth';
    }, 5);
  }, [localHistory?.pathname]);

  useEffectOnce(() => {
    window.addEventListener('resize', windowResize);
    windowResize();

    const setCallBack = setTimeout(initialize, 500);

    return () => {
      window.removeEventListener('resize', windowResize);
      clearTimeout(setCallBack);
    };
  });

  return null;
}
