import React from 'react';
import {
  AlignmentTypes, BlockGridTypes,
  BlockTypes, BlockWrapperTypes, SectionTypes,
} from '../constants/ui';

import { TeamTitle } from '../modules/shared/icons/TeamTitle';
import { TeamList } from '../modules/pages/views/Team/TeamList';
import value1 from '../assets/images/career/careers-1.svg';
import value2 from '../assets/images/career/careers-2.svg';
import value3 from '../assets/images/career/careers-3.svg';
import { ConnectButtonComponent } from '../modules/shared/icons/TeamTitle/TeamTitle';

const sectionTeam = {
  sectionType: SectionTypes.AbsoluteContent,
  index: 50,
  config: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
    minHeight: 400,
  },
  blockType: BlockTypes.PageText,
  blockData: {
    config: {
      color: '#000000',
      backgroundColor: '#000',
      zIndex: 80,
    },
    content: {
      component: (props) => (
        <TeamList {...props} />
      ),
      blocks: [],
    },
  },
};

export const ourValues = {
  sectionType: SectionTypes.Static,
  index: 36,
  blockType: BlockTypes.PageText,
  config: {
    color: '#FFFFFF',
    backgroundColor: '#000000',
    minHeight: 100,
  },
  blockData: {
    config: {
      color: '#FFFFFF',
      zIndex: 38,
      blockGridTypes: BlockGridTypes.Triple,
      transformWrapper: false,
      transformContent: true,
    },
    content: {
      header: {
        styles: {
          descriptionClassName: 'tw-max-w-[750px] tw-mx-auto',
        },
        title: 'Our Values',
        // eslint-disable-next-line max-len
        description: 'Grōksmith is about people, the team, and spirit. We believe that a key for successful development is behind a healthy balance between these 3 processes to work on.',
      },
      blocks: [
        {
          icon: value1,
          title: 'You',
          description: 'How you grow personally and achieve your goals',
        },
        {
          icon: value2,
          title: 'Team',
          description: 'How caring and respectful you are to the people you are working with',
        },
        {
          icon: value3,
          title: 'Customer',
          description: 'How concentrated you are on client and project',
        },
      ],
    },
  },
};

const sectionConnectBtn = {
  sectionType: SectionTypes.Static,
  index: 32,
  config: {
    color: '#000000',
    backgroundColor: '#F7F8FA',
    minHeight: 80,
    contendEndFixed: false,
  },
  blockType: BlockTypes.PageDefault,
  blockData: {
    config: {
      contentPosition: BlockWrapperTypes.Fixed,
      color: '#000000',
      specialColor: '#6b37ff',
      backgroundColor: '#F7F8FA',
      zIndex: 34,
      showContactBtn: true,
    },
    content: {
      component: () => <ConnectButtonComponent />,
      blocks: [],
    },
  },
};

const sectionHome = {
  sectionType: SectionTypes.Sticky,
  blockType: BlockTypes.PageCover,
  index: 28,
  config: {
    color: '#000',
    backgroundColor: '#F7F8FA',
    minHeight: 120,
    contendEndFixed: true,
  },
  blockData: {
    config: {
      color: '#FFFFFF',
      backgroundColor: '#000000',
      contentPosition: BlockWrapperTypes.Fixed,
      contentAlignment: AlignmentTypes.CC,
      mobile: {
        contentAlignment: AlignmentTypes.CC,
      },
      zIndex: 30,
    },
    content: {
      component: (props) => (
        <TeamTitle {...props} />
      ),
    },
  },
};

export const pageTeam = {
  config: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
  },
  sections: [
    sectionHome,
    sectionTeam,
    ourValues,
    sectionConnectBtn,
  ],
  seo: {
    title: 'Groksmith: Software Development Agency',
    // eslint-disable-next-line max-len
    description: 'We Build custom software that allows flexibility to required changes and delivers measurable results for our clients and their customers worldwide.',
    name: 'Groksmith website',
  },
};
