import React from 'react';
import LinkedinIcon from '../../../../assets/images/icons/linkdin.svg';
import FacebookIcon from '../../../../assets/images/icons/facebook.svg';
import InstagramIcon from '../../../../assets/images/icons/instagram.svg';
import GithubIcon from '../../../../assets/images/icons/github.svg';
import SpotifyIcon from '../../../../assets/images/icons/spotify.svg';
import OtherContactIcon from '../../../../assets/images/icons/otherContactIcon.svg';

import { FooterPrimary } from '../../../layout/footers';
import { useTeamMemberHook } from '../../../../hoox/useTeam/useTeamMember';
import { PageError } from '../../PageError';
import { PageLoader } from '../../../init/AppLoader/PageLoader';

function TextWithLineBreaks({ text }) {
  const textWithBreaks = text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      <p className="tw-text-base tw-text-black tw-font-medium">{line}</p>
      <br />
    </React.Fragment>
  ));

  return <>{textWithBreaks}</>;
}

export function TeamMemberSingle() {
  const {
    teamMemberInfo, isError, isLoading, memberSkills, memberSocialLinks,
  } = useTeamMemberHook();
  if (isError) {
    return <PageError />;
  }
  if (isLoading) {
    return <PageLoader />;
  }
  return (
    <div
      className="team-member-container"
    >
      <div className="section-wrapper tw-px-4">
        <div className="section-container tw-max-w-3xl">
          <div className="member-image-wrapper">
            <img className="tw-w-full tw-h-full tw-rounded-full" src={teamMemberInfo.logo} alt="member logo" />
          </div>
          <div className="tw-mb-4 tw-flex tw-items-center">
            <p className="tw-text-xl md:tw-text-2xl lg:tw-text-2xxl tw-text-black tw-font-regular">{teamMemberInfo.name}</p>
          </div>
          <div className="tw-mb-8">
            <p className="tw-mb-1 tw-text-base tw-text-black tw-font-medium">
              {teamMemberInfo.position}
            </p>
          </div>
          {
            teamMemberInfo.description
            && (
            <>
              <TextWithLineBreaks text={teamMemberInfo.description} />
            </>
            )
          }
          <div className="tw-bg-transparent tw-flex tw-items-center">
            {
              memberSocialLinks.linkedin
              && (
              <a target="_blank" href={memberSocialLinks.linkedin} className="tw-p-0 tw-bg-transparent tw-mr-5" type="button" rel="noreferrer">
                <img src={LinkedinIcon} alt="Linkedin" />
              </a>
              )
            }
            {
              memberSocialLinks.github
              && (
                <a target="_blank" href={memberSocialLinks.github} className="tw-p-0 tw-bg-transparent tw-mr-5" type="button" rel="noreferrer">
                  <img src={GithubIcon} alt="github" />
                </a>
              )
            }
            {
              memberSocialLinks.facebook
              && (
                <a target="_blank" href={memberSocialLinks.facebook} className="tw-p-0 tw-bg-transparent tw-mr-5" type="button" rel="noreferrer">
                  <img src={FacebookIcon} alt="Facebook" />
                </a>
              )
            }

            {
              memberSocialLinks.instagram
              && (
                <a target="_blank" href={memberSocialLinks.instagram} className="tw-p-0 tw-bg-transparent tw-mr-5" type="button" rel="noreferrer">
                  <img src={InstagramIcon} alt="Instagram" />
                </a>
              )
            }
            {
              memberSocialLinks.spotify
              && (
                <a target="_blank" href={memberSocialLinks.spotify} className="tw-p-0 tw-bg-transparent tw-mr-5" type="button" rel="noreferrer">
                  <img src={SpotifyIcon} alt="Spotify" />
                </a>
              )
            }
            {
              memberSocialLinks.other_contact
              && (
                <a target="_blank" href={memberSocialLinks.other_contact} className="tw-p-0 tw-bg-transparent tw-mr-5" type="button" rel="noreferrer">
                  <img src={OtherContactIcon} alt="Spotify" />
                </a>
              )
            }
          </div>
        </div>
      </div>
      {
        memberSkills.keys && memberSkills.keys.length > 0
        && (
        <div className="section-wrapper tw-bg-white tw-px-4">
          <div className="section-container tw-max-w-3xl">
            <h1 className="tw-m-0 tw-pt-14 tw-font-regular tw-text-xl tw-text-black tw-text-center">Skills</h1>
            <div>
              {
                memberSkills.keys.map((skillCategory, categoryIndex) => (
                  <div className="tw-mb-4" key={categoryIndex}>
                    <p className="skill-category">
                      {skillCategory}
                    </p>
                    <div className="skills-wrapper">
                      {memberSkills.skills[skillCategory].map((skillItem, index) => (<p key={`${skillItem}-${index}`} className="skill-item">{skillItem}</p>))}
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        )

      }
      {
        teamMemberInfo.projects && teamMemberInfo.projects.length > 0
        && (
        <div className="section-wrapper tw-bg-white tw-px-4">
          <div className="section-container lg:tw-max-w-3xl">
            <h1 className="tw-mb-12 tw-m-0 tw-pt-14 tw-font-regular tw-text-xl tw-text-black tw-text-center">
              Latest
              projects
            </h1>
            <div className="tw-gap-x-5 tw-flex tw-items-center  tw-flex-col md:tw-flex-row tw-justify-center">
              {
                teamMemberInfo.projects.map((projectItem) => (
                  <div
                    style={{
                    }}
                    key={projectItem}
                    className="tw-mb-4 tw-flex tw-justify-center tw-items-center tw-rounded-lg tw-bg-grayLight tw-w-projectCard tw-h-projectCard"
                  >
                    <img
                      style={{
                        maxWidth: '250px',
                        maxHeight: '250px',
                        display: 'block',
                        width: 'auto',
                        height: 'auto',
                      }}
                      className="tw-w-full tw-h-full tw-rounded-lg"
                      src={projectItem}
                      alt="latest project"
                    />
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        )
      }
      {
        (teamMemberInfo.hobbies.photo || teamMemberInfo.hobbies.name)
        && (
        <div className="section-wrapper tw-bg-grayLight tw-px-4">
          <div className="section-container tw-max-w-6xl">
            <h1 className="tw-mb-12 tw-m-0 tw-pt-14 tw-font-regular tw-text-xl tw-text-black tw-text-center">Hobbies</h1>
            <div className="tw-mb-9 tw-flex tw-justify-center tw-items-center tw-flex-col">
              <p className="tw-font-medium tw-text-base tw-text-center">{teamMemberInfo.hobbies.name}</p>
            </div>
            {
              teamMemberInfo.hobbies.photo
              && (
                <div className="tw-w-full images tw-flex tw-justify-center">
                  <img src={teamMemberInfo.hobbies.photo} alt="hobbies" className="tw-object-fill tw-w-full" />
                </div>
              )
            }
          </div>
        </div>
        )

      }
      <FooterPrimary />
    </div>

  );
}
