import React from 'react';

export function IconSocialLinkedIn(props) {
  const { className, style, colorFill = 'black' } = props;

  return (
    <svg
      width="1.375em"
      height="1.375em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      {/* eslint-disable-next-line max-len */}
      <path fill={colorFill} d="M20.2287 19.8563V13.0036C20.2287 9.33173 18.3554 7.6232 15.8577 7.6232C13.8421 7.6232 12.9387 8.78326 12.4355 9.59757V7.90409H8.63801C8.68839 9.02593 8.63801 19.8563 8.63801 19.8563H12.4355V13.1814C12.4355 12.8252 12.4599 12.4679 12.5606 12.2131C12.8346 11.499 13.4596 10.7599 14.5081 10.7599C15.8826 10.7599 16.4317 11.8557 16.4317 13.4623V19.8569H20.2287V19.8563ZM4.63735 6.27259C5.96094 6.27259 6.78632 5.35404 6.78632 4.20672C6.76196 3.03449 5.96149 2.14258 4.66282 2.14258C3.36414 2.14258 2.5144 3.03391 2.5144 4.20672C2.5144 5.35404 3.33867 6.27259 4.61355 6.27259H4.63735ZM6.5361 19.8563V7.90409H2.73915V19.8563H6.5361Z" />
    </svg>
  );
}
