import React from 'react';

export function TeamListItem({ item, navigate }) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      onClick={() => navigate(`${item.username}`)}
      className="tw-cursor-pointer tw-items-center tw-flex tw-justify-start tw-flex-col  tw-max-w-md"
    >
      <div className="tw-mb-9 tw-max-w-xs tw-max-h-xs tw-h-xs tw-w-xs">
        <img className="tw-h-full tw-w-full tw-rounded-full" src={item.logo} alt="awd" />
      </div>
      <h1 className="tw-leading-none tw-text-lg tw-mt-0 tw-mb-4 tw-font-bold">{item.name}</h1>
      <p className="tw-text-violet tw-text-base tw-mt-0 tw-mb-4 tw-font-semibold">{item.position}</p>
      <p className="tw-text-black tw-text-center tw-text-xs tw-mt-0 tw-mb-4 tw-font-semibold">
        {item.about}
      </p>
    </div>
  );
}
