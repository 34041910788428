import { StoreActionSectionState } from './actions';

export const sectionStateReducer = (
  state,
  action,
) => {
  switch (action.type) {
    case StoreActionSectionState.SetSectionMinHeight:
      return { ...state, sectionMinHeight: action.payload };
    case StoreActionSectionState.ReRender:
      return { ...state, reRender: action.payload };

    case StoreActionSectionState.SetSectionTop:
      return { ...state, sectionTop: action.payload };

    case StoreActionSectionState.SetContentTop:
      return { ...state, contentTop: action.payload };

    case StoreActionSectionState.SetSectionCurrentHeight:
      return { ...state, sectionCurrentHeight: action.payload };

    case StoreActionSectionState.SetContentCurrentHeight:
      return { ...state, contentCurrentHeight: action.payload };

    case StoreActionSectionState.SetPercent:
      return {
        ...state,
        percent: Math.floor(100 * action.payload) < 200 ? Math.floor(100 * action.payload) : 200,
      };

    case StoreActionSectionState.SetContainerParams:
      return {
        ...state,
        top: action.payload.top || 0,
        height: action.payload.height || 0,
      };

    case StoreActionSectionState.SetParentMinHeight:
      return {
        ...state,
        wrapperMinHeight: action.payload,
      };

    default:
      return state;
  }
};
