import { useContext } from 'react';
import { AppLoaderContext } from './State';

export const useStoreConfigAppLoader = () => {
  const { loaderState, dispatchLoaderState } = useContext(AppLoaderContext);

  return {
    loaderState,
    dispatchLoaderState,
  };
};
