import React, { createContext, useReducer } from 'react';
import { sectionInitialState } from './initialState';
import { sectionStateReducer } from './reducer';

export const SectionStateContext = createContext(sectionInitialState);

export function SectionStateProvider({ children }) {
  const [sectionState, dispatchSectionState] = useReducer(sectionStateReducer, sectionInitialState, undefined);
  const value = React.useMemo(() => ({
    sectionState, dispatchSectionState,
  }), [sectionState]);

  return (
    <SectionStateContext.Provider
      value={value}
    >
      {
        children
      }
    </SectionStateContext.Provider>
  );
}
