export const sectionInitialState = {
  sectionMinHeight: 0,
  sectionTop: 0,
  sectionCurrentHeight: 0,
  contentTop: 0,
  contentCurrentHeight: 0,
  percent: 0,
  top: 0,
  height: 0,
  wrapperMinHeight: 0,
  reRender: false,
};
