import React from 'react';

export function LabelCard({ title, description }) {
  return (
    <div className="tw-text-start tw-bg-[#F7F8FA] tw-pl-6 tw-pr-4 tw-py-[18px] tw-rounded-xl tw-w-[400px] max-sm:tw-w-[320px]">
      <h4 className="tw-text-blueMedium tw-text-[18px] tw-font-bold tw-m-0">{title}</h4>
      <p className="tw-mt-1 tw-text-[14px] tw-font-medium">
        {description}
      </p>
    </div>
  );
}
