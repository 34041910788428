import React, { useEffect, useRef, useState } from 'react';
import { BlockImage } from '../BlockImage';
import { AlignmentTypes, ImageContainTypes } from '../../../../constants/ui';
import { useStoreConfigAppDevice } from '../../../../dao/store/states/ConfigState';
import { BlockWrapperFix } from '../BlockWrapperFix';
import { ButtonContainer } from '../../../../components/shared/buttons/ButtonContainer';

function ImageContainer(props) {
  const { deviceState } = useStoreConfigAppDevice();

  const calculateContainerSize = (isHorizontal, deviceWidth, deviceHeight) => ({
    width: isHorizontal
      ? (deviceWidth / 2)
      : deviceWidth,
    height: isHorizontal ? deviceHeight : deviceHeight / 2,
  });

  const [containerSizes, setContainerSizes] = useState(calculateContainerSize(
    deviceState.deviceScreen.horizontal,
    deviceState.deviceScreen.browserWidth,
    deviceState.deviceScreen.browserHeight,
  ));

  useEffect(() => {
    setContainerSizes(calculateContainerSize(
      deviceState.deviceScreen.horizontal,
      deviceState.deviceScreen.browserWidth,
      deviceState.deviceScreen.browserHeight,
    ));
  }, [
    deviceState.deviceScreen.horizontal,
    deviceState.deviceScreen.width,
    deviceState.deviceScreen.browserWidth,
    deviceState.deviceScreen.browserHeight,
    deviceState.deviceScreen.pixelRatio,
  ]);

  return (
    <div
      className="image-container"
      style={{
        width: `${containerSizes.width}px`,
        height: `${containerSizes.height}px`,
      }}
    >
      <BlockImage
        {...props}
        contain={ImageContainTypes.Cover}
        alignment={
          deviceState.deviceScreen.horizontal
            ? AlignmentTypes.LC
            : AlignmentTypes.CT
        }
      />
    </div>
  );
}

function PageContainer(props) {
  const {
    header,
    blocks,
    footer,
  } = props;

  return (
    <div className="text-container">
      {
        header
        && (
          <div
            className="content-header"
          >
            {
              header?.title
              && <h2>{header.title}</h2>
            }
            {
              header?.description
              && <p>{header.description}</p>
            }
            {
              header?.button
              && <ButtonContainer {...header.button} />
            }
          </div>
        )
      }
      {
        !!blocks?.length
        && (
          <div
            className="content-blocks"
          >
            {
              blocks.map((item, key) => (
                <div
                  key={`block-text-image-${key}`}
                >
                  {
                    item.icon
                    && <img src={item.icon} alt={`block-${key}-${item.title}`} />
                  }
                  <h2>
                    {
                      item.title
                    }
                  </h2>
                  <p>
                    {
                      item.description
                    }
                  </p>
                </div>
              ))
            }
          </div>
        )
      }
      {
        footer
        && (
          <div
            className="content-footer"
          >
            {
              footer?.title
              && <h1>{footer.title}</h1>
            }
            {
              footer?.description
              && <p>{footer.description}</p>
            }
          </div>
        )
      }
    </div>
  );
}

export function BlockPageProject(props) {
  const {
    blockData,
  } = props;

  const { deviceState } = useStoreConfigAppDevice();

  const blockItem = useRef(null);

  return (
    <BlockWrapperFix
      {...blockData.config}
    >
      <div
        className={`block-page-project ${
          deviceState.deviceScreen.horizontal
            ? ''
            : 'block-page-project-vertical'
        }`}
        ref={blockItem}
        style={{
          color: blockData.config.color,
          overflow: 'hidden',
        }}
      >
        {
        blockData.content.image
        && <ImageContainer {...blockData.content.image} isImageLeft={blockData.config.isImageLeft} />
      }
        {
        blockData.content.text
        && <PageContainer {...blockData.content.text} />
      }
      </div>
    </BlockWrapperFix>
  );
}
