import React, { useState } from 'react';

import { useStoreConfigAppDevice } from '../../../../dao/store/hoox';

import { BlockWrapperFix } from '../BlockWrapperFix';
import { AlignmentTypes } from '../../../../constants/ui';
import { useEffectOnce } from '../../../../hoox/useEffectOnce';
import { ButtonContact } from '../../../../components/shared/buttons/ButtonContact';

function BlockContent(props) {
  const {
    config: { contentAlignment = AlignmentTypes.CC, wrapperClassName },
    content: {
      title, description, component, contentConfigs,
    },
  } = props;
  const [opacity, setOpacity] = useState(0);
  const { deviceState } = useStoreConfigAppDevice();

  useEffectOnce(() => {
    const timeOut = setTimeout(() => {
      setOpacity(1);
    }, 200);

    return () => {
      clearTimeout(timeOut);
    };
  });

  const calculateCoverHeight = (browserHeight, alignment) => {
    if (alignment === AlignmentTypes.CC) {
      return ((80 * browserHeight) / 100);
    }
    return ((50 * browserHeight) / 100);
  };
  return (
    <div
      className={
        `${
          component ? 'block-page-cover-component' : wrapperClassName || 'block-page-cover'
        } ${
          !deviceState.deviceScreen.horizontal ? 'block-page-cover-vertical' : ''
        }`
      }
      style={{
        height: `${
          calculateCoverHeight(
            deviceState.deviceScreen.browserHeight,
            contentAlignment,
          )
        }px`,
        willChange: 'transform',
        opacity,
      }}
    >
      {
        component
        && <props.content.component />
      }
      {
        !component && (
          <div>
            {
              title && <h1 style={contentConfigs.title ? { ...contentConfigs.title } : ''}>{title}</h1>
            }
            {
              description && <p style={contentConfigs.description ? { ...contentConfigs.description } : ''}>{description}</p>
            }
          </div>
        )
      }
      {
        contentConfigs && contentConfigs.showContactBtn
        && (
          <div className="">
            <ButtonContact label="Contact" name="Contact" />
          </div>
        )
      }
    </div>
  );
}

export function BlockPageDefault(props) {
  const {
    blockData,
  } = props;

  return (
    <BlockWrapperFix
      {...blockData.config}
    >
      <BlockContent
        {...blockData}
      />
    </BlockWrapperFix>
  );
}
