import React, { createContext, useReducer } from 'react';
import { appLoaderInitialState } from './initialState';
import { appLoaderStateReducer } from './reducer';

export const AppLoaderContext = createContext(appLoaderInitialState);

export function AppLoaderStateProvider({ children }) {
  const [loaderState, dispatchLoaderState] = useReducer(appLoaderStateReducer, appLoaderInitialState, undefined);

  const value = React.useMemo(() => ({
    loaderState, dispatchLoaderState,
  }), [loaderState]);

  return (
    <AppLoaderContext.Provider
      value={value}
    >
      {
        children
      }
    </AppLoaderContext.Provider>
  );
}
