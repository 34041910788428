import React, { useEffect, useRef, useState } from 'react';
import { useStoreSectionState } from '../../../../dao/store/states/SectionState/hoox';
import { useStoreConfigAppDevice } from '../../../../dao/store/states/ConfigState';
import { ButtonContainer } from '../../../../components/shared/buttons/ButtonContainer';
import { StoreActionSectionState } from '../../../../dao/store/states/SectionState/actions';

function PageContent(props) {
  const {
    config,
    blockTop,
    content: {
      header,
      blocks,
      footer,
      component,
    },
  } = props;

  return (
    <div
      className="page-content"
      style={{
        color: config.color || '#FFFFFF',
      }}
    >
      {
        header
        && (
          <div
            className="content-header"
          >
            {
              header?.title
              && <h2>{header.title}</h2>
            }
            {
              header?.description
              && (
              <p
                className={header.styles && header.styles.descriptionClassName ? header.styles.descriptionClassName : ''}
              >
                {header.description}
              </p>
              )
            }
            {
              header?.button
              && <ButtonContainer {...footer.button} />
            }
          </div>
        )
      }
      {
        component && <props.content.component />
      }
      {
        !!blocks?.length
        && (
          <div
            style={{ transform: config.transformContent ? `translateY(${blockTop}px)` : '' }}
            className={`content-blocks ${
              config.blockGridTypes
                ? `content-blocks-${config.blockGridTypes}`
                : ''
            }`}
          >
            {
              blocks.map((item, key) => (
                <div
                  className="content-block-item"
                  key={`block-text-image-${key}`}
                  style={{
                    textAlign: config?.blocks?.textAlign || 'center',
                  }}
                >
                  {
                    item.icon
                    && <img src={item.icon} alt={`block-${key}-${item.title}`} />
                  }
                  <h3>
                    {
                      item.title
                    }
                  </h3>
                  <p>
                    {
                      item.description
                    }
                  </p>
                </div>
              ))
            }
          </div>
        )
      }
      {
        footer
        && (
          <div
            className="content-footer"
          >
            {
              footer?.button
              && <ButtonContainer {...footer.button} />
            }
            {
              footer?.title
              && <h1>{footer.title}</h1>
            }
            {
              footer?.description
              && <p>{footer.description}</p>
            }
          </div>
        )
      }
    </div>
  );
}

export function BlockPageText(props) {
  const {
    blockData,
  } = props;

  const { deviceState } = useStoreConfigAppDevice();
  const [blockTop, setBlockTop] = useState(0);
  const {
    sectionState,
    dispatchSectionState,
  } = useStoreSectionState();

  const blockItem = useRef(null);

  useEffect(() => {
    setBlockTop(Math.abs(sectionState.sectionCurrentHeight - (sectionState.contentCurrentHeight || 0)) / 2);
  }, [
    sectionState.sectionCurrentHeight,
    sectionState.contentCurrentHeight,
  ]);

  useEffect(() => {
    if (blockItem?.current?.offsetHeight) {
      dispatchSectionState({
        type: StoreActionSectionState.SetContentCurrentHeight,
        payload: blockItem?.current?.offsetHeight,
      });
    }
    const checkResizeTimeout = setTimeout(() => {
      if (dispatchSectionState && blockItem?.current?.offsetHeight) {
        dispatchSectionState({
          type: StoreActionSectionState.SetContentCurrentHeight,
          payload: blockItem?.current?.offsetHeight,
        });
      }
    }, 500);

    return () => {
      clearTimeout(checkResizeTimeout);
    };
  }, [
    sectionState.contentCurrentHeight,
    blockItem?.current,
    blockItem?.current?.offsetHeight,
  ]);

  return (
    <div
      className={`block-page-text ${
        deviceState.deviceScreen.horizontal
          ? ''
          : 'block-page-text-vertical'
      }`}
      ref={blockItem}
      style={{
        color: blockData.config.color,
        transform: blockData.config.transformWrapper ? `translateY(${blockTop}px)` : '',
      }}
    >
      <PageContent blockTop={blockTop} {...blockData} />
    </div>
  );
}
