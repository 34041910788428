import { useContext } from 'react';
import { AppDeviceContext } from './State';

export const useStoreConfigAppDevice = () => {
  const { deviceState, dispatchDeviceState } = useContext(AppDeviceContext);

  return {
    deviceState,
    dispatchDeviceState,
  };
};
