import React from 'react';

export function IconArrowDown({ isOpen }) {
  return (
    <svg
      transform={`rotate(${isOpen ? 180 : 0})`}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.46934
         0.970032C0.609965
          0.829582 0.800589
           0.750692 0.99934
           0.750692C1.19809
           0.750692 1.38871 0.829582
            1.52934 0.970032L5.99934 5.44003L10.4693
            0.970032C10.538 0.896345 10.6208 0.837243 10.7128
            0.796251C10.8048 0.755259 10.9041 0.733217
             11.0048 0.73144C11.1055 0.729663 11.2055
              0.748188 11.2989 0.785909C11.3923 0.82363
              11.4772 0.879775 11.5484 0.950994C11.6196
               1.02221 11.6757 1.10705 11.7135 1.20043C11.7512
                1.29382 11.7697 1.39385 11.7679 1.49455C11.7662 1.59526
                11.7441 1.69457 11.7031 1.78657C11.6621 1.87857
                11.603 1.96137 11.5293 2.03003L6.52934 7.03003C6.38871
                7.17048 6.19809 7.24937 5.99934 7.24937C5.80059
                 7.24937 5.60997 7.17048 5.46934 7.03003L0.46934 2.03003C0.328889
                  1.88941 0.25 1.69878 0.25 1.50003C0.25 1.30128 0.328889
                   1.11066 0.46934 0.970032Z"
        fill="#1A1A1A"
      />

    </svg>
  );
}
