import React from 'react';
import { CustomNavLink } from '../../../components/shared/CustomNavLink';
import { IconLogoPrimary } from '../../../components/shared/icons';
import { socialIcons } from '../../../constants/socials';

export function FooterPrimary(props) {
  const { layoutType } = props;

  return (
    <div className={`footer-primary footer-primary-${layoutType}`}>
      <div className="tw-container tw-mx-auto  tw-px-2">
        <div className="tw-justify-center sm:tw-flex sm:tw-flex-wrap sm:-tw-mx-4 md:tw-py-2">
          <div className="tw-px-4 sm:tw-w-full md:tw-w-1/4 xl:tw-w-1/8">
            <h5 className="tw-h-8 tw-mt-0 tw-font-bold tw-mb-4">
              <CustomNavLink to="/" aria-label="home">
                <IconLogoPrimary width="6.375rem" height="1rem" colorFill="black" />
              </CustomNavLink>
            </h5>
            <ul className="tw-list-none tw-m-0 tw-p-0">
              <li className="tw-text-xs tw-font-medium tw-pr-7">
                We deliver full-cycle services from market research and business modeling to engineering, product design, and marketing.
              </li>
            </ul>
            <div className="tw-mt-12 tw-pt-1">
              {`© ${new Date().getFullYear()} Groksmith. All Rights Reserved.`}
            </div>
          </div>
          <div className="tw-mb-4 tw-px-4 sm:tw-w-1/2 md:tw-w-1/4 xl:tw-w-1/6">
            <div className="tw-h-8 tw-mt-0 tw-mb-4" />
            <ul className="tw-list-none tw-m-0 tw-p-0">
              <li className="tw-mb-7 tw-text-base tw-font-medium">
                <CustomNavLink to="/whatwedo/services/" aria-label="services">
                  Services
                </CustomNavLink>
              </li>
              <li className="tw-mb-7  tw-text-base tw-font-medium">
                <CustomNavLink to="/whatwedo/industries/" aria-label="industries">
                  Industries
                </CustomNavLink>
              </li>
              <li className="tw-mb-7  tw-text-base tw-font-medium">
                <CustomNavLink to="/contact/" aria-label="contact">
                  Contact
                </CustomNavLink>
              </li>
            </ul>
          </div>
          <div className="tw-px-4 sm:tw-w-1/2 md:tw-w-1/4 xl:tw-w-1/6">
            <div className="tw-h-8 tw-mt-0 tw-mb-4" />
            <ul className="tw-list-none tw-m-0 tw-p-0">
              <li className="tw-mb-7  tw-text-base tw-font-medium">
                <CustomNavLink to="/team/" aria-label="team">
                  Team
                </CustomNavLink>
              </li>
              <li className="tw-mb-7  tw-text-base tw-font-medium">
                <CustomNavLink to="/about/careers/" aria-label="carrers">
                  Careers
                </CustomNavLink>
              </li>
              <li className="tw-mb-7  tw-text-base tw-font-medium">
                <CustomNavLink to="/privacy-policy" aria-label="privacy-policy">
                  Privacy Policy
                </CustomNavLink>
              </li>
            </ul>
          </div>
          <div className="tw-px-4 sm:tw-w-full md:tw-w-1/4 xl:tw-w-1/6">
            <div className="tw-h-8 tw-mt-0 tw-mb-4" />
            <ul className="tw-list-none tw-m-0 tw-p-0">
              <li className="tw-pl-2 tw-mb-4  tw-text-base tw-font-medium">
                <CustomNavLink to="/" aria-label="services">
                  Find Us
                </CustomNavLink>
              </li>
              {
                socialIcons.length > 0 && (
                <div className="tw-flex tw-flex-row tw-gap-x-3.5 tw-items-center tw-justify-start">
                  {
                        socialIcons.map((item, index) => (
                          <a
                            href={item.path}
                            aria-label={`${item.name}`}
                            key={`social-icon-footer-${index}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item.icon}
                          </a>
                        ))
                      }
                </div>
                )
              }
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
