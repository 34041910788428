import React from 'react';

export function IconLogotypeRipple(props) {
  const { className, style } = props;

  return (
    <svg
      width="5.1875em"
      height="1.5em"
      viewBox="0 0 83 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      {/* eslint-disable-next-line max-len */}
      <path d="M30.0002 9C29.6002 8.9 29.2002 8.8 28.8002 8.8C27.3002 8.8 26.1002 9.8 25.9002 11.3V17.9H22.7002V6.2H25.7002V7.4C26.7002 6.4 28.0002 5.9 29.3002 6C29.7002 6 30.0002 6 30.3002 6L30.0002 9ZM33.6002 0C34.7002 0.1 35.5002 1.1 35.5002 2.2C35.4002 3.3 34.4002 4.1 33.3002 4.1C32.2002 4 31.4002 3.1 31.4002 2.1C31.5002 0.9 32.5002 0 33.6002 0ZM35.1002 17.9H32.0002V6.2H35.2002V17.9H35.1002ZM44.4002 6C43.2002 6 42.0002 6.4 41.0002 7.1V6.2H37.9002V23.7H41.1002V17C42.0002 17.7 43.1002 18.1 44.3002 18.1C46.0002 18.1 47.6002 17.4 48.7002 16.1C49.7002 15 50.2002 13.5 50.2002 12C50.3002 8.8 47.8002 6.1 44.6002 6C44.5002 6 44.5002 6 44.4002 6ZM44.0002 15.3C42.3002 15.4 40.8002 14.1 40.8002 12.4C40.8002 12.3 40.8002 12.2 40.8002 12.1C40.7002 10.4 42.0002 8.9 43.7002 8.9C43.8002 8.9 43.9002 8.9 44.0002 8.9C45.7002 9 47.1002 10.4 47.0002 12.1C47.1002 13.7 45.8002 15.2 44.0002 15.3C44.1002 15.2 44.1002 15.3 44.0002 15.3ZM66.1002 0.7H69.3002V17.9H66.1002V0.7ZM82.1002 16C80.9002 17.3 79.1002 18.1 77.3002 18.1C74.1002 18.3 71.3002 15.8 71.2002 12.5C71.2002 12.3 71.2002 12.1 71.2002 12C71.2002 8.8 73.4002 5.9 76.9002 5.9C80.0002 6 82.5002 8.6 82.5002 11.7C82.5002 11.8 82.5002 11.8 82.5002 11.9C82.5002 12.2 82.5002 12.5 82.4002 12.8H74.2002C74.4002 14.4 75.7002 15.6 77.4002 15.6C78.5002 15.6 79.6002 15.1 80.3002 14.3L82.1002 16ZM79.1002 10.5C79.0002 9.3 78.0002 8.4 76.8002 8.4C75.6002 8.4 74.5002 9.3 74.3002 10.5H79.1002ZM58.5002 6C57.3002 6 56.1002 6.4 55.1002 7.2V6.2H52.1002V23.7H55.3002V17C56.2002 17.7 57.3002 18.1 58.5002 18.1C60.2002 18.1 61.8002 17.4 62.9002 16.1C63.9002 15 64.4002 13.5 64.4002 12C64.4002 8.7 61.8002 6 58.5002 6ZM58.1002 15.3C56.4002 15.4 54.9002 14.1 54.9002 12.4C54.9002 12.3 54.9002 12.2 54.9002 12.1C54.8002 10.4 56.1002 8.9 57.8002 8.9C57.9002 8.9 58.0002 8.9 58.1002 8.9C59.8002 9 61.2002 10.4 61.1002 12.1C61.2002 13.7 59.9002 15.2 58.1002 15.3C58.2002 15.2 58.1002 15.3 58.1002 15.3Z" fill="#474D55" />
      {/* eslint-disable-next-line max-len */}
      <path d="M12.2001 1.9C10.9001 2.7 10.1001 4 10.1001 5.5C10.1001 6.3 10.4001 7.1 10.8001 7.7C11.3001 8.39999 11.0001 9.39999 10.3001 9.89999L10.2001 10C9.40012 10.5 8.40012 10.2 8.00012 9.39999C7.50012 8.7 7.00012 8.2 6.30012 7.7C5.00012 7 3.40012 7 2.10012 7.7C0.100119 8.89999 -0.599881 11.5 0.600119 13.5C1.00012 14.1 1.50012 14.7 2.10012 15C3.40012 15.8 5.00012 15.8 6.30012 15C7.00012 14.6 7.60012 14 7.90012 13.3C8.30012 12.5 9.30012 12.2 10.1001 12.6C10.1001 12.6 10.2001 12.6 10.2001 12.7C11.0001 13.2 11.2001 14.1 10.8001 14.9C10.4001 15.6 10.2001 16.4 10.2001 17.1C10.2001 18.6 11.0001 20 12.3001 20.7C13.6001 21.5 15.2001 21.5 16.5001 20.7C18.5001 19.5 19.2001 17 18.0001 14.9C17.6001 14.3 17.1001 13.7 16.5001 13.4C15.8001 13 15.0001 12.8 14.2001 12.9C13.3001 12.9 12.6001 12.2 12.6001 11.3C12.6001 10.4 13.3001 9.7 14.2001 9.7C15.0001 9.7 15.8001 9.5 16.5001 9.2C18.5001 8 19.2001 5.5 18.0001 3.4C17.6001 2.8 17.1001 2.2 16.5001 1.9C15.9001 1.5 15.1001 1.3 14.4001 1.4C13.6001 1.4 12.9001 1.6 12.2001 1.9Z" fill="#11AAE2" />
    </svg>
  );
}
