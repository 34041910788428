import {
  AlignmentTypes,
  BlockTypes,
  BlockWrapperTypes,
  ButtonTypes,
  SectionTypes,
} from '../constants/ui';

import projectFloteImage1 from '../assets/images/projects/project-flote-1x.jpg';
import projectFloteImage2 from '../assets/images/projects/project-flote-2x.jpg';
import projectFloteImage3 from '../assets/images/projects/project-flote.jpg';

import projectRippleImage1 from '../assets/images/projects/project-ripple-1x.jpg';
import projectRippleImage2 from '../assets/images/projects/project-ripple-2x.jpg';
import projectRippleImage3 from '../assets/images/projects/project-ripple.jpg';

import projectDubaiImage1 from '../assets/images/projects/project-dubai-1x.jpg';
import projectDubaiImage2 from '../assets/images/projects/project-dubai-2x.jpg';
import projectDubaiImage3 from '../assets/images/projects/project-dubai.jpg';

import projectGlobalIdImage1 from '../assets/images/projects/project-global-id-1x.jpg';
import projectGlobalIdImage2 from '../assets/images/projects/project-global-id-2x.jpg';
import projectGlobalIdImage3 from '../assets/images/projects/project-global-id.jpg';

import projectUefaImage1 from '../assets/images/projects/project-uefa-1x.jpg';
import projectUefaImage2 from '../assets/images/projects/project-uefa-2x.jpg';
import projectUefaImage3 from '../assets/images/projects/project-uefa.jpg';

import projectHeliosImage1 from '../assets/images/projects/project-helios-1x.jpg';
import projectHeliosImage2 from '../assets/images/projects/project-helios-2x.jpg';
import projectHeliosImage3 from '../assets/images/projects/project-helios.jpg';

import projectGiftDepotImage3 from '../assets/images/projects/project-giftDepot.jpg';

import projectPandoraImage3 from '../assets/images/projects/project-pandora.jpg';

export const projectList = [
  {
    sectionType: SectionTypes.Sticky,
    blockType: BlockTypes.PageProject,
    index: 50,
    config: {
      path: 'flote',
      color: '#000000',
      backgroundColor: '#FFFFFF',
      minHeight: 200,
      contendEndFixed: true,
    },
    blockData: {
      config: {
        color: '#000000',
        backgroundColor: '#FFFFFF',
        isImageLeft: true,
        contentPosition: BlockWrapperTypes.Fixed,
        contentAlignment: AlignmentTypes.CT,
        zIndex: 52,
      },
      content: {
        image: {
          image1: projectFloteImage1,
          image2: projectFloteImage2,
          image3: projectFloteImage3,
          alt: 'Flote Project',
        },
        text: {
          header: {
            title: 'Flote',
            // eslint-disable-next-line max-len
            description: 'A personalized investor portal created specifically for real estate entrepreneurs that helps simplify capital raising, close more deals, be more organized, and optimize time while fostering happiness amongst investors.',
            button: {
              type: ButtonTypes.ArrowButton,
              to: '/projects/flote/',
              name: 'flote',
              text: 'Learn More',
              iconColor: '#000',
            },
          },
          blocks: [
          ],
          footer: {
          },
        },
      },
    },
  },
  {
    sectionType: SectionTypes.Sticky,
    blockType: BlockTypes.PageProject,
    index: 54,
    config: {
      path: 'ripple',
      color: '#FFFFFF',
      backgroundColor: '#000000',
      minHeight: 200,
      contendEndFixed: true,
    },
    blockData: {
      config: {
        color: '#FFFFFF',
        backgroundColor: '#000000',
        isImageLeft: true,
        contentPosition: BlockWrapperTypes.Fixed,
        contentAlignment: AlignmentTypes.CT,
        zIndex: 56,
      },
      content: {
        image: {
          image1: projectRippleImage1,
          image2: projectRippleImage2,
          image3: projectRippleImage3,
          alt: 'Ripple Project',
        },
        text: {
          header: {
            title: 'Ripple',
            // eslint-disable-next-line max-len
            description: 'A real-time cryptocurrency network that aids in the quick and easy wire transfer of any currency between various countries using XRP -  payment settlement system and currency exchange network.',
          },
          blocks: [
          ],
          footer: {
          },
        },
      },
    },
  },
  {
    sectionType: SectionTypes.Sticky,
    blockType: BlockTypes.PageProject,
    index: 62,
    config: {
      path: 'helios',
      color: '#060c1a',
      backgroundColor: '#FFFFFF',
      minHeight: 200,
      contendEndFixed: true,
    },
    blockData: {
      config: {
        color: '#060c1a',
        backgroundColor: '#FFFFFF',
        isImageLeft: false,
        contentPosition: BlockWrapperTypes.Fixed,
        contentAlignment: AlignmentTypes.CT,
        zIndex: 64,
      },
      content: {
        image: {
          image1: projectHeliosImage1,
          image2: projectHeliosImage2,
          image3: projectHeliosImage3,
          alt: 'Helios',
        },
        text: {
          header: {
            title: 'Helios',
            // eslint-disable-next-line max-len
            description: 'Bringing renewable energy to web3. Helios helps anyone, anytime, anywhere to profitably fight climate change, utilizing web3 tech to maximize liquidity, demolish decade-long lockup periods, and introduce real-time transparency.',
            button: {
              type: ButtonTypes.ArrowButton,
              to: '/projects/helios/',
              name: 'helios',
              text: 'Learn More',
              iconColor: '#000',
            },
          },
          blocks: [
          ],
          footer: {
          },
        },
      },
    },
  },
  {
    sectionType: SectionTypes.Sticky,
    blockType: BlockTypes.PageProject,
    index: 58,
    config: {
      path: 'dubai-electricity',
      color: '#FFFFFF',
      backgroundColor: '#060c1a',
      minHeight: 200,
      contendEndFixed: true,
    },
    blockData: {
      config: {
        color: '#FFFFFF',
        backgroundColor: '#060c1a',
        isImageLeft: true,
        contentPosition: BlockWrapperTypes.Fixed,
        contentAlignment: AlignmentTypes.CT,
        zIndex: 60,
      },
      content: {
        image: {
          image1: projectDubaiImage1,
          image2: projectDubaiImage2,
          image3: projectDubaiImage3,
          alt: 'Dubai Electricity Project',
        },
        text: {
          header: {
            title: 'Dubai Electricity and Water Authority',
            // eslint-disable-next-line max-len
            description: 'Built on the Ethereum blockchain, the platform facilitates grid operators, Virtual Power Plant (VPP) operators, and VPP participants to leverage real-time data to track and trace energy wastage.',
            button: {
              type: ButtonTypes.ArrowButton,
              to: '/projects/dewa/',
              name: 'dewa',
              text: 'Learn More',
            },
          },
          blocks: [
          ],
          footer: {
          },
        },
      },
    },
  },
  {
    sectionType: SectionTypes.Sticky,
    blockType: BlockTypes.PageProject,
    index: 62,
    config: {
      path: 'global-id',
      color: '#060c1a',
      backgroundColor: '#FFFFFF',
      minHeight: 200,
      contendEndFixed: true,
    },
    blockData: {
      config: {
        color: '#060c1a',
        backgroundColor: '#FFFFFF',
        isImageLeft: true,
        contentPosition: BlockWrapperTypes.Fixed,
        contentAlignment: AlignmentTypes.CT,
        zIndex: 64,
      },
      content: {
        image: {
          image1: projectGlobalIdImage1,
          image2: projectGlobalIdImage2,
          image3: projectGlobalIdImage3,
          alt: 'Global Id Project',
        },
        text: {
          header: {
            title: 'GlobaliD',
            // eslint-disable-next-line max-len
            description: 'A universal identity solution that is easy to use by tying users to unique names and transcends across all borders and institutions.',
            button: {
              type: ButtonTypes.ArrowButton,
              to: '/projects/globalid/',
              name: 'GlobaliD',
              text: 'Learn More',
              iconColor: '#000',
            },
          },
          blocks: [
          ],
          footer: {
          },
        },
      },
    },
  },
  {
    sectionType: SectionTypes.Sticky,
    blockType: BlockTypes.PageProject,
    index: 60,
    config: {
      path: 'pandora',
      color: '#FFFFFF',
      backgroundColor: '#010101',
      minHeight: 200,
      contendEndFixed: true,
    },
    blockData: {
      config: {
        color: '#FFFFFF',
        backgroundColor: '#010101',
        isImageLeft: true,
        contentPosition: BlockWrapperTypes.Fixed,
        contentAlignment: AlignmentTypes.CT,
        zIndex: 62,
      },
      content: {
        image: {
          image1: projectPandoraImage3,
          image2: projectPandoraImage3,
          image3: projectPandoraImage3,
          alt: 'Pandora Project',
        },
        text: {
          header: {
            title: 'Pandora',
            // eslint-disable-next-line max-len
            description: 'A CDP-based protocol that brings full flexibility and composability to one\'s collateral. People can deposit assets into a Pandora box, borrow pUSD without paying any fees, take their money out whenever they want, swap their assets for different ones easily, and venture into supported yield farms effortlessly, claiming rewards and bridging cross-chain.',
          },
          blocks: [
          ],
          footer: {
          },
        },
      },
    },
  },
  {
    sectionType: SectionTypes.Sticky,
    blockType: BlockTypes.PageProject,
    index: 66,
    config: {
      path: 'uefa',
      color: '#FFFFFF',
      backgroundColor: '#040f4d',
      minHeight: 200,
      contendEndFixed: true,
    },
    blockData: {
      config: {
        color: '#FFFFFF',
        backgroundColor: '#040f4d',
        isImageLeft: true,
        contentPosition: BlockWrapperTypes.Fixed,
        contentAlignment: AlignmentTypes.CT,
        zIndex: 68,
      },
      content: {
        image: {
          image1: projectUefaImage1,
          image2: projectUefaImage2,
          image3: projectUefaImage3,
          alt: 'UEFA Project',
        },
        text: {
          header: {
            title: 'UEFA',
            // eslint-disable-next-line max-len
            description: 'A live voting platform with high load infrastructure which is capable to receive 15K requests per second. The service was developed using Symfony framework and MongoDB - a source-available cross-platform document-oriented database program.',
          },
          blocks: [
          ],
          footer: {
          },
        },
      },
    },
  },
  {
    sectionType: SectionTypes.Sticky,
    blockType: BlockTypes.PageProject,
    index: 70,
    config: {
      path: 'gift-depot',
      color: '#FFFFFF',
      backgroundColor: '#010101',
      minHeight: 200,
      contendEndFixed: false,
    },
    blockData: {
      config: {
        color: '#FFFFFF',
        backgroundColor: '#010101',
        isImageLeft: true,
        contentPosition: BlockWrapperTypes.Fixed,
        contentAlignment: AlignmentTypes.CT,
        zIndex: 72,
      },
      content: {
        image: {
          image1: projectGiftDepotImage3,
          image2: projectGiftDepotImage3,
          image3: projectGiftDepotImage3,
          alt: 'Gift Depot Project',
        },
        text: {
          header: {
            title: 'Gift Depot',
            // eslint-disable-next-line max-len
            description: 'Peer to peer gift card app that provides users with the opportunity to easily upload their gift cards for sale. These cards are showcased in a visually appealing manner, resembling items in an online store, allowing potential buyers to browse and select the ones that pique their interest.',
          },
          blocks: [
          ],
          footer: {
          },
        },
      },
    },
  },
];
