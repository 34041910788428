import React from 'react';
import { Structure } from './Structure';

export function KeyFeatures() {
  const items = [
    {
      title: 'Product Development',
      description: 'After conducting extensive analysis and consultation with stakeholders, our team created a portal utilizing the Ethereum blockchain. The portal is able to log events and transmit information to effectively communicate with the client\'s VPP (Virtual Power Plant).',
    },
    {
      title: 'Product Design',
      description: 'We designed a blockchain platform interface based on business analysis and market research results that can transfer real-time data to ensure a reliable power supply.',
    },
    {
      title: 'Software Development',
      description: 'Implementation of Concerto GraphQL API to read, and write real-time data. Designing the architecture and implementing blockchain integration as well as REST API to feed the frontend portals with data. Development of the web app using React Framework and TypeScript.',
    },
  ];

  return (
    <div className="tw-bg-black tw-py-56">
      <div className="tw-mb-64 max-lg:tw-mb-28 max-sm:tw-mb-24 tw-flex tw-justify-center">
        <div className="tw-max-w-4xl">
          <span className="tw-text-[22px] tw-text-white max-lg:tw-text-[22px] max-sm:tw-text-[20px]">
            A unique blockchain-based platform to promote efficient use of energy.
            {' '}
          </span>
          <span className="tw-text-[22px] tw-text-greenMedium max-lg:tw-text-[22px] max-sm:tw-text-[20px]">
            Built on the Ethereum blockchain
          </span>
          <span className="tw-text-[22px] tw-text-white max-lg:tw-text-[22px] max-sm:tw-text-[20px]">
            , the platform facilitates grid operators, Virtual Power Plant (VPP) operators, and VPP participants to leverage real-time data to track and trace energy wastage and incentivize them with DigiWatt (DG) tokens to foster engagement and increased ROI.
            {' '}
          </span>
        </div>
      </div>
      <Structure items={items} />
    </div>
  );
}
