import React from 'react';
import { IconSocialFacebook, IconSocialInstagram, IconSocialLinkedIn } from '../components/shared/icons';
import { IconSocialClutch } from '../components/shared/icons/socials/IconSocialClutch';

export const socialIcons = [
  {
    path: 'https://www.facebook.com/groksmith',
    name: 'facebook',
    icon: <IconSocialFacebook colorFill="black" />,
  },
  {
    path: 'https://www.instagram.com/groksmith.co',
    name: 'instagram',
    icon: <IconSocialInstagram />,
  },
  {
    path: 'https://www.linkedin.com/company/groksmith/',
    name: 'linked in',
    icon: <IconSocialLinkedIn />,
  },
  {
    path: 'https://clutch.co/profile/groksmith#summary',
    name: 'clutch',
    icon: <IconSocialClutch />,
  },
];
