import React from 'react';
import { KeyFeature } from './KeyFeature';
import { KeyFeature2 } from './KeyFeature2';

import estateIcon from '../../../../../assets/images/projects/flote/icons/estate.svg';
import templateIcon from '../../../../../assets/images/projects/flote/icons/template.svg';
import reportIcon from '../../../../../assets/images/projects/flote/icons/report.svg';
import documentIcon from '../../../../../assets/images/projects/flote/icons/document.svg';
import securityIcon from '../../../../../assets/images/projects/flote/icons/security.svg';
import oneBinIcon from '../../../../../assets/images/projects/flote/icons/1bln.svg';
import fortyIcon from '../../../../../assets/images/projects/flote/icons/40.svg';
import eightIcon from '../../../../../assets/images/projects/flote/icons/8.svg';

import frame1 from '../../../../../assets/images/projects/flote/frame1.png';
import frame2 from '../../../../../assets/images/projects/flote/frame2.png';
import frame3 from '../../../../../assets/images/projects/flote/frame3.png';
import frame4 from '../../../../../assets/images/projects/flote/frame4.png';
import frame5 from '../../../../../assets/images/projects/flote/frame5.png';
import frame6 from '../../../../../assets/images/projects/flote/frame6.png';

const keyFeatures1 = [
  {
    icon: estateIcon,
    description: 'Capital Raising functional for making investments in Commercial Real Estate',
  },
  {
    icon: templateIcon,
    description: 'Pre-formatted and customizable notification templates',
  },
  {
    icon: reportIcon,
    description: 'Exportable daily and weekly reports',
  },
  {
    icon: documentIcon,
    description: 'Customizable offering pages with document vaults',
  },
  {
    icon: securityIcon,
    description: 'Enhanced security through the ability to limit who views the offerings',
  },
];

const keyFeatures2 = [
  {
    icon: oneBinIcon,
    description: 'assets under management',
  },
  {
    icon: fortyIcon,
    description: 'properties under the management',
  },
  {
    icon: eightIcon,
    description: 'sponsor companies',
  },
];

export function KeyFeatures() {
  return (
    <>
      <div className="tw-flex tw-flex-wrap tw-justify-center tw-gap-28 tw-mt-10 tw-h-full">
        {keyFeatures1?.map((feature) => <KeyFeature feature={feature} />)}
      </div>
      <div className="tw-flex tw-flex-wrap tw-justify-center tw-gap-32 tw-h-full tw-mt-44">
        {keyFeatures2?.map((feature) => <KeyFeature2 feature={feature} />)}
      </div>
      <div className="tw-relative tw-flex tw-gap-7 tw-flex-wrap tw-justify-center tw-mt-64 tw-mb-80 max-sm:tw-mb-64">
        <div className="tw-flex tw-w-max tw-gap-5 tw-rotate-[-15deg] lg:[&>*]:tw-h-[400px] max-lg:[&>*]:tw-h-[300px] max-sm:[&>*]:tw-h-[200px]">
          <img src={frame1} alt="frame 1" />
          <img src={frame2} alt="frame 2" />
          <img src={frame3} alt="frame 3" />
        </div>
        <div className="tw-flex tw-w-max tw-gap-5 tw-rotate-[-15deg] lg:[&>*]:tw-h-[400px] max-lg:[&>*]:tw-h-[300px] max-sm:[&>*]:tw-h-[200px]">
          <img src={frame4} alt="frame 4" />
          <img src={frame5} alt="frame 5" />
          <img src={frame6} alt="frame 6" />
        </div>
      </div>
      <div className="tw-mb-32 max-lg:tw-mb-28 max-sm:tw-mb-24 tw-flex tw-justify-center">
        <div className="tw-max-w-3xl">
          <span className="tw-text-[24px] tw-text-black max-lg:tw-text-[22px] max-sm:tw-text-[20px]">
            Providing Flote with smooth, efficient, virtualized experience has demonstrated
          </span>
          <span className="tw-text-[24px] tw-text-violet max-lg:tw-text-[22px] max-sm:tw-text-[20px]">
            {' success in real estate market.'}
          </span>
        </div>
      </div>
    </>
  );
}
