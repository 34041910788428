import React from 'react';
import heliosImage from '../../../../../assets/images/projects/helios/cover.svg';
import { useStoreConfigAppDevice } from '../../../../../dao/store/states/ConfigState';

export function Cover() {
  const { deviceState } = useStoreConfigAppDevice();

  return (
    <div className={`tw-h-full tw-flex ${deviceState.deviceScreen.horizontal ? '' : 'tw-flex-col-reverse tw-gap-10 '} tw-gap-20 tw-justify-center tw-items-center`}>
      <h2
        style={{
          background: 'linear-gradient(112deg, #EC17D7 8.73%, #344FDA 84.89%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
        className="tw-max-w-[500px] tw-text-left tw-text-[3rem] tw-leading-[4rem]"
      >
        The evolution of solar, powered by crypto
      </h2>
      <img src={heliosImage} className="tw-h-fit tw-max-w-full" alt="groksmith project helios" />
    </div>
  );
}
