import { useContext } from 'react';
import { SectionStateContext } from './State';

export const useStoreSectionState = () => {
  const { sectionState, dispatchSectionState } = useContext(SectionStateContext);

  return {
    sectionState,
    dispatchSectionState,
  };
};
