import React from 'react';
import logo from '../../../../../assets/images/projects/globalid/logo.svg';

export function Logo() {
  return (
    <div className="tw-h-full tw-flex tw-justify-center tw-items-center tw-px-16">
      <img className="tw-max-w-full" src={logo} alt="logo" />
    </div>
  );
}
