import React from 'react';

export function KeyFeature({ feature: { icon, description, prefix } }) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-6 tw-items-center">
      {prefix && <p className="tw-max-w-[300px]">{prefix}</p>}
      {icon && <img height={40} width={40} src={icon} className="tw-w-full tw-max-w-[956px]" alt="Helios 1" />}
      {description && <p className="tw-max-w-[300px]">{description}</p>}
    </div>
  );
}
