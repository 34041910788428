import React, { createContext, useReducer } from 'react';
import { appConfigInitialState } from './initialState';
import { appConfigStateReducer } from './reducer';

export const AppConfigContext = createContext(appConfigInitialState);

export function AppConfigStateProvider({ children }) {
  const [configState, dispatchConfigState] = useReducer(appConfigStateReducer, appConfigInitialState, undefined);

  const value = React.useMemo(() => ({
    configState, dispatchConfigState,
  }), [configState]);

  return (
    <AppConfigContext.Provider
      value={value}
    >
      {
        children
      }
    </AppConfigContext.Provider>
  );
}
