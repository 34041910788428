import { PageTypes } from '../constants/ui';
import { pageAgency } from './pageAgency';
import { pageHome } from './pageHome';
import { pageReferralProgram } from './pageReferralProgram';
import { routeTypeList } from '../modules/init/AppRoutes/components';
import { pageCareers } from './pageCareers';
import { pageTeam } from './pageTeam';
import { pageIndustries } from './pageIndustries';
import {
  pageHelios, pageFlote, pageGlobaliD, pageDewa,
} from './projects';

export const pageList = [
  {
    pageType: PageTypes.SectionPage,
    routeType: routeTypeList.PUBLIC,
    pathName: '/',
    isInMenu: true,
    isInFooter: true,
    isSub: false,
    isIndex: true,
    pageData: pageHome,
    isOnlyRoute: false,
  },
  {
    pathName: '/team',
    pageType: PageTypes.SectionPage,
    routeType: routeTypeList.PUBLIC,
    isInMenu: true,
    isInFooter: true,
    isSub: false,
    isIndex: true,
    pageData: pageTeam,
    isOnlyRoute: false,
  },
  {
    pageType: PageTypes.SectionPage,
    routeType: routeTypeList.PUBLIC,
    pathName: 'home/',
    isInMenu: true,
    isInFooter: true,
    isSub: false,
    isIndex: false,
    pageData: pageHome,
    isOnlyRoute: false,
  },
  {
    pageType: PageTypes.SectionPage,
    routeType: routeTypeList.PUBLIC,
    pathName: 'referral-program/',
    isInMenu: true,
    isInFooter: true,
    isSub: false,
    isIndex: false,
    pageData: pageReferralProgram,
    isOnlyRoute: false,
  },
  {
    pathName: 'projects/',
    isOnlyRoute: true,
    pages: [
      {
        pageType: PageTypes.SectionPage,
        routeType: routeTypeList.PUBLIC,
        pathName: 'helios',
        isInMenu: false,
        isInFooter: false,
        isSub: false,
        isIndex: false,
        pageData: pageHelios,
        isOnlyRoute: false,
      },
      {
        pageType: PageTypes.SectionPage,
        routeType: routeTypeList.PUBLIC,
        pathName: 'flote',
        isInMenu: false,
        isInFooter: false,
        isSub: false,
        isIndex: false,
        pageData: pageFlote,
        isOnlyRoute: false,
      },
      {
        pageType: PageTypes.SectionPage,
        routeType: routeTypeList.PUBLIC,
        pathName: 'globalid',
        isInMenu: false,
        isInFooter: false,
        isSub: false,
        isIndex: false,
        pageData: pageGlobaliD,
        isOnlyRoute: false,
      },
      {
        pageType: PageTypes.SectionPage,
        routeType: routeTypeList.PUBLIC,
        pathName: 'dewa',
        isInMenu: false,
        isInFooter: false,
        isSub: false,
        isIndex: false,
        pageData: pageDewa,
        isOnlyRoute: false,
      },
    ],
  },
  {
    pathName: 'whatwedo/',
    isOnlyRoute: true,
    pages: [
      {
        pageType: PageTypes.SectionPage,
        routeType: routeTypeList.PUBLIC,
        pathName: 'services/',
        isInMenu: true,
        isInFooter: true,
        isSub: false,
        isIndex: false,
        pageData: pageAgency,
        isOnlyRoute: false,
      },
      {
        pageType: PageTypes.SectionPage,
        routeType: routeTypeList.PUBLIC,
        pathName: 'industries',
        isInMenu: true,
        isInFooter: true,
        isSub: false,
        isIndex: false,
        pageData: pageIndustries,
        isOnlyRoute: false,
      },
    ],
  },
  {
    pathName: 'about/',
    isOnlyRoute: true,
    pages: [
      {
        pageType: PageTypes.SectionPage,
        routeType: routeTypeList.PUBLIC,
        pathName: '',
        isInMenu: true,
        isInFooter: true,
        isSub: false,
        isIndex: true,
        pageData: pageCareers,
        isOnlyRoute: false,
      },
      {
        pageType: PageTypes.SectionPage,
        routeType: routeTypeList.PUBLIC,
        pathName: 'careers/',
        isInMenu: true,
        isInFooter: true,
        isSub: false,
        isIndex: false,
        pageData: pageCareers,
        isOnlyRoute: false,
      },
    ],
  },
];
