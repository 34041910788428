import React from 'react';

import {
  IconLogotypeCanada,
  IconLogotypeEthereum,
  IconLogotypeGovUA,
  IconLogotypePWC,
  IconLogotypeRipple,
  IconLogotypeStarkNet,
  IconLogotypeUEFA,
  IconLogotypeUNICEF,
} from '../components/shared/icons';

export const clientListSVG = [
  {
    name: 'UEFA',
    path: 'https://www.uefa.com',
    alt: 'uefa logotype',
    component: <IconLogotypeUEFA />,
  },
  {
    name: 'Ripple',
    path: 'https://ripple.com',
    alt: 'ripple logotype',
    component: <IconLogotypeRipple />,
  },
  {
    name: 'PWC',
    path: 'https://www.pwc.com',
    alt: 'pwc logotype',
    component: <IconLogotypePWC />,
  },
  {
    name: 'Ethereum',
    path: 'https://ethereum.org',
    alt: 'ethereum logotype',
    component: <IconLogotypeEthereum />,
  },
  {
    name: 'Starknet',
    path: 'https://starkware.co',
    alt: 'starknet logotype',
    component: <IconLogotypeStarkNet />,
  },
  {
    name: 'Canada',
    path: 'https://www.canada.ca',
    alt: 'Canada logotype',
    component: <IconLogotypeCanada />,
  },
  {
    name: 'Government of Dubai',
    path: 'https://www.dewa.gov.ae',
    alt: 'Government of Dubai logotype',
    component: <IconLogotypeGovUA />,
  },
  {
    name: 'UNICEF',
    path: 'https://www.unicef.org',
    alt: 'UNICEF logotype',
    component: <IconLogotypeUNICEF />,
  },
];
