import { useEffect, useState } from 'react';
import {
  useParams,
} from 'react-router-dom';
import axios from 'axios';
import { API_URL } from '../../constants/api';

export const useTeamMemberHook = () => {
  const { memberId } = useParams();
  const [teamMemberInfo, setTeamMemberIno] = useState({
    name: '',
    position: '',
    logo: '',
    description: '',
    projects: [],
    hobbies: {
      photo: null,
      name: '',
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [memberSkills, setMemberSkills] = useState({
    keys: [],
    skills: {},
  });
  const [memberSocialLinks, setMemberSocialLinks] = useState({
    github: undefined,
    facebook: undefined,
    instagram: undefined,
    linkedin: undefined,
    spotify: undefined,
    other_contact: undefined,
  });

  const constructSkillsData = (skills) => {
    const skillsKeys = Object.keys(skills);
    setMemberSkills((prevState) => ({ ...prevState, keys: skillsKeys, skills: { ...skills } }));
  };

  const constructMemberInfo = (data) => {
    setTeamMemberIno(((prevState) => ({
      ...prevState, name: data.name, position: data.position, logo: data.logo, description: data.description, projects: data.projects, hobbies: { photo: data.hobbies_photo, name: data.hobbies },
    })));
  };

  const constructMemberSocialLinks = (data) => {
    setMemberSocialLinks(((prevState) => ({
      ...prevState, other_contact: data.other_contact, github: data.github, facebook: data.facebook, instagram: data.instagram, linkedin: data.linkedin, spotify: data.spotify,
    })));
  };

  const handleGetTeamMemberById = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${API_URL}teams/${memberId}/`);
      constructMemberInfo(res.data);
      constructMemberSocialLinks(res.data);
      constructSkillsData(res.data.skills);
    } catch (e) {
      setIsError(true);
      await Promise.reject(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (memberId) {
      (async () => handleGetTeamMemberById())();
    }
  }, [memberId]);

  return {
    teamMemberInfo,
    isLoading,
    isError,
    memberSkills,
    memberSocialLinks,
  };
};
