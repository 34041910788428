import React from 'react';
import { Field } from 'formik';

function CustomInputComponent({ hasError = false, rest }) {
  return <input className={`${hasError ? 'form-error-input' : 'form-input'}`} type="text" {...rest} />;
}

export function FormInput(props) {
  const {
    type, id, name, placeholder,
  } = props;
  return (
    <Field
      id={id}
      type={type}
      name={name}
    >
      {({ field, meta }) => (
        <div>
          <CustomInputComponent hasError={!!(meta.error && meta.touched)} rest={{ ...field, placeholder }} />
        </div>
      )}
    </Field>
  );
}
