import React from 'react';
import howItWorks from '../../../../../assets/images/projects/helios/howItWorks.png';

export function HowItWorks() {
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full">
      <h2>How it works</h2>
      <img src={howItWorks} className="tw-w-full tw-max-w-[440px]" alt="How it works" />
    </div>
  );
}
