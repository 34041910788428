import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { LayoutPrimary, LayoutSecondary } from '../../layout';
import BaseSelect from '../../../components/shared/Select/BaseSelect';
import { API_URL, DISCOVERED_FROM, RequestStatusTypes } from '../../../constants/api';
import { FormInput } from '../../../components/shared/inputs/FormInput';
import { FormTextArea } from '../../../components/shared/inputs/FormTextarea';
import ThanksIcon from '../../../assets/images/backgrounds/thanksIcon-1x.png';
import { AppLayoutTypes } from '../../../constants/ui';

const ContactFormSchema = Yup.object()
  .shape({
    first_name: Yup.string()
      .required('Required field.'),
    last_name: Yup.string()
      .required('Required field.'),
    email: Yup.string()
      .email('Provide valid email address. ')
      .required('Required field.'),
    telegram: Yup.string(),
    discovered_from: Yup.string()
      .required('Required field.'),
    company: Yup.string(),
    text: Yup.string()
      .required('Required field.'),
  });

const selectOptions = [
  {
    id: 1,
    name: 'Search engine',
    value: DISCOVERED_FROM.search_engine,
  },
  {
    id: 2,
    name: 'Social media',
    value: DISCOVERED_FROM.social_media,
  },
  {
    id: 3,
    name: 'Recommendation',
    value: DISCOVERED_FROM.recommendation,
  },
  {
    id: 4,
    name: 'Goodfirms',
    value: DISCOVERED_FROM.goodfirms,
  },
  {
    id: 5,
    name: 'Other',
    value: DISCOVERED_FROM.other,
  },
];

export function ContactPage() {
  const [isRender, setIsRender] = useState(false);
  const [requestStatus, setRequestStatus] = useState(RequestStatusTypes.Waiting);
  useEffect(() => {
    setIsRender(true);
  }, []);

  const handleSubmit = async (values) => {
    const data = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      discovered_from: values.discovered_from,
      text: values.text,
    };
    if (values.telegram.length) {
      data.telegram = values.telegram;
    }
    if (values.company.length) {
      data.company = values.company;
    }
    try {
      setRequestStatus(RequestStatusTypes.Loading);
      await axios.post(`${API_URL}contact_us/`, { ...data });
      setRequestStatus(RequestStatusTypes.Success);
    } catch (e) {
      setRequestStatus(RequestStatusTypes.Error);
      await Promise.reject(e);
    }
  };

  if (requestStatus === RequestStatusTypes.Success) {
    return (
      <LayoutSecondary layoutType={AppLayoutTypes.Secondary} isRender={isRender}>
        <div className="container">
          <img
            className="icon"
            src={ThanksIcon}
            alt="error-page-icon"
          />
          <h2>Thank you for contacting us!</h2>
          <p>We have received your message.</p>
          <button
            type="button"
            className="button-email"
            aria-label="Go to Homepage"
          >
            <Link to="/">Back to Homepage</Link>
          </button>
        </div>
      </LayoutSecondary>
    );
  }
  return (
    <LayoutPrimary isRender={isRender}>
      <div className="page-wrapper">
        <h1 className="page-title">Get in touch</h1>
        <p className="page-subtitle">Fill in the form below and we will get back to you within 2 business days.</p>
        <div className="form-container">
          <Formik
            initialValues={{
              first_name: '',
              last_name: '',
              email: '',
              telegram: '',
              discovered_from: '',
              company: '',
              text: '',
            }}
            onSubmit={handleSubmit}
            validationSchema={ContactFormSchema}
            validateOnBlur={false}
          >
            {(formProps) => (
              <Form>
                <FormInput
                  id="first_name"
                  name="first_name"
                  placeholder="Name"
                  type="text"
                />
                <FormInput
                  id="last_name"
                  name="last_name"
                  placeholder="Surname"
                  type="text"
                />
                <FormInput
                  id="email"
                  name="email"
                  placeholder="Email"
                  type="email"
                />
                <FormInput
                  id="telegram"
                  name="telegram"
                  placeholder="Telegram (optional)"
                  type="text"
                />
                <FormInput
                  id="company"
                  name="company"
                  placeholder="Company (optional)"
                  type="text"
                />
                <BaseSelect
                  onSelect={(value) => formProps.setFieldValue('discovered_from', value)}
                  hasError={!!(formProps.errors.discovered_from && formProps.touched.discovered_from)}
                  defaultText="How did you hear about us?"
                  optionsList={selectOptions}
                />
                <FormTextArea
                  id="text"
                  name="text"
                  placeholder="Case description"
                  type="text"
                />
                <div className="contact-btn-wrapper">
                  <button
                    type="submit"
                    className="contact-button"
                  >
                    {requestStatus === RequestStatusTypes.Loading ? 'Loading...' : 'Contact'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          {
                  requestStatus === RequestStatusTypes.Error
                  && <div><p className="request-error">Something gone wrong.Please try again later</p></div>
                }
        </div>
        <p className="bottom-subtitle">
          By clicking the button,
          I agree with the collection and processing of my personal data as described in the
          <Link to="/privacy-policy"> Privacy Policy.</Link>
        </p>
      </div>
    </LayoutPrimary>
  );
}
