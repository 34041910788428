import React from 'react';
import {
  Route, Routes,
} from 'react-router-dom';

import { CustomRoute, routeTypeList } from './components';

import {
  PageContainer,
} from '../../pages';

import { useStoreConfigAppConfig } from '../../../dao/store/states/ConfigState/hoox';
import { pageList } from '../../../fakeApi/pages';
import { PageTypes } from '../../../constants/ui';
import { ContactPage } from '../../pages/views/Contact';
import { PrivacyPolicy } from '../../pages/views/PrivacyPolicy';
import { TeamMemberSingle } from '../../pages/views/Team/TeamMemberSingle';

export function AppRoutes() {
  const { configState } = useStoreConfigAppConfig();
  return (
    <>
      {
        configState.isReady && (
          <Routes>
            {
              pageList.map((item, key) => (
                item.isOnlyRoute
                  ? (
                    <Route
                      path={item.pathName}
                      key={`page-${key}`}
                    >
                      {
                        item.pages.map((subPage, subKey) => (
                          <Route
                            key={`sub-page-${subKey}`}
                            path={subPage.pathName}
                            element={(
                              <CustomRoute
                                type={subPage.routeType}
                                element={(
                                  <PageContainer
                                    path={`${item.pathName}/${subPage.pathName}`}
                                    pageType={subPage.pageType}
                                    pageData={subPage.pageData}
                                  />
                                )}
                              />
                            )}
                            index={item.isIndex}
                          />
                        ))
                      }
                      <Route
                        path="*"
                        element={(
                          <CustomRoute
                            type={routeTypeList.PUBLIC}
                            element={(
                              <PageContainer
                                pageType={PageTypes.ErrorPage}
                              />
                            )}
                          />
                        )}
                      />
                    </Route>
                  )
                  : (
                    <Route
                      key={`page-${key}`}
                      path={item.pathName}
                      element={(
                        <CustomRoute
                          type={item.routeType}
                          element={(
                            <PageContainer
                              path={item.pathName}
                              pageType={item.pageType}
                              pageData={item.pageData}
                            />
                          )}
                        />
                      )}
                    />
                  )

              ))
            }
            <Route
              exact
              path="/contact"
              element={<ContactPage />}
            />
            <Route
              exact
              path="/team/:memberId"
              element={<TeamMemberSingle />}
            />
            <Route
              exact
              path="/privacy-policy"
              element={<PrivacyPolicy />}
            />
            <Route
              path="*"
              element={(
                <CustomRoute
                  type={routeTypeList.PUBLIC}
                  element={(
                    <PageContainer
                      pageType={PageTypes.ErrorPage}
                    />
                  )}
                />
              )}
            />
          </Routes>
        )
      }
    </>
  );
}
