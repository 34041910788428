import React from 'react';

import {
  AlignmentTypes,
  BlockTitleTypes,
  BlockTypes,
  BlockWrapperTypes,
  SectionTypes,
} from '../../constants/ui';
import {
  GlobaliDLogo,
  GlobaliDFeatures,
} from '../../modules/pages/views/Projects/GlobaliD';

import globalidImage from '../../assets/images/projects/globalid/cover.jpg';
import { UsageInfo } from '../../modules/pages/views/Projects/GlobaliD/UsageInfo';

const sectionCover = {
  sectionType: SectionTypes.Sticky,
  blockType: BlockTypes.PageCover,
  index: 28,
  config: {
    color: '#FFFFFF',
    contentPosition: BlockWrapperTypes.Fixed,
    contentAlignment: AlignmentTypes.CC,
    contendEndFixed: true,
    minHeight: 100,
    zIndex: 32,
  },
  blockData: {
    config: {
      color: '#000000',
      zIndex: 32,
      specialColor: '#0D51FF',
      contentAlignment: AlignmentTypes.LC,
      minHeight: 100,
    },
    content: {
      image: globalidImage,
      blocks: [
        {
          textType: BlockTitleTypes.Colored,
          text: 'A solution that protects ',
        },
        {
          textType: BlockTitleTypes.Static,
          text: 'one’s digital identity worldwide',
        },
      ],
    },
  },
};

const sectionLogo = {
  sectionType: SectionTypes.Sticky,
  blockType: BlockTypes.PageTitle,
  index: 32,
  config: {
    color: '#FFFFFF',
    contentPosition: BlockWrapperTypes.Fixed,
    minHeight: 100,
    contendEndFixed: true,
    zIndex: 34,
    backgroundColor: '#fff',
  },
  blockData: {
    config: {
      color: '#000000',
      zIndex: 30,
      contentPosition: BlockWrapperTypes.Fixed,
    },
    content: {
      component: (props) => <GlobaliDLogo {...props} />,
    },
  },
};

const sectionTitle = {
  sectionType: SectionTypes.Sticky,
  index: 34,
  config: {
    color: '#000000',
    backgroundColor: '#F7F8FA',
    minHeight: 200,
    contendEndFixed: true,
  },
  blockType: BlockTypes.PageTitle,
  blockData: {
    config: {
      contentPosition: BlockWrapperTypes.Fixed,
      color: '#000000',
      specialColor: '#0D51FF',
      backgroundColor: '#F7F8FA',
      zIndex: 36,
      showContactBtn: false,
    },
    content: {
      blocks: [
        {
          textType: BlockTitleTypes.Colored,
          text: 'GlobalID brings decentralized digital identity to all people ',
        },
        {
          textType: BlockTitleTypes.Static,
          text: '— a self-sovereign identity they can use to communicate, transact, and create value within any context through "zero-knowledge proofs" and a secure platform.',
        },
      ],
    },
  },
};

const sectionStructure = {
  sectionType: SectionTypes.Static,
  blockType: BlockTypes.PageText,
  index: 28,
  config: {
    color: '#FFFFFF',
    contentAlignment: AlignmentTypes.CC,
    minHeight: 100,
    zIndex: 32,
    backgroundColor: '#fff',
  },
  blockData: {
    config: {
      color: '#000000',
      zIndex: 32,
      contentAlignment: AlignmentTypes.CC,
      mobile: {
        contentAlignment: AlignmentTypes.CC,
      },
    },
    content: {
      component: (props) => <UsageInfo {...props} />,
    },
  },
};

const sectionImages = {
  sectionType: SectionTypes.Static,
  blockType: BlockTypes.PageText,
  config: {
    minHeight: 200,
    overflow: 'hidden',
    backgroundColor: '#fff',
    background: 'linear-gradient(180deg, rgba(13, 110, 255, 0), rgba(46, 101, 227, 0.12), rgba(25, 22, 158, 0.16), rgba(13, 81, 255, 0.04))',
  },
  blockData: {
    config: {},
    content: {
      header: {},
      component: (props) => <GlobaliDFeatures {...props} />,
    },
  },
};

export const pageGlobaliD = {
  config: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
  },
  sections: [
    sectionCover,
    sectionLogo,
    sectionTitle,
    sectionStructure,
    sectionImages,
  ],
  seo: {
    title: 'GlobalID - Digital Identity for All\n',
    description: 'GlobalID brings decentralized digital identity to all people — a self-sovereign identity they can use to communicate, transact, and create value within any context. Our focus was primarily on Messaging, Payments, Groups, Mobile App, Web App, Infrastructure, Identity, Design systems, and Labs.',
    name: 'GlobalID',
  },
};
