import React from 'react';
import helios1 from '../../../../../assets/images/projects/helios/helios1.png';
import helios2 from '../../../../../assets/images/projects/helios/helios2.png';
import helios3 from '../../../../../assets/images/projects/helios/helios3.png';

export function Images() {
  return (
    <div className="tw-flex tw-flex-col tw-gap-20 tw-items-center tw-justify-between tw-h-full">
      <img src={helios1} className="tw-w-full tw-max-w-[956px]" alt="Helios 1" />
      <img src={helios2} className="tw-w-full tw-max-w-[1200px]" alt="Helios 2" />
      <img src={helios3} className="tw-w-full tw-max-w-[1024px]" alt="Helios 3" />
    </div>
  );
}
