export const StoreActionSectionState = {
  SetSectionMinHeight: 'SetSectionMinHeight',
  SetSectionTop: 'SetSectionTop',
  SetSectionCurrentHeight: 'SetSectionCurrentHeight',
  SetContentCurrentHeight: 'SetContentCurrentHeight',
  SetContentTop: 'SetContentTop',
  SetPercent: 'SetPercent',
  SetContainerParams: 'SetContainerParams',
  SetParentMinHeight: 'SetParentMinHeight',
  ReRender: 'ReRender',
};
