import React, { useEffect, useRef, useState } from 'react';
import { BlockImage } from '../BlockImage';
import { AlignmentTypes, ImageContainTypes } from '../../../../constants/ui';
import { useStoreSectionState } from '../../../../dao/store/states/SectionState/hoox';
import { useStoreConfigAppDevice } from '../../../../dao/store/states/ConfigState';
import { ButtonContainer } from '../../../../components/shared/buttons/ButtonContainer';
import { StoreActionSectionState } from '../../../../dao/store/states/SectionState/actions';

function ImageContainer(props) {
  const { deviceState } = useStoreConfigAppDevice();
  const { sectionState } = useStoreSectionState();

  const calculateContainerSize = (isHorizontal, deviceWidth, deviceHeight) => ({
    width: isHorizontal
      ? (deviceWidth / 2)
      : (4 * deviceWidth) / 5,
    // eslint-disable-next-line no-nested-ternary
    height: isHorizontal
      ? sectionState.sectionCurrentHeight > deviceHeight
        ? deviceHeight / 1.5
        : deviceHeight / 1.5
      : deviceHeight / 2,
  });

  const [containerSizes, setContainerSizes] = useState(
    {
      width: 1,
      height: 1,
    },
  );

  useEffect(() => {
    setContainerSizes(calculateContainerSize(
      deviceState.deviceScreen.horizontal,
      deviceState.deviceScreen.browserWidth,
      deviceState.deviceScreen.browserHeight,
    ));
  }, [
    deviceState.deviceScreen.horizontal,
    deviceState.deviceScreen.width,
    deviceState.deviceScreen.browserWidth,
    deviceState.deviceScreen.browserHeight,
    deviceState.deviceScreen.pixelRatio,
  ]);

  return (
    <div
      className="image-container"
      style={{
        width: `${containerSizes.width}px`,
        height: `${containerSizes.height}px`,
        maxHeight: `${deviceState.deviceScreen.browserHeight}px`,
      }}
    >
      <BlockImage
        {...props}
        contain={ImageContainTypes.Contain}
        alignment={
          deviceState.deviceScreen.horizontal
            ? AlignmentTypes.CC
            : AlignmentTypes.CC
        }
      />
    </div>
  );
}

function PageContainer(props) {
  const {
    header,
    blocks,
    projects,
    footer,
    config,
    styles,
  } = props;

  return (
    <div className="text-container">
      <div className="text-wrapper" style={styles && styles.textWrapper ? { ...styles.textWrapper } : {}}>
        {
          header
          && (
            <div
              className="content-header"
            >
              {
                header?.title
                && <h2 style={styles && styles.header ? { ...styles.header } : {}}>{header.title}</h2>
              }
              {
                header?.description
                && <p style={styles && styles.description ? { ...styles.description } : {}}>{header.description}</p>
              }
              {
                header?.button
                && <ButtonContainer {...header.button} />
              }
            </div>
          )
        }
        {
          projects && projects.length > 0
          && (
            <div className="tw-mt-8 tw-px-2">
              <p className="tw-mb-4 tw-font-semibold tw-text-xxs">Recent projects</p>
              <div className="tw-container tw-mx-auto">
                <div className="tw-flex tw-flex-wrap tw-justify-start lg:tw-justify-start tw-gap-4">
                  {
                    projects.map((item, key) => (
                      <div key={key} className="tw-max-h-14 tw-bg-white tw-flex tw-w-[7rem] tw-max-w-[7rem] tw-h-14 tw-rounded-lg tw-items-center tw-justify-center  tw-shadow-[1px_1px_24px_0px_#250D5814] tw-w-full sm:tw-w-1/2 md:tw-w-1/3 lg:tw-w-1/5 ">
                        {
                          item.link
                            ? (
                              <a className="tw-max-h-14 tw-rounded-lg tw-flex tw-items-center tw-justify-center" href={item.link} target="_blank" rel="noreferrer">
                                <img className="tw-max-h-14 tw-rounded-lg tw-max-w-[6.5rem]" src={item.logo} alt="dawd" />
                              </a>
                            )
                            : <img className="tw-max-h-14 tw-rounded-lg tw-max-w-[6.5rem]" src={item.logo} alt="dawd" />
                        }
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          )
        }
        {
          !!blocks?.length
          && (
            <div
              className={`content-blocks ${config.contentBlockType ? config.contentBlockType : ''}`}
            >
              {
                blocks.map((item, key) => (
                  <div
                    className="content-block-item"
                    key={`block-text-image-${key}`}
                  >
                    {
                      item.icon
                      && <img src={item.icon} alt={`block-${key}-${item.title}`} />
                    }
                    {
                      item.title
                      && <h5>{item.title}</h5>
                    }
                    {
                      item.description
                      && <p>{item.description}</p>
                    }
                  </div>
                ))
              }
            </div>
          )
        }
        {
          footer
          && (
            <div
              className="content-footer"
            >
              {
                footer?.title
                && <h1>{footer.title}</h1>
              }
              {
                footer?.description
                && <p>{footer.description}</p>
              }
            </div>
          )
        }
      </div>
    </div>
  );
}

export function BlockPageTextImage(props) {
  const {
    blockData,
    sectionConfig,
  } = props;

  const { deviceState } = useStoreConfigAppDevice();
  const [blockTop, setBlockTop] = useState(0);
  const {
    sectionState, dispatchSectionState,
  } = useStoreSectionState();

  const blockItem = useRef(null);

  useEffect(() => {
    if (blockItem?.current?.offsetHeight) {
      switch (sectionConfig.contentAlignment) {
        case AlignmentTypes.CT:
          setBlockTop(
            deviceState.deviceScreen.browserHeight > blockItem?.current?.offsetHeight
              ? (deviceState.deviceScreen.browserHeight - (blockItem?.current?.offsetHeight || 0)) / 2
              : 0,
          );
          break;

        default:
          setBlockTop((sectionState.sectionCurrentHeight - (blockItem?.current?.offsetHeight || 0)) / 2);
      }
    }
  }, [
    sectionState.sectionCurrentHeight,
    blockItem?.current?.offsetHeight,
    deviceState.deviceScreen.browserHeight,
  ]);

  useEffect(() => {
    dispatchSectionState({
      type: StoreActionSectionState.SetContentTop,
      payload: blockTop,
    });
  }, [blockTop]);

  return (
    <div
      className={`${blockData.config.wrapperClassName || 'block-page-text-image'} ${
        deviceState.deviceScreen.horizontal
          ? ''
          : 'block-page-text-image-vertical'
      }`}
      ref={blockItem}
      style={{
        color: blockData.config.color,
        overflow: 'hidden',
        transform: `translateY(${blockTop}px)`,
        direction: `${
          blockData?.config?.isImageLeft || !deviceState.deviceScreen.horizontal ? 'ltr' : 'rtl'
        }`,
      }}
    >
      {
        blockData.content.image
        && <ImageContainer {...blockData.content.image} config={blockData.config} />
      }
      {
        blockData.content.text
        && <PageContainer {...blockData.content.text} config={blockData.config} />
      }
    </div>
  );
}
