import React from 'react';
import logo from '../../../../../assets/images/projects/helios/logo.png';
import logoText from '../../../../../assets/images/projects/helios/text.svg';
import { useStoreConfigAppDevice } from '../../../../../dao/store/states/ConfigState';

export function Logo() {
  const { deviceState } = useStoreConfigAppDevice();

  return (
    <div className={`tw-h-full tw-flex tw-gap-12 tw-justify-center tw-items-center ${deviceState.deviceScreen.horizontal ? '' : 'tw-flex-col'}`}>
      <img src={logo} alt="logo" />
      <img src={logoText} className="tw-h-fit tw-w-full tw-max-w-[340px]" alt="logo text" />
    </div>
  );
}
