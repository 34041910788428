import React from 'react';

export function ListItem({ item: { icon, title, description }, className }) {
  return (
    <div className={`tw-w-full tw-text-start ${className || ''}`}>
      <img src={icon} alt="icon" />
      <h3 className="tw-font-bold tw-text-[20px] m-0 tw-leading-[42px]">{title}</h3>
      <p className="tw-mt-1 tw-text-[20px]">
        {description}
      </p>
    </div>
  );
}
