import React, { useEffect, useState } from 'react';
import { LayoutPrimary } from '../../layout';

function TextContainer({ text, withoutMargin = false }) {
  return (
    <p className="privacy-text" style={withoutMargin ? { marginBottom: 0 } : {}}>{text}</p>
  );
}

function SectionTitle({ title }) {
  if (title) {
    return (
      <h1 className="privacy-section-title">{title}</h1>
    );
  }
  return <></>;
}

function PrivacySection({ title, children }) {
  return (
    <div className="privacy-section-wrapper">
      <SectionTitle title={title} />
      {children}
    </div>
  );
}

export function PrivacyPolicy() {
  const [isRender, setIsRender] = useState(false);
  useEffect(() => {
    setIsRender(true);
  }, []);

  return (
    <LayoutPrimary isRender={isRender}>
      <div className="privacy-page-wrapper">
        <div className="page-container">
          <div className="page-header">
            <h1 className="page-title">Privacy Policy</h1>
            <p className="page-subtitle">Last Updated on November 28, 2022</p>
          </div>
          <PrivacySection>
            <TextContainer
              text={'Groksmith.co is a website owned and operated by Hex Division LLC ("We" or "Us"). We want you to feel secure when visiting our site and are committed to maintaining your information, including any information that is capable of identifying you as an individual person, as you use this site.'}
            />
            <TextContainer
              text="This privacy policy (“Privacy Policy”) explains our approach to any personal information that we might collect from you and the purposes for which we process your personal information. Please take a moment to read and understand it."
            />
            <TextContainer
              text="Please also note that this Privacy Policy only applies to the use of your personal information obtained by us, it does not apply to your personal information collected during your communications with third parties."
            />
          </PrivacySection>
          <PrivacySection title="What information do we collect from the people that visit our website?">
            <TextContainer
              text="It is in our legitimate interest to use your personal information in such a way to ensure that we provide the very best client service we can to you."
            />
          </PrivacySection>
          <PrivacySection title="Personal Information">
            <TextContainer
              text="The submission of any information that identifies you personally is not mandatory unless you commence direct communication with us. In order to process your requests, provide the required services, and personalized experience, we need your name, last name, email address, Telegram username, and company name."
            />
            <TextContainer
              text="Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential."
            />
          </PrivacySection>
          <PrivacySection title="Statistical information">
            <TextContainer
              text="We collect and aggregate non-identifying information such as user journey on our website, advertisements views, pages browsed, the type of browser you are using the URL you have come from, and the time spent at that URL."
            />
            <TextContainer
              text="We use cookies to understand and improve users’ experience in future visits. For this purpose, we use Google Analytics. You can always disable cookies by changing your web browser settings, and this should not affect your use of our website."
            />
          </PrivacySection>
          <PrivacySection title="Why do we need to collect your personal information?">
            <ul>
              <li>
                <TextContainer
                  withoutMargin
                  text="manage and maintain our relationship with you, including responding to an inquiry, question, or comment made by you and providing you with other customer support,"
                />
              </li>
              <li>
                <TextContainer
                  withoutMargin
                  text="allow you to participate in an event organized or promoted by us,"
                />
              </li>
              <li>
                <TextContainer
                  withoutMargin
                  text="carry out analytics and market research, and internal reporting to enable us to plan, develop and improve our sites, services, and marketing activities,"
                />
              </li>
              <li>
                <TextContainer
                  withoutMargin
                  text="inform you of our new products,
                   services, and events that we think you will find valuable by sending promotional messages via email, SMS, phone, post and/or other similar communications means. (If you would prefer not to receive these communications, simply follow the unsubscribe instructions on the promotional communications that you receive.)"
                />
              </li>
            </ul>
          </PrivacySection>
          <PrivacySection title="Third-party disclosure">
            <TextContainer
              text="We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable
              Information unless we provide users with advance notice. This does not include website
              hosting partners and other parties who assist us in operating our website, conducting our business,
               or serving our users, so long as those parties agree to keep this information confidential. We may also release information when it's release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property or safety."
            />
          </PrivacySection>
          <PrivacySection title="Third-party links">
            <TextContainer
              text="We do not include or offer third-party products or services on our website."
            />
          </PrivacySection>
          <PrivacySection title="Children’s privacy protection">
            <TextContainer
              text="The website is not intentionally targeted to, or intended for, children under the age of 18. We do not knowingly collect data relating to children."
            />
          </PrivacySection>
          <PrivacySection title="Controlling your personal information">
            <TextContainer
              text="If you would like to exercise your right to modify or remove your personal information from our database
               or request a copy of your personal information (to the extent these rights are provided by law), you can send us an e-mail at hey@groksmith.co. For your protection, we may need to verify your identity before fulfilling your request."
            />
          </PrivacySection>
          <PrivacySection title="Changes to this Privacy Policy">
            <TextContainer
              text="We may make changes to this Privacy Policy from time to time."
            />
            <TextContainer
              text="To ensure that you are always aware of how we use your personal information, we will update this Privacy Policy from time to time to reflect any changes to our use of your personal information. We may also make changes as required to comply with changes in applicable law or regulatory requirements."
            />
            <TextContainer
              text="We will make available to you here any significant changes to the policy, whether based on a change of law or otherwise. However, we encourage you to review this Privacy Policy periodically to be informed of how we use your personal information."
            />
          </PrivacySection>
        </div>
      </div>
    </LayoutPrimary>
  );
}
