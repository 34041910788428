import React, { useEffect, useState } from 'react';
import { useStoreConfigAppLoader } from '../../../dao/store/hoox';

export function AppLoader() {
  const { loaderState } = useStoreConfigAppLoader();

  const appPreloader = document.getElementById('app-preloader');
  const imagePreloader = document.getElementById('preload-images');
  const [loaderOpacity, setLoaderOpacity] = useState(1);
  const [isRender, setIsRender] = useState(true);

  useEffect(() => {
    if (loaderState.isOpen) {
      setIsRender(true);
      setTimeout(() => {
        if (appPreloader) {
          appPreloader.style.opacity = '1';
        }
        setLoaderOpacity(1);
      }, 20);
    } else {
      setLoaderOpacity(0);
      if (appPreloader) {
        appPreloader.style.opacity = '0';
      }
      setTimeout(() => {
        if (appPreloader?.parentNode) {
          appPreloader.parentNode.removeChild(appPreloader);
        }
        if (imagePreloader?.parentNode) {
          imagePreloader.parentNode.removeChild(imagePreloader);
        }
        setIsRender(false);
      }, 500);
    }
  }, [loaderState.isOpen]);

  if (isRender) {
    if (!appPreloader) {
      return (
        <div
          className="app-loader"
          style={{
            opacity: loaderOpacity,
          }}
        >
          <svg
            width="7.875em"
            height="1.25em"
            viewBox="0 0 126 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="#FFFFFF">
              {/* eslint-disable-next-line max-len */}
              <path d="M6.0266 4.35937C6.61456 4.03536 7.61409 3.88809 9.08399 3.88809C10.5539 3.88809 12.4648 4.00591 14.7578 4.24155H14.9048L15.0224 1.03094L13.8759 0.824758C11.7886 0.441842 9.81895 0.235657 8.02567 0.235657C5.11526 0.235657 3.028 1.03094 1.82268 2.56261C0.617359 4.09427 0 6.59795 0 10.0442C0 13.461 0.587961 16.0236 1.73448 17.6142C2.91041 19.2047 5.05646 20.0295 8.14326 20.0295C9.84834 20.0295 12.112 19.8233 14.8754 19.3815L14.993 19.352V9.33728H9.0252V12.9308H10.8185V16.3476L8.55483 16.4654C6.84974 16.4654 5.73262 16.053 5.20345 15.2283C4.67429 14.3741 4.38031 12.8719 4.38031 10.7511C4.38031 8.6009 4.5273 7.06923 4.79188 6.18558C5.05646 5.27248 5.46804 4.68337 6.0266 4.35937Z" />
              {/* eslint-disable-next-line max-len */}
              <path d="M20.6963 6.92193V5.53754H16.5806V19.6465H20.7257V10.3387L21.2255 10.162C22.3426 9.83799 23.6655 9.51398 25.2236 9.18997L25.3412 9.16052V5.18408L25.1942 5.21354C23.7537 5.47863 22.225 6.06773 20.6963 6.92193Z" />
              {/* eslint-disable-next-line max-len */}
              <path d="M32.8083 5.21356C30.3976 5.21356 28.6338 5.86157 27.5754 7.1576C26.5465 8.45362 26.0173 10.2504 26.0173 12.5773C26.0173 17.4963 28.3104 20 32.8083 20C37.3062 20 39.5992 17.4963 39.5992 12.5773C39.5992 10.2798 39.0701 8.45362 38.0411 7.1576C36.9828 5.89103 35.2483 5.21356 32.8083 5.21356ZM30.8092 9.54346C31.1914 8.9249 31.8381 8.63035 32.8377 8.63035C33.8078 8.63035 34.484 8.9249 34.8661 9.54346C35.2483 10.162 35.4541 11.1929 35.4541 12.5773C35.4541 13.9617 35.2483 14.9926 34.8661 15.6407C34.484 16.2592 33.8372 16.5832 32.8377 16.5832C31.8675 16.5832 31.1914 16.2887 30.8092 15.6407C30.427 14.9926 30.2213 13.9617 30.2213 12.5773C30.2213 11.1929 30.427 10.162 30.8092 9.54346Z" />
              <path d="M37.1886 0.0883789H28.6338V3.4168H37.1886V0.0883789Z" />
              {/* eslint-disable-next-line max-len */}
              <path d="M54.0041 5.56701H49.3592L46.5076 10.81L45.2435 11.0162V0H41.0984V19.676H45.2435V14.4919L46.6252 14.2857L49.5356 19.676H54.2099L50.1824 12.2533L54.0041 5.56701Z" />
              {/* eslint-disable-next-line max-len */}
              <path d="M62.1474 10.9573C60.6187 10.6922 59.6486 10.486 59.2664 10.3682C58.943 10.2504 58.796 10.0442 58.796 9.74963C58.796 9.42563 58.9136 9.21944 59.1488 9.07217C59.3252 8.95435 59.7662 8.83653 60.9421 8.83653C61.8828 8.83653 63.5585 8.9838 65.9398 9.27835L66.0868 9.30781L66.1456 5.97938L65.0872 5.80265C63.1176 5.44919 61.5595 5.27246 60.4129 5.27246C58.7666 5.27246 57.3849 5.65538 56.3266 6.42121C55.2683 7.18704 54.7097 8.33579 54.7097 9.838C54.7097 11.3402 55.0919 12.4006 55.8562 12.9897C56.5912 13.5788 57.7377 13.9912 59.2664 14.2268C60.7363 14.4624 61.677 14.6686 62.0004 14.8159C62.3238 14.9632 62.4708 15.1694 62.4708 15.4639C62.4708 15.7585 62.3238 15.9941 62.0298 16.1414C61.7064 16.3181 61.0891 16.377 60.1777 16.377C59.237 16.377 57.5907 16.2297 55.2095 15.9352L55.0625 15.9057L54.9449 19.2636L55.9738 19.4404C57.7965 19.7644 59.3546 19.9411 60.6187 19.9411C64.5581 19.9411 66.5571 18.38 66.5571 15.3166C66.5571 13.9323 66.2043 12.9013 65.5282 12.2533C64.852 11.6937 63.7349 11.2518 62.1474 10.9573Z" />
              {/* eslint-disable-next-line max-len */}
              <path d="M83.9021 5.21356C82.4615 5.21356 80.8153 5.65539 79.0808 6.48013C78.2282 5.62593 76.9935 5.21356 75.4354 5.21356C74.5241 5.21356 73.4069 5.56702 72.1428 6.24449V5.56702H68.0271V19.676H72.1722V9.27836L72.4368 9.19C73.1424 8.9249 73.8773 8.80708 74.6123 8.80708C75.406 8.80708 75.9058 9.07218 76.141 9.57291C76.3761 10.1326 76.4937 11.0751 76.4937 12.4006V19.6466H80.6389V12.3711C80.6389 11.6642 80.6095 10.6333 80.5213 9.30782L80.8447 9.19C81.7266 8.9249 82.4615 8.80708 83.0495 8.80708C83.8139 8.80708 84.3136 9.07218 84.5488 9.57291C84.8134 10.1031 84.931 10.9868 84.931 12.1355V19.6466H89.0761V12.0177C89.0761 9.66128 88.6939 7.95288 87.9296 6.86305C87.224 5.77321 85.8423 5.21356 83.9021 5.21356Z" />
              <path d="M94.8675 0H90.7224V4.03535H94.8675V0Z" />
              <path d="M94.8675 5.56702H90.7224V19.676H94.8675V5.56702Z" />
              {/* eslint-disable-next-line max-len */}
              <path d="M103.158 16.4065C102.805 16.4065 102.54 16.3476 102.393 16.2003C102.246 16.053 102.129 15.8763 102.129 15.6701C102.099 15.4345 102.099 15.0515 102.099 14.5214V8.9838H105.657V5.56701H102.099V1.4433H97.9544V5.56701H96.2493V8.9838H97.9544V14.6097C97.9544 16.6421 98.2777 18.056 98.9539 18.8218C99.6006 19.5876 100.835 20 102.599 20C103.275 20 104.275 19.8527 105.568 19.5876L105.686 19.5582L105.51 16.3476L103.158 16.4065Z" />
              {/* eslint-disable-next-line max-len */}
              <path d="M114.682 5.21355C113.653 5.21355 112.506 5.5081 111.213 6.12666V0H107.068V19.676H111.213V9.21944L111.507 9.13107C112.242 8.92489 112.977 8.80707 113.712 8.80707C114.535 8.80707 115.064 9.07216 115.328 9.5729C115.593 10.1031 115.74 10.9573 115.74 12.106V19.6465H119.885V12.0177C119.885 9.72018 119.503 8.01178 118.768 6.89249C118.033 5.80265 116.651 5.21355 114.682 5.21355Z" />
              <path d="M126 15.0516H121.59V19.6466H126V15.0516Z" />
            </g>
          </svg>
        </div>
      );
    }
  }

  return null;
}
