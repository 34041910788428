import React from 'react';
import { useStoreConfigAppDevice } from '../../../../../dao/store/states/ConfigState';

export function EventItem(props) {
  const {
    title,
    speaker,
    position,
    date,
    image1x,
    isActive,
  } = props;

  const { deviceState } = useStoreConfigAppDevice();

  return (
    <div
      className={
        `event-item-wrapper ${
          isActive ? 'event-item-wrapper-open' : ''
        } ${
          deviceState.deviceScreen.horizontal
            ? 'item-horizontal' : 'item-vertical'
        }`
      }
    >
      <div
        className="event-item-image"
      >
        <img src={image1x} alt={`${speaker} ${title}`} />
      </div>
      <div
        className="event-item-info"
      >
        <h4>{title}</h4>
        <h4>{speaker}</h4>
        <h6>{position}</h6>
        <p>{date}</p>
      </div>
    </div>
  );
}
