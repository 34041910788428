import React, { useState } from 'react';

import { BlockWrapperFix } from '../BlockWrapperFix';
import { BlockTitleTypes } from '../../../../constants/ui';
import { useEffectOnce } from '../../../../hoox/useEffectOnce';
import { ButtonContact } from '../../../../components/shared/buttons/ButtonContact';

export function BlockPageTitle(props) {
  const {
    blockData,
  } = props;

  const [specialColor, setSpecialColor] = useState('');

  useEffectOnce(() => {
    setSpecialColor(blockData.config.specialColor || '#6b37ff');
  });

  return (
    <BlockWrapperFix
      {...blockData.config}
    >
      <div
        className="block-page-title"
        style={{
          color: blockData.config.color || '#000000',
          backgroundColor: blockData.config.backgroundColor,
        }}
      >
        {
          blockData.content.component && <props.blockData.content.component />
        }
        {
          blockData.content.blocks?.map((item, key) => (
            <span
              style={
                item.textType === BlockTitleTypes.Colored
                  ? { color: specialColor }
                  : {}
              }
              key={`page-title-span-${key}`}
            >
              {
                item.text
              }
            </span>
          ))
        }
      </div>
      {
        blockData.config.showContactBtn
        && (
        <div className="title-section-contact-btn-wrapper">
          <ButtonContact label="Contact" name="Contact" />
        </div>
        )
      }
    </BlockWrapperFix>
  );
}
