import React from 'react';

import {
  AlignmentTypes,
  BlockTitleTypes,
  BlockTypes,
  BlockWrapperTypes,
  SectionTypes,
} from '../../constants/ui';
import {
  DewaLogo,
  DewaFeatures,
  DewaArchitecture,
  UsageInfo,
} from '../../modules/pages/views/Projects/Dewa';

import dewaImage from '../../assets/images/projects/dewa/cover.jpg';

const sectionCover = {
  sectionType: SectionTypes.Sticky,
  blockType: BlockTypes.PageCover,
  index: 28,
  config: {
    color: '#FFFFFF',
    contentPosition: BlockWrapperTypes.Fixed,
    contentAlignment: AlignmentTypes.CC,
    contendEndFixed: true,
    minHeight: 100,
    zIndex: 32,
  },
  blockData: {
    config: {
      color: '#FFFFFF',
      zIndex: 32,
      specialColor: '#33A70D',
      contentAlignment: AlignmentTypes.LC,
      minHeight: 100,
    },
    content: {
      image: dewaImage,
      blocks: [
        {
          textType: BlockTitleTypes.Static,
          text: 'Solar energy',
        },
        {
          textType: BlockTitleTypes.Colored,
          text: ' management and control platform ',
        },
        {
          textType: BlockTitleTypes.Static,
          text: 'on blockchain',
        },
      ],
    },
  },
};

const sectionLogo = {
  sectionType: SectionTypes.Sticky,
  blockType: BlockTypes.PageTitle,
  index: 32,
  config: {
    color: '#FFFFFF',
    contentPosition: BlockWrapperTypes.Fixed,
    minHeight: 100,
    contendEndFixed: true,
    zIndex: 34,
    backgroundColor: '#fff',
  },
  blockData: {
    config: {
      zIndex: 30,
      contentPosition: BlockWrapperTypes.Fixed,
    },
    content: {
      component: (props) => <DewaLogo {...props} />,
    },
  },
};

const sectionImages = {
  sectionType: SectionTypes.Static,
  blockType: BlockTypes.PageText,
  config: {
    overflow: 'hidden',
    backgroundColor: '#000',
  },
  blockData: {
    config: {},
    content: {
      header: {},
      component: (props) => <DewaFeatures {...props} />,
    },
  },
};

const sectionArchitecture = {
  sectionType: SectionTypes.Static,
  blockType: BlockTypes.PageText,
  config: {
    color: '#000000',
    overflow: 'hidden',
  },
  blockData: {
    config: {},
    content: {
      header: {},
      component: (props) => <DewaArchitecture {...props} />,
    },
  },
};

const sectionKeyFeatures = {
  sectionType: SectionTypes.Static,
  blockType: BlockTypes.PageText,
  index: 28,
  config: {
    color: '#FFFFFF',
    contentAlignment: AlignmentTypes.CC,
    zIndex: 32,
    background: 'linear-gradient(180deg, rgba(39, 42, 60, 1), rgba(13, 75, 167, 0.2), rgba(13, 75, 167, 0.04))',
  },

  blockData: {
    config: {
      color: '#fff',
      zIndex: 32,
      contentAlignment: AlignmentTypes.CC,
      mobile: {
        contentAlignment: AlignmentTypes.CC,
      },
    },
    content: {
      component: (props) => <UsageInfo {...props} />,
    },
  },
};
export const pageDewa = {
  config: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
  },
  sections: [
    sectionCover,
    sectionLogo,
    sectionImages,
    sectionArchitecture,
    sectionKeyFeatures,
  ],
  seo: {
    title: 'Dewa\n',
    description: 'Dewa',
    name: 'dewa',
  },
};
