import React from 'react';
import { CustomNavLink } from '../../CustomNavLink';
import { IconArrow } from '../../icons';

export function ButtonWithArrow(props) {
  const {
    text, to, name, label, iconColor,
  } = props;
  return (
    <CustomNavLink
      className="button-with-arrow"
      to={to}
      aria-label={name || label}
    >
      <div>
        {
          text
        }
      </div>
      <IconArrow iconColor={iconColor} />
    </CustomNavLink>
  );
}
