import { StoreActionConfigAppModal } from './actions';

export const appModalStateReducer = (
  state,
  action,
) => {
  switch (action.type) {
    case StoreActionConfigAppModal.AppConfigSetModalOpen:
      return {
        ...state,
        isOpen: action.payload.isOpen || action.payload,
        title: action.payload.title || '',
        message: action.payload.message || '',
        submit: action.payload.submit || null,
        cancel: action.payload.submit || null,
      };

    case StoreActionConfigAppModal.AppConfigSetModalClose:
      return {
        ...state,
        isOpen: false,
        title: '',
        message: '',
        submit: null,
        cancel: null,
      };

    default:
      return state;
  }
};
