import React from 'react';
import { AppWrapper } from './modules/init';

function App() {
  return (
    <AppWrapper />
  );
}

export default App;
