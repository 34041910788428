import React, { useEffect, useRef, useState } from 'react';

import { useStoreConfigAppDevice, useStoreConfigAppHeader } from '../../../../dao/store/states/ConfigState/hoox';
import { CustomNavLink } from '../../../../components/shared/CustomNavLink';
import { StoreActionConfigAppHeader } from '../../../../dao/store/states/ConfigState/AppHeaderState/actions';

export function MobileMenu() {
  const menuObject = useRef(null);
  const { headerState, dispatchHeaderState } = useStoreConfigAppHeader();
  const { deviceState } = useStoreConfigAppDevice();
  const [menuStyles, setMenuStyles] = useState({});

  useEffect(() => {
    if (menuObject && menuObject?.current?.offsetHeight && headerState.mobile.isOpen) {
      if (
        (menuObject?.current?.offsetHeight > deviceState.deviceScreen.browserHeight)
        && headerState.mobile.isOpen
      ) {
        setMenuStyles({
          overflowY: 'scroll',
        });
      } else {
        setMenuStyles({});
      }
    }
  }, [
    headerState.mobile.isOpen,
    deviceState.deviceScreen.browserHeight,
  ]);

  const handleClick = () => {
    dispatchHeaderState({
      type: StoreActionConfigAppHeader.MobileHeaderIsOpen,
      payload: false,
    });
  };

  return (
    <div
      className={`mobile-menu ${headerState.mobile.isOpen ? 'mobile-menu-open' : ''}`}
      style={menuStyles}
    >
      <div
        className={`menu-item-wrapper ${headerState.mobile.isOpen ? 'mobile-item-wrapper-open' : ''}`}
        ref={menuObject}
      >
        <CustomNavLink to="/home/" aria-label="home" onClick={() => handleClick()}>
          Home
        </CustomNavLink>
        <CustomNavLink to="/whatwedo/services/" aria-label="services" onClick={() => handleClick()}>
          Services
        </CustomNavLink>
        <CustomNavLink to="/whatwedo/industries/" aria-label="industries" onClick={() => handleClick()}>
          Industries
        </CustomNavLink>
        <CustomNavLink to="/team/" aria-label="team" onClick={() => handleClick()}>
          Team
        </CustomNavLink>
        <CustomNavLink to="/about/careers/" aria-label="careers" onClick={() => handleClick()}>
          Careers
        </CustomNavLink>
        <CustomNavLink to="/contact/" aria-label="careers" onClick={() => handleClick()}>
          Contact
        </CustomNavLink>
      </div>
      <div className="menu-background" />
    </div>
  );
}
