import {
  DeviceTypes, DeviceTypeWidthList, ScreenBPList, ScreenBPTypes,
} from '../../../../../constants/device';
import { DirectionTypes } from '../../../../../constants/ui';

const deviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return DeviceTypes.Tablet;
  }
  if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return DeviceTypes.Mobile;
  }
  return DeviceTypes.Desktop;
};

const detectDeviceType = (width) => {
  switch (true) {
    case (width > DeviceTypeWidthList[1].minWidth):
      return deviceType();

    default:
      return DeviceTypes.Mobile;
  }
};

const detectBP = (width) => {
  switch (true) {
    case (width > ScreenBPList[4].minWidth):
      return ScreenBPList[4].BP;

    case (width > ScreenBPList[3].minWidth):
      return ScreenBPList[3].BP;

    case (width > ScreenBPList[2].minWidth):
      return ScreenBPList[2].BP;

    case (width > ScreenBPList[1].minWidth):
      return ScreenBPList[1].BP;

    case (width > ScreenBPList[0].minWidth):
      return ScreenBPList[0].BP;

    default:
      return ScreenBPTypes.SM;
  }
};

export const detectDevice = ({
  width, height, browserWidth, browserHeight, pixelRatio, horizontal,
}) => ({
  deviceType: detectDeviceType(width),
  deviceScreen: {
    width,
    height,
    browserWidth,
    browserHeight,
    breakPoint: detectBP(width),
    pixelRatio,
    horizontal,
  },
});

export const detectScrollDirection = (state, newTop) => ({
  top: newTop,
  delayTop: state.delayTop,
  yDirection: newTop > state.top ? DirectionTypes.Down : DirectionTypes.Up,
});
