import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../constants/api';

export const useTeamList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [teamData, setTeamDate] = useState({ managers: [], developers: [] });
  const navigate = useNavigate();

  const handleGetTeamList = async () => {
    try {
      setIsLoading(true);
      const res = await axios.get(`${API_URL}teams/`);
      setTeamDate((prevState) => ({ ...prevState, managers: res.data.managers, developers: res.data.developers }));
    } catch (e) {
      setIsError(true);
      await Promise.reject(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNavigateToMemberSingle = (memberId) => {
    navigate(`/team/${memberId}`);
  };

  useEffect(() => {
    (async () => handleGetTeamList())();
  }, []);

  return {
    isLoading,
    isError,
    teamData,
    handleNavigateToMemberSingle,
  };
};
