import React, { createContext, useReducer } from 'react';
import { appModalInitialState } from './initialState';
import { appModalStateReducer } from './reducer';

export const AppModalContext = createContext(appModalInitialState);

export function AppModalStateProvider({ children }) {
  const [modalState, dispatchModalState] = useReducer(appModalStateReducer, appModalInitialState, undefined);

  const value = React.useMemo(() => ({
    modalState, dispatchModalState,
  }), [modalState]);

  return (
    <AppModalContext.Provider
      value={value}
    >
      {
        children
      }
    </AppModalContext.Provider>
  );
}
