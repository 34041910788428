import React from 'react';
import { NavLink } from 'react-router-dom';

export function CustomNavLink(props) {
  const { children } = props;
  return (
    <NavLink {...props}>
      { children }
    </NavLink>
  );
}
