import { StoreActionConfigAppDevice } from './actions';
import { detectDevice, detectScrollDirection } from './helper';

export const appDeviceStateReducer = (
  state,
  action,
) => {
  switch (action.type) {
    case StoreActionConfigAppDevice.AppDeviceSetSize:
      return {
        ...state,
        ...detectDevice({
          width: action.payload.width,
          height: action.payload.height,
          browserWidth: action.payload.browserWidth || window.body?.clientWidth,
          browserHeight: action.payload.browserHeight || window.body?.clientHeight,
          pixelRatio: action.payload.pixelRatio,
          horizontal: action.payload.browserWidth > action.payload.browserHeight,
        }),
      };

    case StoreActionConfigAppDevice.AppDeviceSetScroll:
      return {
        ...state,
        deviceScroll: {
          ...detectScrollDirection(state.deviceScroll, action.payload),
        },
      };

    case StoreActionConfigAppDevice.AppDeviceSetAnimatedScroll:
      return {
        ...state,
        deviceScroll: {
          ...state.deviceScroll,
          delayTop: action.payload,
        },
      };

    case StoreActionConfigAppDevice.AppDeviceSetAnimation:
      return {
        ...state,
        isAnimated: action.payload,
      };

    default:
      return state;
  }
};
