import {
  AlignmentTypes,
  BlockTitleTypes, BlockTypes, BlockWrapperTypes, ButtonActionTypes, ButtonTypes, SectionTypes,
} from '../constants/ui';

import backgroundAgency from '../assets/images/backgrounds/backgraoundAgency.jpg';
import agencySection1 from '../assets/images/agency/agency-section-1.png';
import agencySection2 from '../assets/images/agency/agency-section-2.png';
import agencySection3 from '../assets/images/agency/agency-section-3.png';
import agencySection4 from '../assets/images/agency/agency-section-4.png';
import agencySection5 from '../assets/images/agency/agency-section-5.png';

import agencyApproach1 from '../assets/images/agency/approach-1.svg';
import agencyApproach2 from '../assets/images/agency/approach-2.svg';
import agencyApproach3 from '../assets/images/agency/approach-3.svg';
import agencyApproach4 from '../assets/images/agency/approach-4.svg';

const sectionCover = {
  sectionType: SectionTypes.Static,
  index: 28,
  config: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
    minHeight: 100,
    contendEndFixed: true,
    background: {
      backgroundImage: {
        image1x: backgroundAgency,
      },
    },
  },
  blockType: BlockTypes.PageDefault,
  blockData: {
    config: {
      zIndex: 30,
      color: '#000000',
      backgroundColor: '#FFFFFF',
      wrapperClassName: 'block-page-cover-centered',
      contentPosition: BlockWrapperTypes.Fixed,
      contentAlignment: AlignmentTypes.CC,
      mobile: {
        contentAlignment: AlignmentTypes.CC,
      },
    },

    content: {
      contentConfigs: {
        title: {
          fontSize: '3.5rem',
          marginTop: 0,
          color: '#FFFFFF',
        },
        description: {
          fontSize: '1.25rem',
          fontFamily: 'gilroy-semibold, serif',
          color: '#FFFFFF',
        },
      },
      title: 'We focus on solving business challenges',
      // eslint-disable-next-line max-len
      description: 'We apply a human-centered approach to put together the customers\' needs, technology, and business goals. Software development, like any other manufacturing process, stands on perfected craft, clear processes, and effective communication.',
    },
  },
};

const sectionTitle = {
  sectionType: SectionTypes.Sticky,
  index: 31,
  config: {
    color: '#000000',
    backgroundColor: '#F7F8FA',
    minHeight: 200,
    contendEndFixed: true,
  },
  blockType: BlockTypes.PageTitle,
  blockData: {
    config: {
      contentPosition: BlockWrapperTypes.Fixed,
      color: '#000000',
      specialColor: '#6b37ff',
      zIndex: 32,
      showContactBtn: false,
    },
    content: {
      blocks: [
        {
          textType: BlockTitleTypes.Static,
          // eslint-disable-next-line max-len
          text: 'Our team is delighted to build custom software ',
        },
        {
          textType: BlockTitleTypes.Colored,
          // eslint-disable-next-line max-len
          text: 'that allows flexibility to required changes and delivers measurable results for our clients and their customers worldwide.',
        },
      ],
    },
  },
};

export const agencyInfoSectionBlocks = [
  {
    sectionType: SectionTypes.Static,
    index: 34,
    blockType: BlockTypes.PageTextImage,
    config: {
      color: '#000000',
      backgroundColor: '#FFFFFF',
      minHeight: 100,
    },
    blockData: {
      config: {
        color: '#000000',
        isImageLeft: true,
        zIndex: 36,
      },
      content: {
        image: {
          image1: agencySection1,
          alt: 'groksmith agency concept development',
        },
        text: {
          styles: {
            header: {
              fontSize: '1.75rem',
              marginTop: 0,
            },
            description: {
              fontSize: '1.125rem',
              fontFamily: 'gilroy-medium',
              color: '#200039',
            },
          },
          header: {
            title: 'Concept Development',
            description: 'Helping clients turn their product vision into a business model hypothesis, testing for viability and making adjustments to achieve market fit.',
          },
          blocks: [],
        },
      },
    },
  },
  {
    sectionType: SectionTypes.Static,
    index: 38,
    blockType: BlockTypes.PageTextImage,
    config: {
      color: '#000000',
      backgroundColor: '#F7F8FA',
      minHeight: 100,
    },
    blockData: {
      config: {
        color: '#000000',
        isImageLeft: false,
        zIndex: 40,
      },
      content: {
        image: {
          image1: agencySection2,
          alt: 'groksmith agency design',
        },
        text: {
          styles: {
            header: {
              fontSize: '1.75rem',
              marginTop: 0,
            },
            description: {
              fontSize: '1.125rem',
              fontFamily: 'gilroy-medium',
              color: '#200039',
            },
          },
          header: {
            title: 'Product Design',
            description: 'We research user needs to structure the product, create an interactive model, test with users, and then finalize the designs',
          },
          blocks: [],
        },
      },
    },
  },
  {
    sectionType: SectionTypes.Static,
    index: 42,
    blockType: BlockTypes.PageTextImage,
    config: {
      color: '#000000',
      backgroundColor: '#FFFFFF',
      minHeight: 100,
    },
    blockData: {
      config: {
        color: '#000000',
        isImageLeft: true,
        zIndex: 44,
      },
      content: {
        image: {
          image1: agencySection3,
          alt: 'groksmith agency development',
        },
        text: {
          styles: {
            header: {
              fontSize: '1.75rem',
              marginTop: 0,
            },
            description: {
              fontSize: '1.125rem',
              fontFamily: 'gilroy-medium',
              color: '#200039',
            },
          },
          header: {
            title: 'Software Engineering',
            description: 'The full-scale development process, from the initial architecture design to ongoing product maintenance.',
          },
          blocks: [],
        },
      },
    },
  },
  {
    sectionType: SectionTypes.Static,
    index: 38,
    blockType: BlockTypes.PageTextImage,
    config: {
      color: '#000000',
      backgroundColor: '#F7F8FA',
      minHeight: 100,
    },
    blockData: {
      config: {
        color: '#000000',
        isImageLeft: false,
        zIndex: 40,
      },
      content: {
        image: {
          image1: agencySection4,
          alt: 'groksmith agency blockchain',
        },
        text: {
          styles: {
            header: {
              fontSize: '1.75rem',
              marginTop: 0,
            },
            description: {
              fontSize: '1.125rem',
              fontFamily: 'gilroy-medium',
              color: '#200039',
            },
          },
          header: {
            title: 'Blockchain',
            // eslint-disable-next-line max-len
            description: 'Tailored decentralized products for businesses in the fast-paced Web 3.0 market.',
          },
          blocks: [],
        },
      },
    },
  },
  {
    sectionType: SectionTypes.Static,
    index: 42,
    blockType: BlockTypes.PageTextImage,
    config: {
      color: '#000000',
      backgroundColor: '#FFFFFF',
      minHeight: 100,
    },
    blockData: {
      config: {
        color: '#000000',
        isImageLeft: true,
        zIndex: 44,
      },
      content: {
        image: {
          image1: agencySection5,
          alt: 'groksmith agency development',
        },
        text: {
          styles: {
            header: {
              fontSize: '1.75rem',
              marginTop: 0,
            },
            description: {
              fontSize: '1.125rem',
              fontFamily: 'gilroy-medium',
              color: '#200039',
            },
          },
          header: {
            title: 'Marketing',
            description: 'Product launch marketing strategies utilizing data-driven methods to identify the most efficient communication channels.',
          },
          blocks: [],
        },
      },
    },
  },
];

export const sectionOurApproach = {
  sectionType: SectionTypes.Static,
  index: 50,
  blockType: BlockTypes.PageText,
  config: {
    color: '#FFFFFF',
    backgroundColor: '#000000',
    minHeight: 80,
  },
  blockData: {
    config: {
      color: '#FFFFFF',
      isImageLeft: false,
      zIndex: 52,
    },
    content: {
      header: {
        title: 'Our Approach',
      },
      blocks: [
        {
          icon: agencyApproach1,
          title: 'We do it well',
          description: 'Uncompromising quality, Best practices.',
        },
        {
          icon: agencyApproach2,
          title: 'We do it with love',
          description: 'Care, Love, Transparency.',
        },
        {
          icon: agencyApproach3,
          title: 'We do it smart',
          description: 'Performance, Innovation, Effectiveness.',
        },
        {
          icon: agencyApproach4,
          title: 'We do it with fun',
          description: 'Good vibes, Friendliness, Fun.',
        },
      ],
      footer: {
        button: {
          label: 'Contact',
          type: ButtonTypes.ContactButton,
          action: {
            type: ButtonActionTypes.Mail,
            email: 'info@milinator.com',
          },
        },
      },
    },
  },
};

export const pageAgency = {
  config: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
  },
  sections: [
    sectionCover,
    sectionTitle,
    ...agencyInfoSectionBlocks,
    sectionOurApproach,
  ],
  seo: {
    title: 'Groksmith: Software Development Agency',
    // eslint-disable-next-line max-len
    description: 'We Build custom software that allows flexibility to required changes and delivers measurable results for our clients and their customers worldwide.',
    name: 'Groksmith website',
  },
};

export const pageTeam = {
  config: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
  },
  sections: [
    sectionCover,
    sectionTitle,
    ...agencyInfoSectionBlocks,
    sectionOurApproach,
  ],
  seo: {
    title: 'Groksmith: Software Development Agency',
    // eslint-disable-next-line max-len
    description: 'We Build custom software that allows flexibility to required changes and delivers measurable results for our clients and their customers worldwide.',
    name: 'Groksmith website',
  },
};
