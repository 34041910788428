import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { PageTypes } from '../../../constants/ui';

import { PageSection } from '../PageSection';
import { PageError } from '../PageError';

function PageContent(props) {
  const {
    isRender, pageData, path, pageType,
  } = props;
  if (isRender) {
    switch (pageType) {
      case PageTypes.SectionPage:
        return <PageSection pageData={pageData} path={path} />;

      case PageTypes.ErrorPage:
        return <PageError />;

      default:
        return <PageError />;
    }
  }

  return null;
}

export function PageContainer(props) {
  const { pageType, pageData, path } = props;
  const [isRender, setIsRender] = useState(false);

  useEffect(() => {
    setIsRender(false);
  }, [path]);

  useEffect(() => {
    setIsRender(true);
  }, [isRender]);

  return (
    <>
      <Helmet>
        {
          pageType === PageTypes.ErrorPage
          && (
          <title>
            Groksmith: 404
          </title>
          )
        }
        {
          pageType !== PageTypes.ErrorPage && pageData.seo.title
          && (
          <>
            <title>{pageData.seo.title}</title>
            <meta property="og:title" content={pageData.seo.title} />
            <meta name="twitter:title" content={pageData.seo.title} />
          </>
          )
        }
        {
          pageType !== PageTypes.ErrorPage && pageData.seo.description
          && (
          <>
            <meta name="description" content={pageData.seo.description} />
            <meta itemProp="description" content={pageData.seo.description} />
            <meta property="og:description" content={pageData.seo.description} />
            <meta name="twitter:description" content={pageData.seo.description} />
          </>
          )
        }
        {
          pageType !== PageTypes.ErrorPage && pageData.seo.name
          && <meta itemProp="name" content={pageData.seo.name} />
        }
        {
          pageType !== PageTypes.ErrorPage && pageData.seo.image1
          && <meta itemProp="image" content={pageData.seo.image1} />
          // <meta itemProp="image" content="%REACT_APP_BASE_URL%images/seo/1200x639.jpg" />
        }
        {
          pageType !== PageTypes.ErrorPage && pageData.seo.image2
          && <meta property="og:image" content={pageData.seo.image2} />
          // <meta property="og:image" content="%REACT_APP_BASE_URL%images/seo/2400x1260.jpg" />
        }
        {
          pageType !== PageTypes.ErrorPage && pageData.seo.image3
          && <meta name="twitter:image" content={pageData.seo.image3} />
          // <meta name="twitter:image" content="%REACT_APP_BASE_URL%images/seo/400x200.jpg" />
        }

        <meta property="og:url" content="%REACT_APP_BASE_URL%" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <PageContent {...props} isRender={isRender} />
    </>
  );
}
