import { AlignmentTypes, ImageContainTypes } from '../constants/ui';

const imagePosition = (cWidth, cHeight, width, height, alignment) => {
  switch (alignment) {
    case AlignmentTypes.LT:
      return {
        x: 0,
        y: 0,
      };

    case AlignmentTypes.CT:
      return {
        x: (cWidth - width) / 2,
        y: 0,
      };

    case AlignmentTypes.RT:
      return {
        x: (cWidth - width),
        y: 0,
      };

    case AlignmentTypes.LC:
      return {
        x: 0,
        y: (cHeight - height) / 2,
      };

    case AlignmentTypes.RC:
      return {
        x: (cWidth - width),
        y: (cHeight - height) / 2,
      };

    case AlignmentTypes.LB:
      return {
        x: 0,
        y: (cHeight - height),
      };

    case AlignmentTypes.CB:
      return {
        x: (cWidth - width) / 2,
        y: (cHeight - height),
      };

    case AlignmentTypes.RB:
      return {
        x: (cWidth - width),
        y: (cHeight - height),
      };

    default:
      return {
        x: (cWidth - width) / 2,
        y: (cHeight - height) / 2,
      };
  }
};

const calculateImageSize = (cWidth, cHeight, oWidth, oHeight, contain) => {
  let width;
  let height;

  if (contain === ImageContainTypes.Contain) {
    if (cWidth > cHeight) {
      height = cHeight;
      width = (height * oWidth) / oHeight;
    } else {
      width = cWidth;
      height = (width * oHeight) / oWidth;
    }
  }

  if (contain === ImageContainTypes.Cover) {
    if (oWidth > oHeight) {
      width = cWidth;
      height = (width * oHeight) / oWidth;
      if (height < cHeight) {
        height = cHeight;
        width = (height * oWidth) / oHeight;
      }
    } else {
      height = cHeight;
      width = (height * oWidth) / oHeight;
      if (width < cWidth) {
        width = cWidth;
        height = (width * oHeight) / oWidth;
      }
    }
  }

  return {
    width,
    height,
  };
};

export const returnImageStyle = (
  cWidth,
  cHeight,
  oWidth,
  oHeight,
  alignment = AlignmentTypes.CC,
  contain = ImageContainTypes.Cover,
) => {
  const imageSizes = calculateImageSize(cWidth, cHeight, oWidth, oHeight, contain);
  const position = imagePosition(cWidth, cHeight, imageSizes.width, imageSizes.height, alignment, contain);

  return {
    width: `${imageSizes.width}px`,
    height: `${imageSizes.height}px`,
    transform: `translate(${position.x}px, ${position.y}px)`,
    margin: 0,
  };
};
