import React from 'react';

import { routeTypeList } from './constants';
import { RestrictedRoute } from './RestrictedRoute';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

export function CustomRoute(props) {
  const {
    type,
    element,
  } = props;

  switch (type) {
    case routeTypeList.RESTRICTED:
      return (
        <RestrictedRoute>
          {
            element
          }
        </RestrictedRoute>
      );

    case routeTypeList.PRIVATE:
      return (
        <PrivateRoute>
          {
            element
          }
        </PrivateRoute>
      );

    default: return (
      <PublicRoute>
        {
          element
        }
      </PublicRoute>
    );
  }
}
