export const DeviceTypes = {
  Desktop: 'desktop',
  Tablet: 'Tablet',
  Mobile: 'mobile',
};

export const DeviceTypeWidthList = [
  {
    type: DeviceTypes.Mobile,
    minWidth: 0,
  },
  {
    type: DeviceTypes.Desktop,
    minWidth: 700,
  },
];

export const ScreenBPTypes = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl',
};

export const ScreenBPList = [
  {
    BP: ScreenBPTypes.XS,
    minWidth: 0,
  },
  {
    BP: ScreenBPTypes.SM,
    minWidth: 340,
  },
  {
    BP: ScreenBPTypes.MD,
    minWidth: 600,
  },
  {
    BP: ScreenBPTypes.LG,
    minWidth: 900,
  },
  {
    BP: ScreenBPTypes.XL,
    minWidth: 1200,
  },
];
