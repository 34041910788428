import { StoreActionConfigAppLoader } from './actions';

export const appLoaderStateReducer = (
  state,
  action,
) => {
  switch (action.type) {
    case StoreActionConfigAppLoader.AppConfigSetLoaderOpen:
      return {
        ...state,
        isOpen: action.payload?.isOpen || action.payload || true,
        message: action.payload?.message || '',
      };

    case StoreActionConfigAppLoader.AppConfigSetLoaderClose:
      return {
        ...state,
        isOpen: false,
        message: '',
      };

    default:
      return state;
  }
};
