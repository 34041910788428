import React, { useEffect, useRef } from 'react';

import { BlockTypes, SectionTypes } from '../../../../constants/ui';

import { StoreActionSectionState } from '../../../../dao/store/actions';
import { useStoreConfigAppDevice, useStoreSectionState } from '../../../../dao/store/hoox';
import { SectionStateProvider } from '../../../../dao/store/providers';
import { BlockBackground } from '../../blocks';
import { DeviceTypes } from '../../../../constants/device';

function SectionContent(props) {
  const {
    type = SectionTypes.Default,
    blockType = BlockTypes.PageCover,
    config,
    mobileConfig,
    index = 50,
    children,
    path,
  } = props;

  const { deviceState } = useStoreConfigAppDevice();
  const { sectionState, dispatchSectionState } = useStoreSectionState();

  const sectionContainer = useRef(null);
  const sectionContainerWrapper = useRef(null);

  const calculateMinHeight = (deviceHeight, percent, ratio) => {
    const currentHeight = (deviceHeight * percent) / 100;

    if (ratio < 1) {
      return (currentHeight / ratio);
    }
    return currentHeight;
  };

  useEffect(() => {
    const newHeight = sectionContainerWrapper?.current?.offsetHeight > sectionState.contentCurrentHeight
      ? sectionContainerWrapper?.current?.offsetHeight
      : sectionState.contentCurrentHeight;
    dispatchSectionState({
      type: StoreActionSectionState.SetSectionCurrentHeight,
      payload: (
        (sectionState.sectionMinHeight > newHeight)
          ? sectionState.sectionMinHeight
          : newHeight
      ),
    });
  }, [
    path,
    sectionState.reRender,
    sectionState.sectionMinHeight,
    deviceState.deviceScreen.pixelRatio,
    deviceState.deviceScreen.browserWidth,
    deviceState.deviceScreen.browserHeight,
    sectionState.contentCurrentHeight,
    sectionState.contentTop,
    sectionContainerWrapper?.current?.offsetHeight,
  ]);

  useEffect(() => {
    dispatchSectionState({
      type: StoreActionSectionState.SetSectionMinHeight,
      payload: calculateMinHeight(
        deviceState.deviceScreen.browserHeight,
        deviceState.deviceType === DeviceTypes.Mobile
          ? mobileConfig?.minHeight || config.minHeight
          : config.minHeight,
        1,
      ),
    });
  }, [
    path,
    sectionState.reRender,
    deviceState.deviceScreen.height,
    deviceState.deviceScreen.browserWidth,
    deviceState.deviceScreen.browserHeight,
    deviceState.deviceScreen.pixelRatio,
  ]);

  return (
    <section
      ref={sectionContainer}
      className={`section-container-${type}`}
      style={{
        position: 'relative',
        display: config.display || 'block',
        height: `${
          type === SectionTypes.Sticky ? sectionState.sectionMinHeight : sectionState.sectionCurrentHeight
        }px`,
        background: config?.background || config?.backgroundColor || 'transparent',
        backgroundColor: config?.backgroundColor || 'transparent',
        zIndex: index || 1,
      }}
    >
      <div
        className={`section-container-${type}-wrapper`}
        style={
          // eslint-disable-next-line no-nested-ternary
          (SectionTypes.Sticky)
            ? (config.contendEndFixed)
              ? {
                height: '0',
                zIndex: 2,
              }
              : {
                zIndex: 2,
                height: `${
                  (sectionState.contentTop + (
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    sectionState.contentCurrentHeight || sectionContainerWrapper?.current?.offsetHeight
                  ))
                }px`,
                display: config.wrapperDisplay || 'inherit',
                verticalAlign: 'middle',
              }
            : {
              zIndex: 2,
              height: '100%',
            }
        }
      >
        <div
          className={`section-container-${type}-content`}
          ref={sectionContainerWrapper}
          style={{
            position: 'relative',
            overflow: config.overflow || 'inherit',
            zIndex: 3,
          }}
        >
          {
            children && (
              children
            )
          }
        </div>
      </div>
      {
        config.background
        && (
          <BlockBackground
            className="section-background"
            sectionType={type}
            blockType={blockType}
            {...config.background}
            index={1}
          />
        )
      }
    </section>
  );
}

export function SectionContainer(props) {
  return (
    <SectionStateProvider>
      <SectionContent {...props} />
    </SectionStateProvider>
  );
}
