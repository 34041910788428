import React, { useRef, useState } from 'react';
import { EventItem } from './components/EventItem';
import { ButtonContainer } from '../../../../components/shared/buttons/ButtonContainer';
import { useStoreConfigAppDevice } from '../../../../dao/store/states/ConfigState';

function PageContent(props) {
  const {
    config,
    content: {
      header,
      blocks,
      footer,
    },
  } = props;

  const eventsRow = useRef(null);
  const eventsRowWrapper = useRef(null);
  const [activeIndex, setActiveIndex] = useState(blocks?.length ? ((blocks.length - 1) / 2) : 0);

  return (
    <div
      className="page-event"
      style={{
        color: config.color || '#FFFFFF',
      }}
    >
      {
        header
        && (
          <div
            className="content-header"
          >
            {
              header?.title
              && <h3>{header.title}</h3>
            }
            {
              header?.description
              && <p>{header.description}</p>
            }
            {
              header?.button
              && <ButtonContainer {...footer.button} />
            }
          </div>
        )
      }
      {
        !!blocks?.length
        && (
          <div className="content-blocks events-row">
            <div className="events-row-root" ref={eventsRow}>
              <div className="event-row-wrapper" ref={eventsRowWrapper}>
                {
                  blocks.map((item, key) => (
                    <EventItem
                      className="event-item"
                      isActive={key === activeIndex}
                      setActiveIndex={setActiveIndex}
                      index={key}
                      key={`event-${key}`}
                      {
                        ...item
                      }
                    />
                  ))
                }
              </div>
            </div>
          </div>
        )
      }
      {
        footer
        && (
          <div
            className="content-footer"
          >
            {
              footer?.button
              && <ButtonContainer {...footer.button} />
            }
            {
              footer?.title
              && <h1>{footer.title}</h1>
            }
            {
              footer?.description
              && <p>{footer.description}</p>
            }
          </div>
        )
      }
    </div>
  );
}

export function BlockPageEvents(props) {
  const {
    blockData,
  } = props;

  const { deviceState } = useStoreConfigAppDevice();

  return (
    <div
      className={`block-page-event ${
        deviceState.deviceScreen.horizontal
          ? ''
          : 'block-page-event-vertical'
      }`}
    >
      <PageContent {...blockData} />
    </div>
  );
}
