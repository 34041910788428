import React, { createContext, useReducer } from 'react';
import { appHeaderInitialState } from './initialState';
import { appHeaderStateReducer } from './reducer';

export const AppHeaderContext = createContext(appHeaderInitialState);

export function AppHeaderStateProvider({ children }) {
  const [headerState, dispatchHeaderState] = useReducer(appHeaderStateReducer, appHeaderInitialState, undefined);

  const value = React.useMemo(() => ({
    headerState, dispatchHeaderState,
  }), [headerState]);

  return (
    <AppHeaderContext.Provider
      value={value}
    >
      {
        children
      }
    </AppHeaderContext.Provider>
  );
}
