import React from 'react';

export function IconSocialFacebook(props) {
  const { className, style, colorFill = 'black' } = props;

  return (
    <svg
      width="1.375em"
      height="1.375em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      {/* eslint-disable-next-line max-len */}
      <path fill={colorFill} d="M18.5,5.1h1.7V2.3c-0.8-0.1-1.6-0.1-2.4-0.1c-2.4,0-4.1,1.5-4.1,4.2v2.3H11v3.2h2.7v8.1H17v-8.1h2.7l0.4-3.2H17v-2 C17,5.7,17.2,5.1,18.5,5.1z" />
    </svg>
  );
}
