import { StoreActionClient } from './actions';

export const clientStateReducer = (
  state,
  action,
) => {
  switch (action.type) {
    case StoreActionClient.ClientSetLoggedIn:
      return {
        ...state,
        isLoggedIn: action.payload,
      };

    case StoreActionClient.ClientSetProfile:
      return {
        ...state,
        profile: {
          ...action.payload,
        },
      };

    case StoreActionClient.ClientSetTokens:
      return {
        ...state,
        tokens: {
          ...action.payload,
        },
      };

    default:
      return state;
  }
};
