import React from 'react';

export function HomeTitle(props) {
  const { className, style } = props;

  return (
    <svg
      className={className}
      style={{
        ...style,
        width: '100%',
        height: '100%',
        mixBlendMode: 'difference',
        filter: 'grayscale(1)',
      }}
      viewBox="0 0 628.48 384.27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#FFFFFF">
        <path
          d="M22.34,81.48L0,1.68H16.53l15.28,59.17L48.45,1.68h13.45l16.76,59.17L93.93,
          1.68h16.53l-22.34,79.8h-17.9l-15.05-52.67-14.93,52.67H22.34Z"
        />
        <path
          d="M121.75,59.02c1.94,7.18,7.3,10.72,16.07,10.72,5.59,0,9.92-1.94,12.77-5.7l11.86,6.84c-5.59,
          8.09-13.91,12.2-24.85,12.2-9.46,0-16.99-2.85-22.69-8.55-5.7-5.7-8.55-12.88-8.55-21.55s2.85-15.73,
          8.44-21.43c5.59-5.81,12.88-8.66,21.66-8.66,8.32,0,15.28,2.85,20.63,8.66,5.47,5.81,8.21,12.88,8.21,
          21.43,0,1.94-.23,3.88-.57,6.04h-42.98Zm28.84-11.4c-1.71-7.75-7.41-11.51-14.14-11.51-7.87,0-13.22,
          4.22-14.93,11.51h29.07Z"
        />
        <path
          d="M207.36,71.1c-7.87-7.98-11.74-17.78-11.74-29.53s3.88-21.66,11.74-29.53C215.23,
          4.07,225.15,.08,237.12,.08c14.48,0,27.47,7.3,34.31,18.81l-13.57,7.87c-3.99-7.18-11.63-11.4-20.75-11.4-7.75,
          0-14.02,2.39-18.81,7.3-4.67,4.9-7.07,11.17-7.07,18.92s2.39,13.91,7.07,18.81c4.79,4.9,11.06,7.3,18.81,
          7.3,9.12,0,16.99-4.33,20.75-11.29l13.57,7.87c-6.84,11.51-19.72,18.81-34.31,18.81-11.97,
          0-21.89-3.99-29.75-11.97Z"
        />
        <path
          d="M293.32,34.28c2.74-7.3,9.58-10.94,17.33-10.94v16.42c-4.45-.57-8.55,.34-12.08,
          2.74-3.53,2.39-5.24,6.27-5.24,11.74v27.25h-14.71V24.48h14.71v9.8Z"
        />
        <path
          d="M357.61,24.48h14.71v57h-14.71v-6.73c-4.45,5.59-10.6,8.32-18.58,
          8.32s-14.14-2.85-19.61-8.66c-5.36-5.81-8.09-13-8.09-21.43s2.74-15.5,
          8.09-21.32c5.47-5.81,11.97-8.78,19.61-8.78s14.14,2.74,18.58,8.32v-6.73Zm-15.85,
          44.57c4.56,0,8.32-1.48,11.29-4.45s4.56-6.95,
          4.56-11.63-1.48-8.55-4.56-11.51c-2.96-3.08-6.73-4.56-11.29-4.56s-8.32,1.48-11.29,
          4.56c-2.96,2.96-4.45,6.84-4.45,11.51s1.48,8.55,4.45,11.63c2.96,2.96,6.73,4.45,
          11.29,4.45Z"
        />
        <path
          d="M413.59,14.22c-8.09-.57-12.08,2.62-12.08,
          9.69v.57h12.08v14.14h-12.08v42.86h-14.71V38.61h-8.21v-14.14h8.21v-.57c0-16.07,
          9.01-24.85,26.79-23.83V14.22Z"
        />
        <path
          d="M452,38.61h-12.88v23.71c0,6.16,4.45,6.27,12.88,5.81v13.34c-20.18,
          2.28-27.59-3.53-27.59-19.15v-23.71h-9.92v-14.14h9.92V12.96l14.71-4.45v15.96h12.88v14.14Z"
        />
        <path d="M5.7,102.08h15.73v79.8H5.7V102.08Z" />
        <path
          d="M76.04,102.08h14.71v79.8h-14.71v-6.73c-4.33,5.59-10.49,8.32-18.47,
          8.32s-14.25-2.85-19.72-8.66c-5.36-5.81-8.09-13-8.09-21.43s2.74-15.5,
          8.09-21.32c5.47-5.81,12.08-8.78,19.72-8.78s14.14,2.74,18.47,8.32v-29.53Zm-15.73,
          67.37c4.56,0,8.32-1.48,11.29-4.45,2.96-3.08,4.45-6.95,
          4.45-11.63s-1.48-8.55-4.45-11.51c-2.96-3.08-6.73-4.56-11.29-4.56s-8.32,
          1.48-11.4,4.56-4.45,6.84-4.45,11.51,1.48,8.55,4.45,11.63c3.08,2.96,6.84,
          4.45,11.4,4.45Z"
        />
        <path
          d="M113.88,159.42c1.94,7.18,7.3,10.72,16.07,10.72,5.59,0,9.92-1.94,12.77-5.7l11.86,
          6.84c-5.59,8.09-13.91,12.2-24.85,12.2-9.46,
          0-16.99-2.85-22.69-8.55-5.7-5.7-8.55-12.88-8.55-21.55s2.85-15.73,
          8.44-21.43c5.59-5.81,12.88-8.66,21.66-8.66,8.32,0,15.28,2.85,20.63,8.66,5.47,5.81,
          8.21,12.88,8.21,21.43,0,1.94-.23,3.88-.57,
          6.04h-42.98Zm28.84-11.4c-1.71-7.75-7.41-11.51-14.14-11.51-7.87,0-13.22,4.22-14.93,11.51h29.07Z"
        />
        <path
          d="M207.82,124.88h14.71v57h-14.71v-6.73c-4.45,5.59-10.6,8.32-18.58,
          8.32s-14.14-2.85-19.61-8.66c-5.36-5.81-8.09-13-8.09-21.43s2.74-15.5,
          8.09-21.32c5.47-5.81,11.97-8.78,19.61-8.78s14.14,2.74,18.58,8.32v-6.73Zm-15.85,
          44.57c4.56,0,8.32-1.48,11.29-4.45s4.56-6.95,
          4.56-11.63-1.48-8.55-4.56-11.51c-2.96-3.08-6.73-4.56-11.29-4.56s-8.32,1.48-11.29,
          4.56c-2.96,2.96-4.45,6.84-4.45,11.51s1.48,8.55,4.45,11.63c2.96,2.96,6.73,4.45,11.29,4.45Z"
        />
        <path
          d="M256.61,147.33c8.32,1.71,19.15,5.7,19.04,17.9,0,5.81-2.17,10.37-6.61,13.57-4.33,3.08-9.8,
          4.67-16.3,4.67-11.74,0-19.72-4.56-23.94-13.57l12.77-7.18c1.71,4.9,5.36,7.41,11.17,7.41,5.24,
          0,7.87-1.6,7.87-4.9,0-3.08-4.56-4.79-10.37-6.27-8.44-2.17-19.04-5.7-19.04-17.56,0-5.59,2.05-10.03,
          6.16-13.22,4.22-3.31,9.35-4.9,15.5-4.9,9.23,0,17.21,4.33,21.66,12.08l-12.54,
          6.84c-1.82-3.88-4.9-5.81-9.12-5.81-3.76,0-6.61,1.82-6.61,4.67,0,3.08,4.56,4.79,10.37,6.27Z"
        />
        <path
          d="M307,109.03c0-4.9,4.1-9.12,9.01-9.12s9.12,4.22,9.12,9.12-4.1,9.01-9.12,
          9.01-9.01-4.1-9.01-9.01Zm1.71,72.84v-57h14.71v57h-14.71Z"
        />
        <path
          d="M381.21,129.55c4.1,4.22,6.16,9.92,6.16,
          17.33v35h-14.71v-33.17c0-7.64-4.45-11.74-11.29-11.74-7.64,0-12.54,4.67-12.54,
          14.14v30.78h-14.71v-57h14.71v6.38c3.53-5.36,9.23-7.98,16.99-7.98,6.27,0,11.4,
          2.05,15.39,6.27Z"
        />
        <path
          d="M429.77,139.01h-12.88v23.71c0,6.16,4.45,6.27,12.88,5.81v13.34c-20.18,
          2.28-27.59-3.53-27.59-19.15v-23.71h-9.92v-14.14h9.92v-11.51l14.71-4.45v15.96h12.88v14.14Z"
        />
        <path
          d="M462.83,183.47c-8.44,
          0-15.62-2.85-21.43-8.66s-8.66-13-8.66-21.43,2.85-15.5,8.66-21.32c5.81-5.81,
          13-8.78,21.43-8.78s15.62,2.96,21.43,8.78,8.78,12.88,8.78,21.32-2.96,15.62-8.78,
          21.43c-5.81,5.81-13,8.66-21.43,8.66Zm0-14.36c4.45,0,8.09-1.48,11.06-4.45s4.45-6.73,
          4.45-11.29-1.48-8.32-4.45-11.29-6.61-4.45-11.06-4.45-8.09,1.48-11.06,4.45c-2.85,
          2.96-4.33,6.73-4.33,11.29s1.48,8.32,4.33,11.29c2.96,2.96,6.61,4.45,11.06,4.45Z"
        />
        <path
          d="M43.43,283.87c-11.51,
          0-21.32-3.99-29.41-11.97-7.98-8.09-11.97-17.9-11.97-29.53s3.99-21.43,
          11.97-29.41c8.09-8.09,17.9-12.08,29.41-12.08s21.32,3.99,29.41,12.08c8.09,
          7.98,12.08,17.78,12.08,29.41s-3.99,21.43-12.08,29.53c-8.09,7.98-17.9,11.97-29.41,
          11.97Zm0-15.39c7.3,0,13.45-2.39,18.35-7.3,4.9-4.9,7.41-11.17,
          7.41-18.81s-2.51-13.91-7.41-18.81c-4.9-4.9-11.06-7.41-18.35-7.41s-13.45,2.51-18.35,
          7.41c-4.9,4.9-7.41,11.17-7.41,18.81s2.51,13.91,7.41,18.81c4.9,4.9,11.06,7.3,18.35,7.3Z"
        />
        <path
          d="M130.76,225.28h14.71v57h-14.71v-6.38c-3.53,5.36-9.23,7.98-16.99,
          7.98-6.27,0-11.4-2.05-15.5-6.27-3.99-4.22-6.04-9.92-6.04-17.33v-35h14.71v33.17c0,
          7.64,4.45,11.74,11.29,11.74,7.64,0,12.54-4.67,12.54-14.14v-30.78Z"
        />
        <path
          d="M188.78,239.41h-12.88v23.71c0,6.16,4.45,6.27,12.88,5.81v13.34c-20.18,
          2.28-27.59-3.53-27.59-19.15v-23.71h-9.92v-14.14h9.92v-11.51l14.71-4.45v15.96h12.88v14.14Z"
        />
        <path
          d="M219.11,247.73c8.32,1.71,19.15,5.7,19.04,17.9,0,5.81-2.17,10.37-6.61,
          13.57-4.33,3.08-9.8,4.67-16.3,4.67-11.74,0-19.72-4.56-23.94-13.57l12.77-7.18c1.71,
          4.9,5.36,7.41,11.17,7.41,5.24,0,7.87-1.6,7.87-4.9,
          0-3.08-4.56-4.79-10.37-6.27-8.44-2.17-19.04-5.7-19.04-17.56,0-5.59,2.05-10.03,6.16-13.22,
          4.22-3.31,9.35-4.9,15.5-4.9,9.23,0,17.21,4.33,21.66,12.08l-12.54,
          6.84c-1.82-3.88-4.9-5.81-9.12-5.81-3.76,0-6.61,1.82-6.61,4.67,0,3.08,4.56,4.79,10.37,6.27Z"
        />
        <path
          d="M275.76,239.41h-12.88v23.71c0,6.16,4.45,6.27,12.88,5.81v13.34c-20.18,
          2.28-27.59-3.53-27.59-19.15v-23.71h-9.92v-14.14h9.92v-11.51l14.71-4.45v15.96h12.88v14.14Z"
        />
        <path
          d="M325.01,225.28h14.71v57h-14.71v-6.73c-4.45,5.59-10.6,8.32-18.58,
          8.32s-14.14-2.85-19.61-8.66c-5.36-5.81-8.09-13-8.09-21.43s2.74-15.5,
          8.09-21.32c5.47-5.81,11.97-8.78,19.61-8.78s14.14,2.74,18.58,8.32v-6.73Zm-15.85,
          44.57c4.56,0,8.32-1.48,11.29-4.45s4.56-6.95,
          4.56-11.63-1.48-8.55-4.56-11.51c-2.96-3.08-6.73-4.56-11.29-4.56s-8.32,1.48-11.29,
          4.56c-2.96,2.96-4.45,6.84-4.45,11.51s1.48,8.55,4.45,11.63c2.96,2.96,6.73,4.45,11.29,4.45Z"
        />
        <path
          d="M397.4,229.95c4.1,4.22,6.16,9.92,6.16,17.33v35h-14.71v-33.17c0-7.64-4.45-11.74-11.29-11.74-7.64,
          0-12.54,4.67-12.54,14.14v30.78h-14.71v-57h14.71v6.38c3.53-5.36,9.23-7.98,16.99-7.98,6.27,0,11.4,2.05,
          15.39,6.27Z"
        />
        <path
          d="M456.57,202.48h14.71v79.8h-14.71v-6.73c-4.33,5.59-10.49,8.32-18.47,
          8.32s-14.25-2.85-19.72-8.66c-5.36-5.81-8.09-13-8.09-21.43s2.74-15.5,8.09-21.32c5.47-5.81,
          12.08-8.78,19.72-8.78s14.14,2.74,18.47,8.32v-29.53Zm-15.73,67.37c4.56,0,8.32-1.48,11.29-4.45,
          2.96-3.08,4.45-6.95,4.45-11.63s-1.48-8.55-4.45-11.51c-2.96-3.08-6.73-4.56-11.29-4.56s-8.32,1.48-11.4,
          4.56-4.45,6.84-4.45,11.51,1.48,8.55,4.45,11.63c3.08,2.96,6.84,4.45,11.4,4.45Z"
        />
        <path
          d="M480.28,209.43c0-4.9,4.1-9.12,9.01-9.12s9.12,4.22,9.12,9.12-4.1,9.01-9.12,
          9.01-9.01-4.1-9.01-9.01Zm1.71,72.84v-57h14.71v57h-14.71Z"
        />
        <path
          d="M554.49,229.95c4.1,4.22,6.16,9.92,6.16,17.33v35h-14.71v-33.17c0-7.64-4.45-11.74-11.29-11.74-7.64,
          0-12.54,4.67-12.54,14.14v30.78h-14.71v-57h14.71v6.38c3.53-5.36,9.23-7.98,16.99-7.98,6.27,0,11.4,2.05,
          15.39,6.27Z"
        />
        <path
          d="M614.11,225.28h14.36v54.26c0,8.78-2.96,15.5-8.78,20.18-5.81,4.67-12.77,6.95-20.98,6.95-12.31,
          0-22-4.79-27.02-13.91l12.54-7.18c2.85,5.36,7.87,7.98,14.93,7.98,9.23,0,14.93-5.02,
          14.93-14.02v-6.16c-4.33,5.81-10.37,8.66-18.24,
          8.66s-14.82-2.85-20.29-8.44c-5.36-5.7-8.09-12.65-8.09-20.75s2.74-14.93,8.21-20.63c5.47-5.7,
          12.2-8.55,20.18-8.55s13.91,2.85,18.24,8.66v-7.07Zm-4.56,16.53c-2.96-2.96-6.73-4.45-11.4-4.45s-8.44,
          1.48-11.51,4.45c-2.96,2.85-4.45,6.61-4.45,11.06,0,9.12,6.73,15.62,15.96,15.62s15.96-6.5,
          15.96-15.62c0-4.45-1.48-8.21-4.56-11.06Z"
        />
        <path
          d="M35.45,302.88c7.64,0,14.14,2.62,19.38,7.75s7.87,11.51,7.87,19.04-2.62,13.91-7.87,19.04-11.74,
          7.75-19.38,7.75h-14.02v26.22H5.7v-79.8h29.75Zm0,38.87c6.73,0,11.63-5.24,
          11.63-12.08s-4.9-12.08-11.63-12.08h-14.02v24.17h14.02Z"
        />
        <path
          d="M82.99,335.48c2.74-7.3,9.58-10.94,17.33-10.94v16.42c-4.45-.57-8.55,
          .34-12.08,2.74-3.53,2.39-5.24,6.27-5.24,11.74v27.25h-14.71v-57h14.71v9.8Z"
        />
        <path
          d="M131.1,384.27c-8.44,
          0-15.62-2.85-21.43-8.66-5.81-5.81-8.66-13-8.66-21.43s2.85-15.5,
          8.66-21.32c5.81-5.81,13-8.78,21.43-8.78s15.62,2.96,21.43,8.78,8.78,
          12.88,8.78,21.32-2.96,15.62-8.78,21.43c-5.81,5.81-13,8.66-21.43,8.66Zm0-14.36c4.45,0,
          8.09-1.48,11.06-4.45s4.45-6.73,4.45-11.29-1.48-8.32-4.45-11.29-6.61-4.45-11.06-4.45-8.09,
          1.48-11.06,4.45c-2.85,2.96-4.33,6.73-4.33,11.29s1.48,8.32,4.33,11.29c2.96,2.96,6.61,4.45,
          11.06,4.45Z"
        />
        <path
          d="M212.15,302.88h14.71v79.8h-14.71v-6.73c-4.33,5.59-10.49,8.32-18.47,
          8.32s-14.25-2.85-19.72-8.66c-5.36-5.81-8.09-13-8.09-21.43s2.74-15.5,8.09-21.32c5.47-5.81,
          12.08-8.78,19.72-8.78s14.14,2.74,18.47,8.32v-29.53Zm-15.73,67.37c4.56,0,8.32-1.48,11.29-4.45,
          2.96-3.08,4.45-6.95,4.45-11.63s-1.48-8.55-4.45-11.51c-2.96-3.08-6.73-4.56-11.29-4.56s-8.32,
          1.48-11.4,4.56-4.45,6.84-4.45,11.51,1.48,8.55,4.45,11.63c3.08,2.96,6.84,4.45,11.4,4.45Z"
        />
        <path
          d="M275.19,325.68h14.71v57h-14.71v-6.38c-3.53,5.36-9.23,7.98-16.99,7.98-6.27,
          0-11.4-2.05-15.5-6.27-3.99-4.22-6.04-9.92-6.04-17.33v-35h14.71v33.17c0,7.64,4.45,11.74,
          11.29,11.74,7.64,0,12.54-4.67,12.54-14.14v-30.78Z"
        />
        <path
          d="M306.2,375.61c-5.7-5.81-8.55-12.88-8.55-21.43s2.85-15.62,
          8.55-21.43c5.81-5.81,13-8.66,21.55-8.66,11.06,0,20.86,5.7,25.54,14.71l-12.65,
          7.41c-2.28-4.67-7.18-7.64-13-7.64-8.78,0-15.28,6.5-15.28,15.62,0,4.45,1.48,8.21,
          4.33,11.17,2.85,2.85,6.5,4.33,10.94,4.33,5.93,0,10.83-2.85,13.11-7.52l12.77,
          7.3c-5.02,9.01-14.71,14.82-25.76,14.82-8.55,0-15.73-2.85-21.55-8.66Z"
        />
        <path
          d="M391.13,339.81h-12.88v23.71c0,6.16,4.45,6.27,12.88,5.81v13.34c-20.18,
          2.28-27.59-3.53-27.59-19.15v-23.71h-9.92v-14.14h9.92v-11.51l14.71-4.45v15.96h12.88v14.14Z"
        />
        <path
          d="M421.46,348.13c8.32,1.71,19.15,5.7,19.04,17.9,0,5.81-2.17,10.37-6.61,
          13.57-4.33,3.08-9.8,4.67-16.3,4.67-11.74,0-19.72-4.56-23.94-13.57l12.77-7.18c1.71,
          4.9,5.36,7.41,11.17,7.41,5.24,0,7.87-1.6,7.87-4.9,
          0-3.08-4.56-4.79-10.37-6.27-8.44-2.17-19.04-5.7-19.04-17.56,0-5.59,2.05-10.03,
          6.16-13.22,4.22-3.31,9.35-4.9,15.5-4.9,9.23,0,17.21,4.33,21.66,12.08l-12.54,
          6.84c-1.82-3.88-4.9-5.81-9.12-5.81-3.76,0-6.61,1.82-6.61,4.67,0,3.08,4.56,4.79,
          10.37,6.27Z"
        />
        <path
          d="M448.25,381.31c-3.76-3.76-3.76-9.92,0-13.68,3.76-3.76,9.92-3.76,13.68,0,
          3.76,3.76,3.76,9.92,0,13.68-3.76,3.76-9.92,3.76-13.68,0Z"
        />
      </g>
    </svg>
  );
}
