import React from 'react';

export function Structure() {
  return (
    <div className="tw-bg-white tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full">
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-24 tw-w-full tw-my-20">
        <div className="tw-text-start tw-flex tw-flex-wrap tw-gap-10 tw-justify-center tw-font-medium tw-w-full [&>p]:tw-min-w-[135px] [&>div]:tw-min-w-[180px] [&>p]:tw-max-w-[135px] [&>div]:tw-max-w-[220px]">
          <div className="tw-flex tw-flex-col tw-gap-[18px] tw-flex-1">
            <p className="tw-text-[2.75rem] tw-font-extrabold tw-text-[#0B132A1F]">1</p>
            <h4 className="tw-text-[18px] tw-leading-[26px] tw-font-bold tw-text-violet">Business analysis & evaluation</h4>
            <p className="tw-leading-6 tw-text-[14px]">
              {'Conducting an in-depth analysis of the company\'s vision and goals through collaboration with key stakeholders.'}
            </p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-[18px] tw-flex-1">
            <p className="tw-text-[2.75rem] tw-font-extrabold tw-text-[#0B132A1F]">2</p>
            <h4 className="tw-text-[18px] tw-leading-[26px] tw-font-bold tw-text-violet">Market research & prototyping</h4>
            <p className="tw-leading-6 tw-text-[14px]">
              Before designing the product, qualitative research was executed through face-to-face interviews implemented in the San Francisco market. This helped in understanding market demand and identifying a niche clientele to focus on.
            </p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-[18px] tw-flex-1">
            <p className="tw-text-[2.75rem] tw-font-extrabold tw-text-[#0B132A1F]">3</p>
            <h4 className="tw-text-[18px] tw-leading-[26px] tw-font-bold tw-text-violet">Product design</h4>
            <p className="tw-leading-6 tw-text-[14px]">
              User research, wireframing, validation and draft branding.
            </p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-[18px] tw-flex-1">
            <p className="tw-text-[2.75rem] tw-font-extrabold tw-text-[#0B132A1F]">4</p>
            <h4 className="tw-text-[18px] tw-leading-[26px] tw-font-bold tw-text-violet">Software development</h4>
            <p className="tw-leading-6 tw-text-[14px]">
              Design and implementation of RESTful APISs on .NET. Development of Vue.js based webapp for frontend.
              Development of full blockchain driven investment and trading infrastructure based on Ethereum ecosystem.
            </p>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-[18px] tw-flex-1">
            <p className="tw-text-[2.75rem] tw-font-extrabold tw-text-[#0B132A1F]">5</p>
            <h4 className="tw-text-[18px] tw-leading-[26px] tw-font-bold tw-text-violet">Go to market</h4>
            <p className="tw-leading-6 tw-text-[14px]">
              Final branding, website development, go-to-market strategy and implementation.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
