import React from 'react';
import { useStoreConfigAppDevice } from '../../../../../dao/store/states/ConfigState';

export function Structure() {
  const { deviceState } = useStoreConfigAppDevice();

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-h-full">
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-24 max-md:tw-gap-16 tw-w-full tw-max-w-[1030px]">
        <h3>The core thesis behind Helios</h3>
        <div className={`tw-flex ${deviceState.deviceScreen.horizontal ? '' : 'tw-flex-col tw-gap-20 tw-items-center'} tw-gap-10 tw-justify-between tw-font-medium tw-w-full`}>
          <div className="tw-flex tw-flex-col tw-gap-9 tw-flex-1 tw-max-w-[420px]">
            <div className="tw-flex tw-flex-col tw-gap-5 ">
              <p className="tw-text-[2.75rem] tw-font-extrabold tw-text-[#0B132A1F]">1</p>
              <p className="tw-leading-6 ">
                There is a massive shortfall of financing for clean energy projects in emerging markets,
                a crucial lever in our global fight against climate change.
              </p>
            </div>
            <div className="tw-flex tw-flex-col tw-gap-5">
              <p className="tw-text-[2.75rem] tw-font-extrabold tw-text-[#0B132A1F]">2</p>
              <p className="tw-leading-6">
                Trillions of dollars are locked in crypto, lacking green investments or uncorrelated assets,
                leading to massive losses during market downturns.
              </p>
            </div>
          </div>
          <p className="tw-max-w-[420px] tw-flex-1 tw-leading-8 tw-text-[20px]">
            Helios unites these disparate problems to create a revolutionary solution.
            The protocol enables crypto-native investment into high-impact solar projects in emerging markets,
            earning users stable, double-digit returns uncorrelated with crypto and public markets while averting
            millions of tonnes of CO2 emissions.
          </p>
        </div>
      </div>
    </div>
  );
}
