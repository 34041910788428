import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './assets/styles/index.scss';

if (process.env.REACT_APP_ENV === 'production' && process.env.REACT_APP_GTAG_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTAG_ID,
    dataLayerName: 'PageDataLayer',
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
