import React, { useEffect, useRef, useState } from 'react';
import { useStoreConfigAppDevice } from '../../../../dao/store/states/ConfigState';
import { useStoreSectionState } from '../../../../dao/store/states/SectionState/hoox';
import { StoreActionSectionState } from '../../../../dao/store/states/SectionState/actions';
import { AlignmentTypes } from '../../../../constants/ui';

export function BlockWrapperFix(props) {
  const blockWrapper = useRef(null);
  const blockContent = useRef(null);
  const [currentY, setCurrentY] = useState(0);

  const {
    contentAlignment = AlignmentTypes.CC,
    backgroundColor = 'transparent',
    background = '',
    contendEndFixed = false,
    contentPercent = 1,
    children,
  } = props;

  const { sectionState, dispatchSectionState } = useStoreSectionState();
  const { deviceState } = useStoreConfigAppDevice();

  const calculateYCenter = (
    delayTop,
    deviceHeight,
    sectionTop,
    sectionCurrentHeight,
    contentCurrentHeight,
    endFixed = false,
  ) => {
    const viewPortHeight = sectionCurrentHeight > deviceHeight ? deviceHeight : sectionCurrentHeight;
    const marginTop = (viewPortHeight - contentCurrentHeight) / 2;

    const currentScroll = (delayTop - sectionTop + marginTop);

    const minPoint = marginTop;

    const maxPoint = (sectionCurrentHeight - marginTop - contentCurrentHeight);

    const endPoint = sectionCurrentHeight > contentCurrentHeight ? sectionCurrentHeight : contentCurrentHeight;

    if (currentScroll < minPoint) {
      return minPoint;
    }

    if (!endFixed && currentScroll > maxPoint) {
      return maxPoint;
    }

    if (currentScroll > endPoint) {
      return endPoint;
    }

    return currentScroll;
  };

  const calculateYTop = (
    delayTop,
    deviceHeight,
    sectionTop,
    sectionCurrentHeight,
    contentCurrentHeight,
    endFixed = false,
  ) => {
    const currentScroll = (delayTop - sectionTop);
    const minPoint = 0;

    const maxPoint = (sectionCurrentHeight - contentCurrentHeight);

    if (!endFixed && currentScroll > maxPoint) {
      return maxPoint;
    }
    if (currentScroll < minPoint) {
      return minPoint;
    }
    return currentScroll;
  };

  const calculateCurrentY = (
    delayTop,
    deviceHeight,
    sectionTop,
    sectionCurrentHeight,
    contentCurrentHeight,
    contentCurrentAlignment = AlignmentTypes.CC,
    endFixed = false,
    yPercent = 100,
  ) => {
    switch (contentCurrentAlignment) {
      case AlignmentTypes.CC:
        return calculateYCenter(
          delayTop,
          deviceHeight,
          sectionTop,
          sectionCurrentHeight,
          contentCurrentHeight,
          endFixed,
          yPercent,
        );

      default:
        return calculateYTop(
          delayTop,
          deviceHeight,
          sectionTop,
          sectionCurrentHeight,
          contentCurrentHeight,
          endFixed,
          yPercent,
        );
    }
  };

  useEffect(() => {
    setCurrentY(
      calculateCurrentY(
        0,
        deviceState.deviceScreen.browserHeight,
        sectionState.sectionTop,
        sectionState.sectionCurrentHeight,
        blockContent?.current?.offsetHeight,
        contentAlignment,
        contendEndFixed,
        contentPercent,
      ),
    );
  }, [
    deviceState.deviceScreen.browserHeight,
    sectionState.sectionTop,
    sectionState.sectionCurrentHeight,
    blockContent?.current?.offsetHeight,
    sectionState.contentCurrentHeight,
    contentAlignment,
    contendEndFixed,
    contentPercent,
  ]);

  useEffect(() => {
    if (blockContent && blockContent?.current?.offsetHeight) {
      dispatchSectionState({
        type: StoreActionSectionState.SetContentCurrentHeight,
        payload: blockContent?.current?.offsetHeight,
      });
    }
  }, [
    blockWrapper,
    blockContent,
    deviceState.deviceScreen.browserWidth,
    deviceState.deviceScreen.browserHeight,
    deviceState.deviceScreen.pixelRatio,
  ]);

  return (
    <div className="block-wrapper-fix" style={{ height: '100%' }}>
      <div
        ref={blockContent}
        className="block-content-wrapper"
        style={{
          height: '100%',
          width: '100%',
          willChange: 'transform',
          transform: `translateY(${currentY}px)`,
          backgroundColor: { backgroundColor },
          background,
        }}
      >
        {
        children
      }
      </div>
    </div>
  );
}
