import React from 'react';

export function IconLogotypePWC(props) {
  const { className, style } = props;

  return (
    <svg
      width="3.5em"
      height="2.6875em"
      viewBox="0 0 56 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      {/* eslint-disable-next-line max-len */}
      <path d="M0.0431107 41.8547L1.27535 41.7412L1.51364 41.4597V29.5886H0V28.5456L4.00081 27.2687H5.12526V28.7181C7.11432 27.5502 7.89268 27.2426 8.90934 27.2426C11.2921 27.2426 13.1076 29.4922 13.1076 32.4557C13.1076 35.9164 10.6204 38.2318 6.94411 38.2318C6.51181 38.2318 5.81966 38.1886 5.12865 38.1183V41.4495L5.41005 41.731L6.83746 41.8445L6.79433 42.9909H0.0476469L0.0431107 41.8547ZM5.12526 36.9015C5.31831 36.9338 5.51391 36.9482 5.70961 36.9447C7.93694 36.9447 9.12607 35.56 9.12607 32.9642C9.12607 30.7577 8.15253 29.5898 6.3575 29.5898C5.94453 29.5976 5.53269 29.6355 5.12526 29.7033V36.9015Z" fill="#1A1818" />
      {/* eslint-disable-next-line max-len */}
      <path d="M17.9549 27.3174C18.1008 27.9212 18.1949 28.5364 18.2363 29.1562L18.4746 34.434L21.6323 28.4638V27.8361L24.8979 27.5762L25.3086 35.796L28.0987 31.9233L27.4928 27.9428C27.7059 27.7167 27.9644 27.5382 28.2512 27.4189C28.538 27.2996 28.8468 27.2422 29.1574 27.2505C29.3879 27.2309 29.6199 27.2605 29.8381 27.3372C30.0563 27.4139 30.2558 27.536 30.4234 27.6955C30.591 27.855 30.7229 28.0483 30.8104 28.2625C30.8978 28.4767 30.9389 28.707 30.9308 28.9383C30.9308 29.9984 30.3476 31.2525 28.8987 33.2649C28.4448 33.8926 27.1036 35.5576 24.8979 38.2408H22.346L21.849 30.7997L17.8051 38.2408H15.0592L15.2759 37.267L14.9355 29.4354L12.7944 29.0892V28.2243L16.709 27.3163L17.9549 27.3174Z" fill="#1A1818" />
      {/* eslint-disable-next-line max-len */}
      <path d="M37.6685 28.4208C35.8303 28.7022 34.8783 30.0642 34.8783 32.4228C34.8783 34.8063 36.1548 36.3817 38.0554 36.3817C39.2653 36.2889 40.4361 35.9109 41.4719 35.2785V37.208C39.9574 38.002 38.2732 38.4175 36.5633 38.4191C35.8143 38.4685 35.0632 38.3592 34.3593 38.0983C33.6554 37.8373 33.0145 37.4307 32.4786 36.905C31.965 36.388 31.5606 35.7731 31.2895 35.0967C31.0183 34.4203 30.8859 33.6962 30.9002 32.9676C30.9002 29.5716 33.4521 27.2562 37.1931 27.2562C39.6802 27.2562 41.3913 28.381 41.3913 30.0041C41.3929 30.2572 41.3411 30.5078 41.239 30.7394C41.137 30.971 40.9872 31.1785 40.7993 31.3481C40.6114 31.5176 40.3898 31.6455 40.1491 31.7234C39.9083 31.8012 39.6538 31.8272 39.4022 31.7997C38.7876 31.7965 38.1869 31.6165 37.6719 31.281L37.6685 28.4208Z" fill="#1A1818" />
      <path d="M35.7134 18.3748H25.135V21.1658H35.7134V18.3748Z" fill="#9A1119" />
      <path d="M46.0616 0H35.7146V2.21101H46.0616V0Z" fill="#ED872D" />
      <path d="M47.9802 2.21106H46.0615V5.40839H47.9802V2.21106Z" fill="#F0A771" />
      <path d="M46.0616 2.21106H35.7146V5.40839H46.0616V2.21106Z" fill="#DE601B" />
      <path d="M53.2121 5.40723H47.9802V13.316H53.2121V5.40723Z" fill="#F1AA22" />
      <path d="M47.9802 5.40723H46.0615V13.316H47.9802V5.40723Z" fill="#E37718" />
      <path d="M42.1085 13.316H46.0662V5.40723H35.7192V10.6953H42.1131L42.1085 13.316Z" fill="#CF4819" />
      <path d="M55.9999 13.316H53.2109V18.3748H55.9999V13.316Z" fill="#D95697" />
      <path d="M53.2121 13.316H47.9802V18.3748H53.2121V13.316Z" fill="#D5411B" />
      <path d="M47.9802 13.316H46.0615V18.3748H47.9802V13.316Z" fill="#C72E1B" />
      <path d="M46.0604 13.316H42.1084V18.3748H46.0604V13.316Z" fill="#B31A1B" />
      <path d="M42.1084 10.6998H35.7146V13.3172H42.1084V10.6998Z" fill="#B3371B" />
      <path d="M42.1084 13.316H35.7146V18.3748H42.1084V13.316Z" fill="#9A1119" />
    </svg>
  );
}
