import { StoreActionConfigAppHeader } from './actions';

export const appHeaderStateReducer = (
  state,
  action,
) => {
  switch (action.type) {
    case StoreActionConfigAppHeader.MobileHeaderIsOpen:
      return {
        ...state,
        mobile: {
          ...state.mobile,
          isOpen: action.payload,
        },
      };

    case StoreActionConfigAppHeader.OnlyLogotype:
      return {
        ...state,
        onlyLogotype: action.payload || false,
      };

    default:
      return state;
  }
};
