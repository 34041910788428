export const DirectionTypes = {
  Left: 'left',
  Up: 'up',
  Right: 'right',
  Down: 'down',
};

export const ModalTypes = {
  Message: 'Message',
  Confirm: 'Confirm',
};

export const PageTypes = {
  SectionPage: 'SectionPage',
  ErrorPage: 'ErrorPage',
};

export const AppLayoutTypes = {
  Primary: 'primary',
  Secondary: 'secondary',
};

export const SectionTypes = {
  Default: 'default',
  Fixed: 'fixed',
  Sticky: 'sticky',
  FullScreen: 'full-screen',
  Static: 'static',
  AbsoluteContent: 'AbsoluteContent',
};

export const AlignmentTypes = {
  LT: 'lt',
  CT: 'ct',
  RT: 'rt',
  LC: 'lc',
  CC: 'cc',
  RC: 'rc',
  LB: 'lb',
  CB: 'cb',
  RB: 'rb',
};

export const BlockWrapperTypes = {
  Static: 'static',
  Fixed: 'fixed',
};

export const BlockWrapperAlignment = {
  Center: 'center',
  Top: 'top',
  Bottom: 'bottom',
};

export const BlockTypes = {
  PageCover: 'page-cover',
  PageTitle: 'page-title',
  PageIcons: 'page-icons',
  PageText: 'page-text',
  PageTextImage: 'page-text-image',
  PageProject: 'page-project',
  PageEvents: 'page-events',
  PageDefault: 'page-default',
};

export const ImageContainTypes = {
  Cover: 'Cover',
  Contain: 'Contain',
};

export const BlockTitleTypes = {
  Colored: 'colored',
  Static: 'Static',
};

export const contentBlocksTypes = {
  GridPrimary: 'content-grid-primary',
  GridSecondary: 'content-grid-secondary',
};

export const ButtonTypes = {
  ContactButton: 'contact-button',
  ArrowButton: 'arrow-button',
};

export const ButtonActionTypes = {
  Mail: 'mailto:',
};

export const BlockGridTypes = {
  Double: 'double',
  Triple: 'triple',
};
