import React, { useEffect, useState } from 'react';

import { LayoutPrimary } from '../../layout';
import { SectionContainer } from '../../shared/sections';
import { BlockContainer } from '../../shared/blocks';
import { useStoreConfigAppDevice } from '../../../dao/store/states/ConfigState';
import { DeviceTypes } from '../../../constants/device';
import { useEffectOnce } from '../../../hoox/useEffectOnce';
import { StoreActionConfigAppHeader } from '../../../dao/store/actions';
import { useStoreConfigAppHeader } from '../../../dao/store/hoox';

export function PageSection(props) {
  const { pageData, path } = props;

  const [isRender, setIsRender] = useState(false);
  const { deviceState } = useStoreConfigAppDevice();

  const { dispatchHeaderState } = useStoreConfigAppHeader();

  useEffect(() => {
    const localTimeOut = setTimeout(() => {
      setIsRender(1);
    }, 200);

    return () => {
      clearTimeout(localTimeOut);
    };
  }, [isRender]);

  useEffect(() => {
    setIsRender(false);
  }, [path]);

  useEffectOnce(() => {
    dispatchHeaderState({
      type: StoreActionConfigAppHeader.OnlyLogotype,
      payload: false,
    });
  });

  return (
    <LayoutPrimary isRender={isRender}>
      {
        pageData.sections.length && (
          pageData?.sections.map((item, key) => (
            <SectionContainer
              type={
                deviceState.deviceType === DeviceTypes.Mobile
                  ? (item.mobileConfig?.sectionType || item.sectionType)
                  : item.sectionType
              }
              config={item.config}
              mobileConfig={item.mobileConfig}
              key={`section-${key}`}
              index={(3 * key + 10)}
              path={path}
            >
              <BlockContainer
                sectionType={item.sectionType}
                type={item.blockType}
                blockData={item.blockData}
                sectionConfig={item.config}
                path={path}
              />
            </SectionContainer>
          )))
      }
    </LayoutPrimary>
  );
}
