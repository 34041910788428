import React from 'react';

export function Structure({ items }) {
  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-mt-24">
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-24 tw-w-full">
        <div className="tw-text-start tw-flex tw-flex-wrap tw-gap-10 tw-justify-center tw-font-medium tw-w-full [&>p]:tw-min-w-[135px] [&>div]:tw-min-w-[180px] [&>p]:tw-max-w-[135px] [&>div]:tw-max-w-[220px]">
          {items.map(({ title, description }, index) => (
            <div className="tw-flex tw-flex-col tw-gap-[18px] tw-flex-1">
              <p className="tw-text-[2.75rem] tw-font-extrabold tw-text-[#0B132A1F]">{index + 1}</p>
              <h4 className="tw-text-[18px] tw-leading-[26px] tw-font-bold tw-text-[#0D51FF]">{title}</h4>
              <p className="tw-leading-6 tw-text-[14px] tw-text-[#0B132A]">
                {description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
