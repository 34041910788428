import React from 'react';
import { Navigate } from 'react-router-dom';

import { useStoreClient } from '../../../../dao/store/hoox';

export function PrivateRoute({ children }) {
  const { clientState } = useStoreClient();

  if (clientState.isLoggedIn && children) {
    return (
      <>
        {children}
      </>
    );
  }

  return (
    <Navigate
      to={{
        pathname: '/accounts/signin',
      }}
    />
  );
}
