import React from 'react';

export function IconSocialClutch(props) {
  const { className, style, colorFill = 'black' } = props;

  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      {/* eslint-disable-next-line max-len */}
      <path fill={colorFill} d="M12.5222 9.00069C12.5222 10.7097 11.0917 12.0953 9.32727 12.0953C7.5628 12.0953 6.13237 10.7097 6.13237 9.00069C6.13237 7.29163 7.5628 5.90612 9.32727 5.90612C11.0917 5.90612 12.5222 7.29163 12.5222 9.00069ZM13.9817 11.7126C13.0285 13.059 11.3868 13.9486 9.51946 13.9486C6.57277 13.9486 4.18422 11.7332 4.18422 9C4.18422 5.93085 6.57277 4.05144 9.51946 4.05144C11.2151 4.05144 12.725 4.78576 13.7023 5.92948L16.6965 3.02931C14.9171 1.17188 12.3349 0 9.50457 0C4.25514 0 0 3.66952 0 9C0 14.3305 4.25514 18 9.50457 18C12.5002 18 15.2185 16.6873 17 14.6362L13.9817 11.7126Z" />
    </svg>
  );
}
