import React from 'react';

import { BlockWrapperFix } from '../BlockWrapperFix';

export function BlockPageIcons(props) {
  const {
    blockData,
  } = props;

  return (
    <BlockWrapperFix
      {...blockData.config}
    >
      <div
        className="block-page-icons"
        style={{
          color: blockData.config.color,
          backgroundColor: blockData.config.backgroundColor,
        }}
      >
        {
          blockData.content.header?.title
          && <h4 style={{ ...blockData.content.header.styles }}>{blockData.content.header.title}</h4>
        }
        <div
          className="icon-list"
        >
          {
            blockData.content?.blocks?.map((item, index) => (
              <div
                key={`client-${index}`}
              >
                <a href={item.path} aria-label={`${item.name}`} target="_blank" rel="noreferrer">
                  {
                    item.component
                  }
                </a>
              </div>
            ))
          }
        </div>
      </div>
    </BlockWrapperFix>
  );
}
