import React from 'react';

import { LabelCard } from './LabelCard';

import founderImage from '../../../../../assets/images/projects/globalid/founder.png';
import dataView from '../../../../../assets/images/projects/globalid/icons/data-view.svg';
import identification from '../../../../../assets/images/projects/globalid/icons/identification.svg';
import fingerPrint from '../../../../../assets/images/projects/globalid/icons/finger-print.svg';
import { ListItem } from './ListItem';

export function UsageInfo() {
  const cardItems = [
    {
      title: 'People',
      description: 'use GlobalID to leave their physical wallets behind',
    },
    {
      title: 'Credit Unions',
      description: 'use GlobalID for secure onboarding and fraud prevetion',
    },
    {
      title: 'DeFi Protocols',
      description: 'use GlobalID to achieve regulatory compliance',
    },
    {
      title: 'Airdrop Devs',
      description: 'use GlobalID to eliminate bots and farmers',
    },
    {
      title: 'Exchanges',
      description: 'use GlobalID KYC and passwordless login',
    },
    {
      title: 'Marketplaces',
      description: 'use GlobalID for customer verification and screening',
    },
  ];

  const listItems = [
    {
      icon: dataView,
      description: 'Upholding control over data. Individuals can bring their identity, decide who sees it, and determine permissions.',
    },
    {
      icon: identification,
      description: 'Businesses utilize verifiable credentials to ensure the identity of their customers.',
    },
    {
      icon: fingerPrint,
      description: 'Developers streamline onboarding and enable instant, multi-factor, passwordless authentication.',
    },
  ];

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-between tw-text-[24px] tw-mt-[140px]">
      <div className="tw-w-full tw-flex tw-gap-14 max-lg:tw-flex-col max-lg:tw-items-center">
        <img className="tw-max-w-lg max-sm:tw-max-w-md" src={founderImage} alt="founder" />
        <div className="tw-flex tw-flex-col tw-justify-end tw-text-start">
          <h3>Greg Kidd</h3>
          <p className="tw-text-[14px]">Co-founder of GlobaliD</p>
          <p className="tw-text-[14px] tw-mt-4">
            Greg’s experience spans from taking his own startup public (Dispatch Management Services)
            to time at Booz Allen, Promontory, and the Board of Governors of the Federal Reserve.
            He was an early investor in companies such as Twitter, Square, Ripple, Coinbase, Robinhood,
            Protocol Labs, Brave, Solana Labs and Uphold and his investment firm Hard Yaka continues
            to back many fintech and regtech companies.
          </p>
        </div>
      </div>
      <div className="tw-mt-[260px]">
        <h3>How GlobalID is used today</h3>
        <div className="tw-flex tw-flex-wrap tw-gap-6 tw-mt-14 tw-justify-center tw-max-w-7xl">
          {cardItems.map(({ title, description }) => <LabelCard title={title} description={description} />)}
        </div>
      </div>
      <div className="tw-flex tw-justify-between tw-items-center tw-gap-16 max-lg:tw-flex-col tw-mt-[200px]">
        <div className="tw-max-w-3xl tw-text-start max-lg:tw-text-center">
          <span className="tw-text-[22px] tw-text-black max-lg:tw-text-[20px] max-sm:tw-text-[18px]">
            {'Today’s identity systems lead to staggering loss. '}
          </span>
          <span className="tw-text-[22px] tw-text-blueMedium max-lg:tw-text-[20px] max-sm:tw-text-[18px]">
            {'GlobalID is a new identity paradigm '}
          </span>
          <span className="tw-text-[22px] tw-text-black max-lg:tw-text-[20px] max-sm:tw-text-[1p8x]">
            that empowers everyday people, removes business risk, and enables more efficient institutions.
          </span>
        </div>
        <div className="tw-flex tw-flex-wrap tw-gap-4 tw-justify-center  tw-max-w-[400px] max-sm:tw-max-w-[320px]">
          {listItems.map(({ icon, description }) => <ListItem icon={icon} description={description} />)}
        </div>
      </div>
    </div>
  );
}
