import React from 'react';
import { useStoreConfigAppHeader } from '../../../../../dao/store/states/ConfigState/hoox';
import { StoreActionConfigAppHeader } from '../../../../../dao/store/states/ConfigState/AppHeaderState/actions';

export function IconHeaderBurger() {
  const { headerState, dispatchHeaderState } = useStoreConfigAppHeader();

  const handleBurgerButton = () => {
    dispatchHeaderState({
      type: StoreActionConfigAppHeader.MobileHeaderIsOpen,
      payload: !headerState.mobile.isOpen,
    });
  };

  return (
    <svg
      width="6.25em"
      height="6.25em"
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      onClick={() => { handleBurgerButton(); }}
      className={`mobile-burger ${headerState.mobile.isOpen ? 'mobile-burger-open' : ''}`}
    >
      <path className="line--1" d="M0 70l28-28c2-2 2-2 7-2h64" />
      <path className="line--2" d="M0 50h99" />
      <path className="line--3" d="M0 30l28 28c2 2 2 2 7 2h64" />
    </svg>
  );
}
