import { useContext } from 'react';
import { AppHeaderContext } from './State';

export const useStoreConfigAppHeader = () => {
  const { headerState, dispatchHeaderState } = useContext(AppHeaderContext);

  return {
    headerState,
    dispatchHeaderState,
  };
};
