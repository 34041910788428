import React from 'react';

import { ClientStateProvider } from '../ClientState';
import {
  AppConfigStateProvider,
  AppDeviceStateProvider,
  AppHeaderStateProvider,
  AppLoaderStateProvider, AppModalStateProvider,
} from '../ConfigState';

export function GlobalStateProvider({ children }) {
  return (
    <AppConfigStateProvider>
      <ClientStateProvider>
        <AppDeviceStateProvider>
          <AppLoaderStateProvider>
            <AppModalStateProvider>
              <AppHeaderStateProvider>
                {
                  children
                }
              </AppHeaderStateProvider>
            </AppModalStateProvider>
          </AppLoaderStateProvider>
        </AppDeviceStateProvider>
      </ClientStateProvider>
    </AppConfigStateProvider>
  );
}
