import React, { useEffect, useState } from 'react';

import { FooterPrimary } from './footers';
import { AppLayoutTypes } from '../../constants/ui';

import { SectionDelayWrapper } from '../shared/sections';

export function LayoutPrimary(props) {
  const { layoutType = AppLayoutTypes.Primary, isRender, children } = props;
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setOpacity(0);
    const currentTimeOut = setTimeout(() => {
      setOpacity(1);
    }, 250);

    return () => {
      clearTimeout(currentTimeOut);
    };
  }, [isRender]);

  if (isRender) {
    return (
      <div
        className={`app-container-primary app-container-${layoutType}`}
        style={{ opacity }}
      >
        <SectionDelayWrapper>
          {
            children
          }
          <FooterPrimary layoutType={layoutType} />
        </SectionDelayWrapper>
      </div>
    );
  }

  return null;
}
