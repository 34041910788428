import React from 'react';

import {
  AlignmentTypes,
  BlockTitleTypes,
  BlockTypes,
  BlockWrapperTypes,
  ButtonTypes,
  SectionTypes,
} from '../constants/ui';

import { HomeTitle } from '../modules/shared/icons';
import { clientListSVG } from './clientList';
import { projectList } from './projectList';

import homeBackgroundVideo from '../assets/videos/home-background-video.mp4';

import iconHomeAgency1 from '../assets/images/whatwedo/icon-home-agency-1x.jpg';
import iconHomeAgency2 from '../assets/images/whatwedo/icon-home-agency-2x.jpg';
import iconHomeAgency3 from '../assets/images/whatwedo/icon-home-agency.jpg';

import iconHomeVenture1 from '../assets/images/whatwedo/icon-home-venture-1x.jpg';
import iconHomeVenture3 from '../assets/images/whatwedo/icon-home-venture.jpg';
import iconHomeVenture2 from '../assets/images/whatwedo/icon-home-venture-2x.jpg';

import seo1 from '../assets/images/seo/1200x639.jpg';
import seo2 from '../assets/images/seo/2400x1260.jpg';
import seo3 from '../assets/images/seo/400x200.jpg';

const sectionHome = {
  sectionType: SectionTypes.Sticky,
  blockType: BlockTypes.PageCover,
  index: 28,
  config: {
    color: '#FFFFFF',
    backgroundColor: '#000000',
    minHeight: 120,
    contendEndFixed: true,
    background: {
      backgroundVideo: homeBackgroundVideo,
    },
  },
  blockData: {
    config: {
      color: '#FFFFFF',
      backgroundColor: '#000000',
      contentPosition: BlockWrapperTypes.Fixed,
      contentAlignment: AlignmentTypes.CC,
      mobile: {
        contentAlignment: AlignmentTypes.CC,
      },
      zIndex: 30,
    },
    content: {
      component: (props) => (
        <HomeTitle {...props} />
      ),
    },
  },
};

const sectionTitle = {
  sectionType: SectionTypes.Sticky,
  index: 32,
  config: {
    color: '#000000',
    backgroundColor: '#f7f8fa',
    minHeight: 200,
    contendEndFixed: true,
  },
  blockType: BlockTypes.PageTitle,
  blockData: {
    config: {
      contentPosition: BlockWrapperTypes.Fixed,
      color: '#000000',
      specialColor: '#6b37ff',
      backgroundColor: '#F7F8FA',
      zIndex: 34,
      showContactBtn: false,
    },
    content: {
      blocks: [
        {
          textType: BlockTitleTypes.Static,
          text: 'We deliver full-cycle services from market research and business modeling to engineering, product design, and marketing. ',
        },
        {
          textType: BlockTitleTypes.Colored,
          text: 'Our inspiration is to create products that truly bring value.',
        },
      ],
    },
  },
};

const sectionClients = {
  sectionType: SectionTypes.Sticky,
  blockType: BlockTypes.PageIcons,
  index: 36,
  config: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
    minHeight: 180,
    contendEndFixed: true,
  },
  blockData: {
    config: {
      contentPosition: BlockWrapperTypes.Fixed,
      zIndex: 38,
    },
    content: {
      header: {
        title: 'We have built solutions for',
      },
      blocks: clientListSVG,
    },
  },
};

const sectionAgency = {
  sectionType: SectionTypes.Sticky,
  blockType: BlockTypes.PageTextImage,
  index: 40,
  config: {
    color: '#FFFFFF',
    backgroundColor: '#0E0E0E',
    contentAlignment: AlignmentTypes.CT,
    minHeight: 200,
    contendEndFixed: true,
  },
  blockData: {
    config: {
      color: '#FFFFFF',
      backgroundColor: '#0E0E0E',
      contentPosition: BlockWrapperTypes.Fixed,
      isImageLeft: true,
      zIndex: 42,
    },
    content: {
      image: {
        image1: iconHomeAgency1,
        image2: iconHomeAgency2,
        image3: iconHomeAgency3,
        alt: 'groksmith agency icon',
      },
      text: {
        header: {
          title: 'Services',
          description: 'Driving digital transformation through product design, software development, and product marketing.',
          button: {
            type: ButtonTypes.ArrowButton,
            to: '/whatwedo/services/',
            name: 'services',
            text: 'Learn More',
          },
        },
        // blocks: [],
        // footer: {},
      },
    },
  },
};

const sectionVenture = {
  sectionType: SectionTypes.Sticky,
  blockType: BlockTypes.PageTextImage,
  index: 44,
  config: {
    color: '#FFFFFF',
    backgroundColor: '#000000',
    minHeight: 200,
    contentAlignment: AlignmentTypes.CT,
    contendEndFixed: true,
  },
  blockData: {
    config: {
      color: '#FFFFFF',
      backgroundColor: '#000000',
      isImageLeft: false,
      zIndex: 46,
    },
    content: {
      image: {
        image1: iconHomeVenture1,
        image2: iconHomeVenture2,
        image3: iconHomeVenture3,
        alt: 'groksmith industries icon',
      },
      text: {
        header: {
          title: 'Industries',
          description: 'Leveraging our expertise in select industries to achieve business goals.',
          button: {
            type: ButtonTypes.ArrowButton,
            to: '/whatwedo/industries/',
            name: 'industries',
            text: 'Learn More',
          },
        },
        // blocks: [],
        // footer: {},
      },
    },
  },
};

const sectionConnectButton = {
  sectionType: SectionTypes.Static,
  index: 78,
  blockType: BlockTypes.PageDefault,
  config: {
    color: '#000000',
    backgroundColor: '#F7F8FA',
    minHeight: 80,
  },
  blockData: {
    config: {
      color: '#000000',
      isImageLeft: false,
      zIndex: 60,
      showContactBtn: true,
      wrapperClassName: 'block-page-cover-centered',
    },
    content: {
      contentConfigs: {
        showContactBtn: true,
        wrapperClassName: 'bldux',
      },
      // header: {},
      // blocks: [],
    },
  },
};

export const pageHome = {
  config: {
    color: '#000000',
    backgroundColor: '#FFFFFF',
  },
  sections: [
    sectionHome,
    sectionTitle,
    sectionClients,
    sectionAgency,
    sectionVenture,
    ...projectList,
    sectionConnectButton,
  ],
  seo: {
    title: 'Groksmith: Software Design and Development Company',
    // eslint-disable-next-line max-len
    description: 'Groksmith is a Yerevan-based Tech Company that delivers full-cycle services to global businesses | Product Strategy, Design, Development, and Growth Hacking.',
    name: 'Groksmith website',
    image1: seo1,
    image2: seo2,
    image3: seo3,
  },
};
